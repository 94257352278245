import { uniq } from 'lodash';
import { useContext } from 'react';
import { DetailContext, UserContext } from '@eas/common-web';
import {
  CourtFile,
  DisciplinaryProceedingsFile,
  Me,
  PlenaryOpinionFile,
  SprFile,
} from '@models';
import { EsFileState, Role } from '@enums';
import { courtFileAccessMap } from '../es-file-court/court-file-access-map';
import { infFileAccessMap } from '../es-file-inf/inf-file-access-map';
import { plenaryOpinionAccessMap } from '../es-file-plenary-opinion/plenary-opinion-access-map';
import { sprFileAccessMap } from '../es-file-spr/spr-file-access-map';
import { disciplinaryProceedingsFileAccessMap } from './../es-file-disciplinary-proceedings/disciplinary-proceedings-access-map';

export const INF_FILES_ACCESS = [
  Role.MAILROOM, // Podatelna/výpravna
  Role.LUSTRUM, // Lustrum
  Role.HEAD_OSV, // Vedoucí OSV
  Role.INDEX, // Rejstřík
  Role.LAWYER, // Právník
  Role.SECRETARY, // Tajemnice
  Role.JUDGE, // Soudce
  Role.ASSISTANT, // Asistent
  Role.ANALYST, // Analytik
  Role.FILE_ROOM, // Spisovna
  Role.SOLVER, // Řešitel
  Role.READER, // Čtenář
];

export const SPR_FILES_ACCESS = INF_FILES_ACCESS;

export const COURT_FILES_ACCESS = [
  Role.LUSTRUM, // Lustrum
  Role.HEAD_OSV, // Vedoucí OSV
  Role.INDEX, // Rejstřík
  Role.LAWYER, // Právník
  Role.SECRETARY, // Tajemnice
  Role.JUDGE, // Soudce
  Role.ASSISTANT, // Asistent
  Role.ANALYST, // Analytik
  Role.FILE_ROOM, // Spisovna
  Role.READER, // Čtenář
  Role.ADVISER, // Poradce - sbírky
  Role.PUBLICATION, // Zveřejnění
  Role.ANONYMIZATION, // Anonymizace
  Role.VIEWING_REQUEST_MANAGER, // Správce žádostí o nahlížení
];

export const DISCIPLINARY_PROCEEDINGS_FILE_ACCESS = [
  Role.INDEX, // Rejstřík,
  Role.JUDGE, // Soudce
  Role.READER, // Čtenář
];

export const PLENARY_OPINION_FILES_ACCESS = [
  Role.LUSTRUM, // Lustrum
  Role.HEAD_OSV, // Vedoucí OSV
  Role.INDEX, // Rejstřík
  Role.LAWYER, // Právník
  Role.SECRETARY, // Tajemnice
  Role.JUDGE, // Soudce
  Role.ASSISTANT, // Asistent
  Role.ANALYST, // Analytik
  Role.FILE_ROOM, // Spisovna
  Role.READER, // Čtenář
];

export const ALL_FILES_ACCESS = uniq([
  ...INF_FILES_ACCESS,
  ...COURT_FILES_ACCESS,
  ...DISCIPLINARY_PROCEEDINGS_FILE_ACCESS,
  ...PLENARY_OPINION_FILES_ACCESS,
]);

export function usePermission(
  accessMap?: Map<EsFileState, Map<Role, string[]>>,
  esFile?: CourtFile | null
) {
  const { user } = useContext<UserContext<Me>>(UserContext);
  const { isExisting } = useContext(DetailContext);

  const isAdmin = user?.userRoles.map((r) => r.role).includes(Role.ADMIN);

  const hasPermission = (element: string, skipAssignmentCheck = isAdmin) => {
    if (!esFile?.state) {
      return false;
    }

    const permittedElements = new Set<string>();

    // flat permitted elements into Set
    user?.userRoles.forEach(({ role }) => {
      const permittedForRole = accessMap?.get(esFile.state!)?.get(role!);

      // skip user assignment for superadmin || skip user assignment FILE_COVER button
      if (!skipAssignmentCheck) {
        if (
          role === Role.INDEX &&
          isExisting &&
          esFile?.indexUserRole?.user?.id !== user.id &&
          esFile?.indexUserRole?.user?.id !==
            user.additionalDetails?.activeSubstitution?.substitutedUser?.id
        ) {
          return;
        }

        if (
          role === Role.SECRETARY &&
          isExisting &&
          esFile?.secretaryUserRole?.user?.id !== user.id &&
          esFile?.secretaryUserRole?.user?.id !==
            user.additionalDetails?.activeSubstitution?.substitutedUser?.id
        ) {
          return;
        }

        if (
          role === Role.ASSISTANT &&
          isExisting &&
          esFile?.assistantUserRole?.user?.id !== user.id &&
          esFile?.assistantUserRole?.user?.id !==
            user.additionalDetails?.activeSubstitution?.substitutedUser?.id
        ) {
          return;
        }

        if (
          role === Role.JUDGE &&
          isExisting &&
          esFile?.judge?.judgeUserRole?.user?.id !== user.id &&
          esFile?.judge?.judgeUserRole?.user?.id !==
            user.additionalDetails?.activeSubstitution?.substitutedUser?.id
        ) {
          return;
        }

        if (
          role === Role.ANALYST &&
          isExisting &&
          esFile?.analystUserRole?.user?.id !== user.id &&
          esFile?.analystUserRole?.user?.id !==
            user.additionalDetails?.activeSubstitution?.substitutedUser?.id
        ) {
          return;
        }
      }

      permittedForRole?.forEach((element) => permittedElements.add(element));
    });

    return permittedElements.has(element);
  };

  return {
    hasPermission,
  };
}

export function useCourtFilePermission(esFile?: CourtFile | null) {
  return usePermission(courtFileAccessMap, esFile);
}

export function useSprFilePermission(esFile?: SprFile | null) {
  return usePermission(sprFileAccessMap, esFile);
}

export function useInfFilePermission(esFile?: SprFile | null) {
  return usePermission(infFileAccessMap, esFile);
}

export function useDisciplinaryProceedingsPermission(
  esFile?: DisciplinaryProceedingsFile | null
) {
  return usePermission(disciplinaryProceedingsFileAccessMap, esFile);
}

export function usePlenaryOpinionPermission(
  esFile?: PlenaryOpinionFile | null
) {
  return usePermission(plenaryOpinionAccessMap, esFile);
}
