import React, { useMemo, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { green, grey, red } from '@material-ui/core/colors';
import CancelIcon from '@material-ui/icons/Cancel';
import EditIcon from '@material-ui/icons/Edit';
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAlt';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import {
  DictionaryObject,
  FieldFilterType,
  Filter,
  FormPanel,
  RemoteTableField,
  ScrollableSource,
  TableFieldColumn,
  TableFieldHandle,
  TableFieldSpeedDialAction,
  TableFieldToolbarHandle,
  TableFieldToolbarProps,
} from '@eas/common-web';
import { FormTableCount } from '@components/form/table-field/form-table-count';
import { Deadline } from '@models';
import { DeadlineState } from '@enums';
import { useDocumentColumns, useEsFileColumns } from './deadline-columns';
import { DeadlineContext } from './deadline-context';
import { DeadlineToolbar } from './deadline-toolbar';
import { useEditDeadlineDialog } from './dialog-actions/edit-deadline-hook';

export interface DeadlineFieldProps {
  columns?: TableFieldColumn<Deadline>[];
  display: 'IN_ES_FILE' | 'IN_DOCUMENT';
  preFilters?: Filter[];
  filtersFields?: FieldFilterType[];
  label?: React.ReactNode;
  deadlineStates: DictionaryObject[];
  disabled: boolean;
  source?: ScrollableSource<Deadline>;
}

const createRowStyle = (deadline: Deadline) => {
  switch (deadline.state) {
    case DeadlineState.MET:
      return {
        color: green[500],
        backgroundColor: green[50],
      };
    case DeadlineState.NOT_MET:
      return {
        color: red[500],
        backgroundColor: red[50],
      };
    case DeadlineState.CANCELED:
      return {
        color: grey[500],
      };
    default:
      return {};
  }
};

export function DeadlineField({
  columns,
  display,
  preFilters = [],
  filtersFields,
  label = (
    <FormattedMessage id="ES__DEADLINES__PANEL_TITLE" defaultMessage="Lhůty" />
  ),
  deadlineStates,
  disabled,
  source,
}: DeadlineFieldProps) {
  const intl = useIntl();

  const tableRef = useRef<TableFieldHandle>(null);
  const toolbarRef = useRef<TableFieldToolbarHandle>(null);

  const documentColumns = useDocumentColumns();
  const esFileColumns = useEsFileColumns();

  const { show: showEditButton } = useEditDeadlineDialog();

  const defaultColumns =
    display === 'IN_DOCUMENT'
      ? documentColumns
      : display === 'IN_ES_FILE'
      ? esFileColumns
      : [];

  const actions: TableFieldSpeedDialAction<Deadline>[] = useMemo(() => {
    const editButton: TableFieldSpeedDialAction<Deadline> = {
      Icon: EditIcon,
      name: intl.formatMessage({
        id: 'ES__DEADLINES__EDIT_DEADLINE',
        defaultMessage: 'Editovat lhůtu',
      }),
      onClick: async (_, index) => {
        await tableRef.current?.setSelectedIndex(index);
        toolbarRef.current?.executeAction('EDIT');
      },
    };

    return showEditButton && display === 'IN_ES_FILE'
      ? [editButton]
      : showEditButton && display === 'IN_DOCUMENT'
      ? [
          editButton,
          {
            Icon: SentimentSatisfiedAltIcon,
            name: intl.formatMessage({
              id: 'ES__DEADLINES__MET_DEADLINE',
              defaultMessage: 'Změnit stav lhůty na Dodržena',
            }),
            onClick: async (_, index) => {
              await tableRef.current?.setSelectedIndex(index);
              toolbarRef.current?.executeAction('MET');
            },
          },
          {
            Icon: SentimentVeryDissatisfiedIcon,
            name: intl.formatMessage({
              id: 'ES__DEADLINES__NOT_MET_DEADLINE',
              defaultMessage: 'Změnit stav lhůty na Nedodržena',
            }),
            onClick: async (_, index) => {
              await tableRef.current?.setSelectedIndex(index);
              toolbarRef.current?.executeAction('NOT_MET');
            },
          },
          {
            Icon: CancelIcon,
            name: intl.formatMessage({
              id: 'ES__DEADLINES__CANCEL_DEADLINE',
              defaultMessage: 'Změnit stav lhůty na Stornována',
            }),
            onClick: async (_, index) => {
              await tableRef.current?.setSelectedIndex(index);
              toolbarRef.current?.executeAction('CANCEL');
            },
          },
        ]
      : [];
  }, [intl, showEditButton, display]);

  const ToolbarComponent = useMemo(
    () =>
      function Toolbar(props: TableFieldToolbarProps) {
        return (
          <DeadlineToolbar
            selectedIndex={props.selectedIndex}
            setSelectedIndex={props.setSelectedIndex}
            selectedIndexes={props.selectedIndexes}
            setSelectedIndexes={props.setSelectedIndexes}
            preFilters={preFilters}
            ref={toolbarRef}
            disabled={disabled}
            deadlineStates={deadlineStates}
          />
        );
      },
    [deadlineStates, disabled, preFilters]
  );

  if (!source) {
    return <> Missing DEADLINE_SOURCE </>;
  }

  return (
    <FormPanel
      label={label}
      expandable={true}
      defaultExpanded={true}
      summary={<FormTableCount source={source} />}
    >
      <DeadlineContext.Provider value={{ display, filtersFields }}>
        <RemoteTableField
          ref={tableRef}
          columns={columns ?? defaultColumns}
          ToolbarComponent={ToolbarComponent}
          speedDialActions={actions}
          maxRows={7}
          onRowClick={() => {}}
          showSpeedDialCond={() => !disabled}
          showRadioCond={() => false}
          showDetailBtnCond={() => false}
          createRowStyle={createRowStyle}
          source={source}
          disabled={disabled}
        />
      </DeadlineContext.Provider>
    </FormPanel>
  );
}
