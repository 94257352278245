import React from 'react';
import { Evidence } from '@eas/common-web';
import { useEsFiles } from '../es-file/es-files';
import { useColumns } from './all-es-files-columns';

export const ALL_ES_FILES_VERSION = 14;

export function AllEsFiles() {
  const columns = useColumns();

  const { evidenceBag } = useEsFiles({
    evidenceProps: {
      version: ALL_ES_FILES_VERSION,
      tableProps: { columns: columns },
      detailProps: {
        toolbarProps: {
          showBtn: (btn) => btn !== 'NEW',
        },
      },
    },
  });

  return <Evidence {...evidenceBag} />;
}
