import React from 'react';
import { useIntl } from 'react-intl';
import {
  ApiFilterOperation,
  Evidence,
  getItemFactory,
  updateItemFactory,
} from '@eas/common-web';
import { OutgoingDocument } from '@models';
import { DispatchState, DocumentType, EvidenceApiUrl, UIElement } from '@enums';
import { useOutgoingDocumentPermission } from '../document/document-permission';
import { DocumentToolbar } from '../document/document-toolbar';
import { useDocuments } from '../document/documents';
import { ActionBar } from './outgoing-document-actionbar';
import { useColumns } from './outgoing-document-columns';
import { Fields } from './outgoing-document-fields';
import { useValidationSchema } from './outgoing-document-schema';

export function OutgoingDocuments() {
  const intl = useIntl();
  const validationSchema = useValidationSchema();
  const columns = useColumns<OutgoingDocument>();

  const { hasPermission } = useOutgoingDocumentPermission();

  const { evidenceBag } = useDocuments({
    evidenceProps: {
      version: 14,
      apiProps: {
        url: EvidenceApiUrl.DOCUMENTS,
        getItem: getItemFactory({
          postProcess: postprocessGetCall,
        }),
        updateItem: updateItemFactory({
          postProcess: postprocessUpdateCall,
        }),
      },
      tableProps: {
        defaultPreFilters: [
          {
            field: 'type.id',
            operation: ApiFilterOperation.EQ,
            value: DocumentType.OUTGOING,
          },
        ],
        tableName: intl.formatMessage({
          id: 'ES__DOCUMENTS_OUTGOING__TABLE__TITLE',
          defaultMessage: 'Odchozí dokumenty',
        }),
        columns: columns,
      },
      detailProps: {
        FieldsComponent: Fields,
        validationSchema,
        toolbarProps: {
          title: intl.formatMessage({
            id: 'ES__DOCUMENTS_OUTGOING__DETAIL__TITLE',
            defaultMessage: 'Odchozí dokument',
          }),
          showBtn: (button) => !['NEW', 'REMOVE'].includes(button),
          disableBtn: (button) =>
            button === 'EDIT' &&
            !hasPermission(UIElement.OutgoingDocument.EDIT_DOCUMENT_BUTTON),
          After: DocumentToolbar,
          ActionBar,
        },
      },
    },
  });

  return <Evidence {...evidenceBag} />;
}

const postprocessGetCall = async (document: OutgoingDocument) => {
  const response = await fetch(`${EvidenceApiUrl.DOCUMENTS_DISPATCHES}/list`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      size: 0,
      filters: [
        {
          operation: ApiFilterOperation.EQ,
          value: document?.id,
          field: 'document.id',
        },
        {
          operation: ApiFilterOperation.EQ,
          value: DispatchState.PREPARED,
          field: 'state.id',
        },
      ],
    }),
  });

  const json = await response.json();

  return { ...document, preparedDispatchesCount: json.count };
};

const postprocessUpdateCall = async (document: OutgoingDocument) => {
  if (document.decision && !document.linkedDecision) {
    const response = await fetch(
      `${EvidenceApiUrl.DOCUMENTS}/${document.id}/decision`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      }
    );

    await response.json();
  }

  return document;
};
