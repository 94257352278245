import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import {
  ApiFilterOperation,
  EmptyComponent,
  TableCells,
  TableColumn,
  TableColumnAlign,
  TableFilterCells,
  eqFilterParams,
  useStaticListSource,
} from '@eas/common-web';
import { Redirect } from '@composite/redirect/redirect';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';
import {
  autocompleteColumnMapper,
  autocompleteLabelMapper,
} from '@components/form/misc/label-mappers';
import { Document } from '@models';
import { EvidenceBrowserUrl, Role } from '@enums';
import { useJudges } from '../judge/judge-api';
import { useUserRoles } from '../role/role-api';

export function useColumns<T extends Document>(): TableColumn<T>[] {
  const intl = useIntl();

  const { documentOutgoingDispatchStates, decisionForms, agendas } =
    useContext(StaticDataContext);
  const useAgendas = () => useStaticListSource(agendas);
  const useDispatchStateSource = () =>
    useStaticListSource(documentOutgoingDispatchStates);
  const useDecisionFormSource = () => useStaticListSource(decisionForms);
  const useIndexes = () =>
    useUserRoles(eqFilterParams({ field: 'role.id', value: Role.INDEX }));

  return [
    {
      datakey: 'id',
      name: intl.formatMessage({
        id: 'ES__OUTGOING_DOCUMENTS__TABLE__COLUMN__REDIRECT',
        defaultMessage: 'Přesměrování',
      }),
      NameComponent: EmptyComponent,
      width: 50,
      CellComponent: function Cell(props) {
        return (
          <>
            <Redirect
              url={EvidenceBrowserUrl.DOCUMENTS_OUTGOING}
              id={props.value}
              showRedirectInCurrent={false}
            />
          </>
        );
      },
      align: TableColumnAlign.LEFT,
    },
    {
      datakey: 'createdDate',
      name: intl.formatMessage({
        id: 'ES__OUTGOING_DOCUMENTS__TABLE__COLUMN__DATE',
        defaultMessage: 'Datum vytvoření',
      }),
      width: 120,
      CellComponent: TableCells.DateTimeCell,
      FilterComponent: TableFilterCells.FilterLocalDateTimeCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'referenceNumber',
      name: intl.formatMessage({
        id: 'ES__OUTGOING_DOCUMENTS__TABLE__COLUMN__REFERENCE_NUMBER',
        defaultMessage: 'Číslo jednací',
      }),
      width: 150,
      CellComponent: TableCells.TextCell,
      sortable: true,
      sortkey: 'referenceNumberSort',
      filterable: true,
    },
    {
      datakey: 'esFile.number.value',
      sortkey: 'esFile.number.valueSort',
      name: intl.formatMessage({
        id: 'ES__OUTGOING_DOCUMENTS__TABLE__COLUMN__ASSIGNED_FILE_NUMBER',
        defaultMessage: 'Spisová značka',
      }),
      width: 120,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'jid',
      name: intl.formatMessage({
        id: 'ES__OUTGOING_DOCUMENTS_PROCESSED__TABLE__COLUMN__JID',
        defaultMessage: 'JID',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'summary',
      name: intl.formatMessage({
        id: 'ES__OUTGOING_DOCUMENTS__TABLE__COLUMN__SUMMARY',
        defaultMessage: 'Stručný obsah',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'agenda',
      sortkey: 'agenda.name',
      filterkey: 'agenda.id',
      name: intl.formatMessage({
        id: 'ES__INTERNAL_DOCUMENTS__TABLE__COLUMN__AGENDA',
        defaultMessage: 'Agenda',
      }),
      width: 150,
      CellComponent: TableCells.TextCell,
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(useAgendas),
      valueMapper: TableCells.useSelectCellFactory(useAgendas),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'dispatchState',
      sortkey: 'dispatchState.name',
      filterkey: 'dispatchState.id',
      name: intl.formatMessage({
        id: 'ES__OUTGOING_DOCUMENTS__TABLE__COLUMN__DISPATCH_STATE',
        defaultMessage: 'Stav vypravení',
      }),
      width: 150,
      CellComponent: TableCells.TextCell,
      valueMapper: TableCells.useSelectCellFactory(useDispatchStateSource),
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(
        useDispatchStateSource
      ),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'decision',
      name: intl.formatMessage({
        id: 'ES__OUTGOING_DOCUMENTS__TABLE__COLUMN__DECISION',
        defaultMessage: 'Rozhodnutí',
      }),
      width: 120,
      CellComponent: TableCells.BooleanCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'decisionForm',
      sortkey: 'decisionForm.name',
      filterkey: 'decisionForm.id',
      name: intl.formatMessage({
        id: 'ES__OUTGOING_DOCUMENTS__TABLE__COLUMN__DECISION_FORM',
        defaultMessage: 'Forma rozhodnutí',
      }),
      width: 150,
      CellComponent: TableCells.TextCell,
      valueMapper: TableCells.useSelectCellFactory(useDecisionFormSource),
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(
        useDecisionFormSource
      ),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'esFile.judge',
      sortkey: 'esFile.judge.name',
      filterkey: 'esFile.judge.id',
      name: intl.formatMessage({
        id: 'ES__OUTGOING_DOCUMENTS__TABLE__COLUMN__JUDGE',
        defaultMessage: 'Soudce',
      }),
      width: 200,
      valueMapper: autocompleteColumnMapper,
      CellComponent: TableCells.TextCell,
      FilterComponent: TableFilterCells.useFilterAutocompleteCellFactory(
        useJudges,
        autocompleteLabelMapper
      ),
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'esFile.indexUserRole',
      sortkey: 'esFile.indexUserRole.name',
      filterkey: 'esFile.indexUserRole.id',
      name: intl.formatMessage({
        id: 'ES__OUTGOING_DOCUMENTS__TABLE__COLUMN__INDEX',
        defaultMessage: 'Rejstřík',
      }),
      width: 200,
      valueMapper: autocompleteColumnMapper,
      CellComponent: TableCells.TextCell,
      FilterComponent: TableFilterCells.useFilterAutocompleteCellFactory(
        useIndexes,
        autocompleteLabelMapper
      ),
      sortable: true,
      filterable: true,
    },
  ];
}
