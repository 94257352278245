import React from 'react';
import {FormattedMessage} from 'react-intl';
import {FormAutocomplete, FormTextField} from '@eas/common-web';
import {useLegalForms} from '@modules/dict-legal-form/dict-legal-form-api';
import {autocompleteLabelMapper} from '@components/form/misc/label-mappers';

export function LegalEntityFields() {
  const legalForms = useLegalForms();

  return (
    <>
      <FormTextField
        name="companyName"
        label={
          <FormattedMessage
            id="ES__SUBJECTS__DETAIL__FIELD_LABEL__NAME"
            defaultMessage="Název"
          />
        }
        required
      />
      <FormTextField
        name="ico"
        label={
          <FormattedMessage
            id="ES__SUBJECTS__DETAIL__FIELD_LABEL__ICO"
            defaultMessage="IČO"
          />
        }
      />
      <FormTextField
        name="dic"
        label={
          <FormattedMessage
            id="ES__SUBJECTS__DETAIL__FIELD_LABEL__DIC"
            defaultMessage="DIČ"
          />
        }
      />
      <FormTextField
        name="taxRegistrationNumber"
        label={
          <FormattedMessage
            id="ES__SUBJECTS__DETAIL__FIELD_LABEL__TAX_REGISTRATION_NUMBER"
            defaultMessage="Daňové registrační číslo"
          />
        }
      />
      <FormTextField
        name="esIdCode"
        label={
          <FormattedMessage
            id="ES__SUBJECTS__DETAIL__FIELD_LABEL__ES_ID_CODE"
            defaultMessage="Identifikační kód ES"
          />
        }
      />
      <FormTextField
        name="legalEntityIdCode"
        label={
          <FormattedMessage
            id="ES__SUBJECTS__DETAIL__FIELD_LABEL__LEGAL_ENTITY_ID_CODE"
            defaultMessage="Identifikační kód právnické osoby"
          />
        }
      />
      <FormTextField
        name="eori"
        label={
          <FormattedMessage
            id="ES__SUBJECTS__DETAIL__FIELD_LABEL__EORI"
            defaultMessage="EORI"
          />
        }
      />
      <FormTextField
        name="exciseNumber"
        label={
          <FormattedMessage
            id="ES__SUBJECTS__DETAIL__FIELD_LABEL__EXCISE_NUMBER"
            defaultMessage="Číslo pro účely spotřebních daní"
          />
        }
      />
      <FormAutocomplete
        name="legalForm"
        source={legalForms}
        label={
          <FormattedMessage
            id="ES__SUBJECTS__DETAIL__FIELD_LABEL__LEGAL_FORM"
            defaultMessage="Právní forma"
          />
        }
        labelMapper={autocompleteLabelMapper}
      />
    </>
  );
}
