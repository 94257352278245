import React, {ComponentType, ReactNode, useContext} from 'react';
import {FormattedMessage} from 'react-intl';
import {
  DetailContext,
  DetailMode,
  EvidenceContext,
  FormCheckbox,
  FormContext,
  FormPanel,
  FormSelect,
  FormTextField,
  useFormSelector,
  useStaticListSource,
} from '@eas/common-web';
import {ContactField, ContactFieldProps,} from '@composite/contact/contact-field';
import {HISTORY_SOURCE} from '@composite/history/history-api';
import {HistoryField} from '@composite/history/history-field';
import {StaticDataContext} from '@components/evidence/static-data-provider/static-data-provider';
import {Subject} from '@models';
import {SubjectType} from '@enums';
import {BusinessNaturalPersonFields} from './fields/business-natural-person-fields';
import {LegalEntityFields} from './fields/legal-entity-fields';
import {NaturalPersonFields} from './fields/natural-person-fields';
import {UnspecifiedSubjectFields} from './fields/unspecified-subject-fields';
import {SubjectHistoryContext} from './subject-history-context';
import {useHistoryDescriptors} from './subject-history-descriptors';

export function Fields({
  HistoryFields,
  display,
  contactsProps,
  panelLabel,
}: {
  HistoryFields?: ComponentType;
  display: 'IN_SUBJECT' | 'OUT_OF_SUBJECT';
  contactsProps?: Omit<ContactFieldProps, 'display' | 'disabled'>;
  panelLabel: ReactNode;
}) {
  const { setFieldValue } = useContext(FormContext);
  const { subjectTypes } = useContext(StaticDataContext);
  const { mode } = useContext(DetailContext);

  const { type } = useFormSelector((values: Subject) => ({
    type: values.type,
    contacts: values.contacts ?? [],
  }));

  const subjectTypeSource = useStaticListSource(subjectTypes);

  const handleTypeChange = () => {
    const allFields = [
      'companyName',
      'ico',
      'dic',
      'taxRegistrationNumber',
      'esIdCode',
      'legalEntityIdCode',
      'eori',
      'exciseNumber',
      'publicAuthority',
      'function',
      'firstName',
      'lastName',
      'middleName',
      'maidenFirstName',
      'maidenLastName',
      'note',
      'identified',
      'titleBefore',
      'titleAfter',
      'birthDate',
      'birthNumber',
      'birthPlace',
      'birthDistrict',
      'birthCountry',
      'addressing',
      'sex',
      'idCardNumber',
      'passportNumber',
      'otherIdentification',
      'lawyerEvidenceNumber',
    ];

    allFields.forEach((field) => setFieldValue(field, undefined));
  };

  return (
    <>
      <FormPanel label={panelLabel} expandable={false}>
        <FormSelect
          name="type"
          label={
            <FormattedMessage
              id="ES__SUBJECTS__DETAIL__FIELD_LABEL__TYPE"
              defaultMessage="Typ subjektu"
            />
          }
          source={subjectTypeSource}
          valueIsId={true}
          notifyChange={handleTypeChange as any}
          required
        />
        {!!type && (
          <FormTextField
            name="otherIdentification"
            label={
              <FormattedMessage
                id="ES__SUBJECTS__DETAIL__FIELD_LABEL__OTHER_IDENTIFICATION"
                defaultMessage="Jiná identifikace"
              />
            }
          />
        )}
        {type === SubjectType.LEGAL_ENTITY && <LegalEntityFields />}
        {type === SubjectType.BUSINESS_NATURAL_PERSON && (
          <BusinessNaturalPersonFields />
        )}
        {type === SubjectType.NATURAL_PERSON && <NaturalPersonFields />}
        {type === SubjectType.UNSPECIFIED && <UnspecifiedSubjectFields />}
        {!!type && (
          <>
            <FormTextField
              name="function"
              label={
                <FormattedMessage
                  id="ES__SUBJECTS__DETAIL__FIELD_LABEL__FUNCTION"
                  defaultMessage="Funkce"
                />
              }
            />
            <FormTextField
              name="lawyerEvidenceNumber"
              label={
                <FormattedMessage
                  id="ES__SUBJECTS__DETAIL__FIELD_LABEL__LAWYER_EVIDENCE_NUMBER"
                  defaultMessage="Evidenční číslo České advokátní komory"
                />
              }
            />
            <FormCheckbox
              name="publicAuthority"
              label={
                <FormattedMessage
                  id="ES__SUBJECTS__DETAIL__FIELD_LABEL__IS_PUBLIC_AUTHORITY"
                  defaultMessage="Orgán veřejné moci"
                />
              }
            />
            <FormCheckbox
              name="identified"
              label={
                <FormattedMessage
                  id="ES__SUBJECTS__DETAIL__FIELD_LABEL__IDENTIFIED"
                  defaultMessage="Ztotožněn"
                />
              }
            />
            <FormCheckbox
              name="active"
              label={
                <FormattedMessage
                  id="ES__SUBJECTS__DETAIL__FIELD_LABEL__ACTIVE"
                  defaultMessage="Aktivní"
                />
              }
              disabled={true}
            />
            <FormTextField
              name="note"
              label={
                <FormattedMessage
                  id="ES__SUBJECTS__DETAIL__FIELD_LABEL__NOTE"
                  defaultMessage="Poznámka"
                />
              }
            />
          </>
        )}
      </FormPanel>
      <ContactField display={display} {...contactsProps} />
      {HistoryFields && mode !== DetailMode.NEW && <HistoryFields />}
    </>
  );
}

export function FieldsWithHistory() {
  const { hookedSources } = useContext(EvidenceContext);
  const historySource = hookedSources[HISTORY_SOURCE]?.source;

  const historyDescriptors = useHistoryDescriptors();

  const HistoryFields = () => <HistoryField descriptors={historyDescriptors} />;

  return (
    <SubjectHistoryContext.Provider value={{ historySource }}>
      <Fields
        HistoryFields={HistoryFields}
        display="IN_SUBJECT"
        panelLabel={
          <FormattedMessage
            id="ES__SUBJECTS__DETAIL__PANEL_TITLE__GENERAL"
            defaultMessage="Obecné"
          />
        }
      />
    </SubjectHistoryContext.Provider>
  );
}
