import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  FormAutocomplete,
  FormLocalDateField,
  FormSelect,
  FormTextField,
  useStaticListSource,
} from '@eas/common-web';
import { useCountries } from '@modules/dict-country/dict-country-api';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';
import { autocompleteLabelMapper } from '@components/form/misc/label-mappers';

export function NaturalPersonFields() {
  const countries = useCountries();
  const { subjectSexes } = useContext(StaticDataContext);
  const subjectSexesSource = useStaticListSource(subjectSexes);

  return (
    <>
      <FormTextField
        name="firstName"
        label={
          <FormattedMessage
            id="ES__SUBJECTS__DETAIL__FIELD_LABEL__FIRST_NAME"
            defaultMessage="Jméno"
          />
        }
        required
      />
      <FormTextField
        name="middleName"
        label={
          <FormattedMessage
            id="ES__SUBJECTS__DETAIL__FIELD_LABEL__MIDDLE_NAME"
            defaultMessage="Prostřední jméno"
          />
        }
      />
      <FormTextField
        name="lastName"
        label={
          <FormattedMessage
            id="ES__SUBJECTS__DETAIL__FIELD_LABEL__LAST_NAME"
            defaultMessage="Příjmení"
          />
        }
        required
      />
      <FormTextField
        name="maidenFirstName"
        label={
          <FormattedMessage
            id="ES__SUBJECTS__DETAIL__FIELD_LABEL__MAIDEN_FIRST_NAME"
            defaultMessage="Rodné jméno"
          />
        }
      />
      <FormTextField
        name="maidenLastName"
        label={
          <FormattedMessage
            id="ES__SUBJECTS__DETAIL__FIELD_LABEL__MAIDEN_NAME"
            defaultMessage="Rodné příjmení"
          />
        }
      />
      <FormTextField
        name="titleBefore"
        label={
          <FormattedMessage
            id="ES__SUBJECTS__DETAIL__FIELD_LABEL__TITLE_BEFORE"
            defaultMessage="Titul před"
          />
        }
      />
      <FormTextField
        name="titleAfter"
        label={
          <FormattedMessage
            id="ES__SUBJECTS__DETAIL__FIELD_LABEL__TITLE_AFTER"
            defaultMessage="Titul za"
          />
        }
      />
      <FormLocalDateField
        name="birthDate"
        label={
          <FormattedMessage
            id="ES__SUBJECTS__DETAIL__FIELD_LABEL__BIRTH_DATE"
            defaultMessage="Datum narození"
          />
        }
        maxDatePicker={new Date().toISOString()}
      />
      <FormTextField
        name="birthNumber"
        label={
          <FormattedMessage
            id="ES__SUBJECTS__DETAIL__FIELD_LABEL__BIRTH_NUMBER"
            defaultMessage="Rodné číslo"
          />
        }
      />
      <FormTextField
        name="birthPlace"
        label={
          <FormattedMessage
            id="ES__SUBJECTS__DETAIL__FIELD_LABEL__BIRTH_PLACE"
            defaultMessage="Místo narození"
          />
        }
      />
      <FormTextField
        name="birthDistrict"
        label={
          <FormattedMessage
            id="ES__SUBJECTS__DETAIL__FIELD_LABEL__BIRTH_DISTRICT"
            defaultMessage="Okres narození"
          />
        }
      />
      <FormAutocomplete
        name="birthCountry"
        source={countries}
        label={
          <FormattedMessage
            id="ES__SUBJECTS__DETAIL__FIELD_LABEL__BIRTH_COUNTRY"
            defaultMessage="Stát narození"
          />
        }
        labelMapper={autocompleteLabelMapper}
      />
      <FormTextField
        name="addressing"
        label={
          <FormattedMessage
            id="ES__SUBJECTS__DETAIL__FIELD_LABEL__ADDRESSING"
            defaultMessage="Oslovení"
          />
        }
      />
      <FormSelect
        name="sex"
        label={
          <FormattedMessage
            id="ES__SUBJECTS__DETAIL__FIELD_LABEL__SEX"
            defaultMessage="Pohlaví"
          />
        }
        source={subjectSexesSource}
        valueIsId={true}
      />
      <FormTextField
        name="idCardNumber"
        label={
          <FormattedMessage
            id="ES__SUBJECTS__DETAIL__FIELD_LABEL__ID_CARD_NUMBER"
            defaultMessage="Číslo OP"
          />
        }
      />
      <FormTextField
        name="passportNumber"
        label={
          <FormattedMessage
            id="ES__SUBJECTS__DETAIL__FIELD_LABEL__PASSPORT_NUMBER"
            defaultMessage="Číslo pasu"
          />
        }
      />
    </>
  );
}
