import { noop } from 'lodash';
import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  ApiFilterOperation,
  FieldFilter,
  TableCells,
  TableColumn,
  TableFieldCellProps,
  TableFieldCells,
  TableFieldColumn,
  TableFilterCells,
  TextField,
  useStaticListSource,
} from '@eas/common-web';
import {
  ChallengedActCell,
  ParticipantCell,
} from '@modules/es-file/es-file-columns';
import { Redirect } from '@composite/redirect/redirect';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';
import {
  autocompleteLabelMapper,
  multipleAutocompleteColumnMapper,
  subjectContactColumnMapper,
} from '@components/form/misc/label-mappers';
import { CourtFile, Deadline, EsFileMerge, PlenaryOpinionFile } from '@models';
import { DocumentType, EvidenceBrowserUrl } from '@enums';
import { useEsFiles } from '../es-file/es-file-api';
import { useContacts } from '../subject/subject-api';

export function useCourtFileColumns() {
  const intl = useIntl();

  const { courtFileTypes } = useContext(StaticDataContext);
  const useCourtFileTypes = () => useStaticListSource(courtFileTypes);

  const columnDeliveryDate: TableColumn<CourtFile> = {
    datakey: 'deliveryDate',
    name: intl.formatMessage({
      id: 'ES__COURT_FILES__TABLE__COLUMN__DELIVERY_DATE',
      defaultMessage: 'Datum doručení',
    }),
    width: 150,
    CellComponent: TableCells.DateCell,
    FilterComponent: TableFilterCells.FilterDateCell,
    sortable: true,
    filterable: true,
  };

  const columnJid: TableColumn<CourtFile> = {
    datakey: 'jid',
    name: intl.formatMessage({
      id: 'ES__COURT_FILES__TABLE__COLUMN__JID',
      defaultMessage: 'JID',
    }),
    width: 200,
    CellComponent: TableCells.TextCell,
    sortable: true,
    filterable: true,
  };

  const columnOpponent: TableColumn<CourtFile> = {
    datakey: 'challengedActs',
    filterkey: 'challengedActs.label',
    sortkey: 'challengedActs.label',
    name: intl.formatMessage({
      id: 'ES__COURT_FILES__TABLE__COLUMN__OPPONENT',
      defaultMessage: 'Odpůrce',
    }),
    width: 200,
    CellComponent: ChallengedActCell,
    valueMapper: multipleAutocompleteColumnMapper,
    FilterComponent: TableFilterCells.FilterTextCell,
    filterOperation: ApiFilterOperation.CONTAINS,
    sortable: true,
    filterable: true,
  };

  const columnProposers: TableColumn<CourtFile> = {
    datakey: 'proposers',
    filterkey: 'proposers.contact.id',
    sortkey: 'proposers.label',
    name: intl.formatMessage({
      id: 'ES__COURT_FILES__TABLE__COLUMN__PROPOSERS',
      defaultMessage: 'Navrhovatel',
    }),
    width: 300,
    CellComponent: ParticipantCell('proposers'),
    valueMapper: subjectContactColumnMapper,
    FilterComponent: TableFilterCells.useFilterAutocompleteCellFactory(
      useContacts,
      autocompleteLabelMapper
    ),
    filterOperation: ApiFilterOperation.EQ,
    sortable: true,
    filterable: true,
  };

  const columnType: TableColumn<CourtFile> = {
    datakey: 'type',
    sortkey: 'type.name',
    filterkey: 'type.id',
    name: intl.formatMessage({
      id: 'ES__COURT_FILES__TABLE__COLUMN__COURT_TYPE',
      defaultMessage: 'Typ soudního spisu',
    }),
    width: 150,
    CellComponent: TableCells.TextCell,
    valueMapper: TableCells.useSelectCellFactory(useCourtFileTypes),
    FilterComponent:
      TableFilterCells.useFilterSelectCellFactory(useCourtFileTypes),
    filterOperation: ApiFilterOperation.EQ,
    sortable: true,
    filterable: true,
  };

  return {
    columnJid,
    columnDeliveryDate,
    columnOpponent,
    columnProposers,
    columnType,
  };
}

export function useContestedOpinionsColumns(): TableFieldColumn<PlenaryOpinionFile>[] {
  const { states } = useContext(StaticDataContext);

  const useStates = () => useStaticListSource(states);

  return [
    {
      datakey: 'id',
      name: '',
      width: 80,
      CellComponent: function Cell(
        props: TableFieldCellProps<PlenaryOpinionFile>
      ) {
        return (
          <Redirect
            url={`${EvidenceBrowserUrl.PLENARY_OPINION_FILES}`}
            id={props.value}
          />
        );
      },
    },
    {
      name: 'Spisová značka',
      datakey: 'number.value',
      width: 200,
    },
    {
      name: 'Stav',
      datakey: 'state',
      width: 200,
      CellComponent: TableFieldCells.useSelectCellFactory(useStates),
    },
    {
      name: 'Zdrojové spisové značky',
      datakey: 'sourceFiles',
      width: 300,
      CellComponent: TableFieldCells.useAutocompleteCellFactory({
        dataHook: useEsFiles,
        multiple: true,
        labelMapper: (value) => value.label ?? '',
      }),
    },
    {
      name: 'Napadené spisové značky',
      datakey: 'challengedFiles',
      width: 300,
      CellComponent: TableFieldCells.useAutocompleteCellFactory({
        dataHook: useEsFiles,
        multiple: true,
        labelMapper: (value) => value.label ?? '',
      }),
    },
  ];
}

const useStyles = makeStyles({
  mergedFilesCell: {
    display: 'flex',
    flexDirection: 'row',
  },
});

export function useMergedFilesColumns(): TableFieldColumn<EsFileMerge>[] {
  const classes = useStyles();

  const MergedFilesCell = ({ value }: TableFieldCellProps<EsFileMerge>) => {
    return (
      <div>
        {value?.map((file: CourtFile) => (
          <div key={file.id} className={classes.mergedFilesCell}>
            <Redirect url={EvidenceBrowserUrl.ES_FILES_ALL} id={file.id} />
            <TextField value={file.label} onChange={noop} disabled />
          </div>
        ))}
      </div>
    );
  };

  const ContinuingEsFileCell = ({
    value,
  }: TableFieldCellProps<EsFileMerge>) => {
    return (
      <div className={classes.mergedFilesCell}>
        <Redirect url={EvidenceBrowserUrl.ES_FILES_ALL} id={value.id} />
        <TextField value={value.label} onChange={noop} disabled />
      </div>
    );
  };

  return [
    {
      name: 'Pokračující spis',
      datakey: 'continuingEsFile',
      width: 200,
      CellComponent: ContinuingEsFileCell,
    },
    {
      name: 'Datum spojení',
      datakey: 'mergeDate',
      width: 150,
      CellComponent: TableFieldCells.DateCell,
    },
    {
      name: 'Spojené spisy - uzavřené',
      datakey: 'mergedEsFiles',
      width: 300,
      CellComponent: MergedFilesCell,
    },
  ];
}

function getDocumentUrl(type: DocumentType) {
  switch (type) {
    case DocumentType.DELIVERED:
      return EvidenceBrowserUrl.DOCUMENTS_DELIVERED_PROCESSED;
      break;
    case DocumentType.INTERNAL:
      return EvidenceBrowserUrl.DOCUMENTS_INTERNAL;
      break;
    case DocumentType.OUTGOING:
      return EvidenceBrowserUrl.DOCUMENTS_OUTGOING;
      break;

    default:
      return EvidenceBrowserUrl.DOCUMENTS_DELIVERED_PROCESSED;
      break;
  }
}

export function useRunningDeadlineColumns(): TableFieldColumn<Deadline>[] {
  const intl = useIntl();

  return [
    {
      datakey: 'id',
      name: '',
      width: 80,
      CellComponent: function Cell(props: TableFieldCellProps<Deadline>) {
        const url = getDocumentUrl(props.rowValue.document!.type!);

        return (
          <>
            <Redirect url={url} id={props.rowValue.document!.id} />
          </>
        );
      },
    },
    {
      name: intl.formatMessage({
        id: 'ES__DEADLINES_TOOLBAR__COLUMN__REFERENCE_NUMBER',
        defaultMessage: 'Číslo jednací',
      }),
      datakey: 'document.referenceNumber',
      sortkey: 'document.referenceNumber',
      width: 120,
    },
    {
      name: intl.formatMessage({
        id: 'ES__DEADLINES_TOOLBAR__COLUMN__DESCRIPTION',
        defaultMessage: 'Popis',
      }),
      datakey: 'description',
      sortkey: 'description',
      width: 200,
    },
    {
      name: intl.formatMessage({
        id: 'ES__DEADLINES_TOOLBAR__COLUMN__DURATION',
        defaultMessage: 'Počet dnů',
      }),
      datakey: 'duration',
      sortkey: 'duration',
      width: 80,
    },
    {
      name: intl.formatMessage({
        id: 'ES__DEADLINES_TOOLBAR__COLUMN__STARTING_FROM',
        defaultMessage: 'Počáteční datum',
      }),
      datakey: 'startingFrom',
      sortkey: 'startingFrom',
      width: 120,
      CellComponent: TableFieldCells.DateCell,
    },
    {
      name: intl.formatMessage({
        id: 'ES__DEADLINES_TOOLBAR__COLUMN__END_DATE',
        defaultMessage: 'Konečné datum',
      }),
      datakey: 'endDate',
      sortkey: 'endDate',
      width: 120,
      CellComponent: TableFieldCells.DateCell,
    },
    {
      name: intl.formatMessage({
        id: 'ES__DEADLINES_TOOLBAR__COLUMN__DAYS_REMAINING',
        defaultMessage: 'Zbývá',
      }),
      datakey: 'daysRemaining',
      sortkey: 'daysRemaining',
      width: 60,
    },
  ];
}

export function useRunningDeadlineFiltersFields() {
  const intl = useIntl();

  const filtersFields = [
    {
      label: intl.formatMessage({
        id: 'ES__DEADLINES_TOOLBAR__FILTER__STARTING_FROM',
        defaultMessage: 'Počáteční datum',
      }),
      datakey: 'startingFrom',
      filterkey: 'startingFrom',
      defaultOperation: ApiFilterOperation.EQ,
      FilterComponent: FieldFilter.FilterDateCell,
    },
    {
      label: intl.formatMessage({
        id: 'ES__DEADLINES_TOOLBAR__FILTER__END_DATE',
        defaultMessage: 'Konečné datum',
      }),
      datakey: 'endDate',
      filterkey: 'endDate',
      defaultOperation: ApiFilterOperation.EQ,
      FilterComponent: FieldFilter.FilterDateCell,
    },
    {
      label: intl.formatMessage({
        id: 'ES__DEADLINES_TOOLBAR__FILTER__DAYS_REMAINING',
        defaultMessage: 'Zbývá',
      }),
      datakey: 'daysRemaining',
      filterkey: 'daysRemaining',
      defaultOperation: ApiFilterOperation.EQ,
      FilterComponent: FieldFilter.FilterNumberCell,
    },
    {
      label: intl.formatMessage({
        id: 'ES__DEADLINES_TOOLBAR__FILTER__REFERENCE_NUMBER',
        defaultMessage: 'Číslo jednací',
      }),
      datakey: 'document.referenceNumber',
      filterkey: 'document.referenceNumber',
      defaultOperation: ApiFilterOperation.EQ,
      FilterComponent: FieldFilter.FilterTextCell,
    },
    {
      label: intl.formatMessage({
        id: 'ES__DEADLINES_TOOLBAR__FILTER__DESCRIPTION',
        defaultMessage: 'Popis',
      }),
      datakey: 'description',
      filterkey: 'description',
      defaultOperation: ApiFilterOperation.EQ,
      FilterComponent: FieldFilter.FilterTextCell,
    },
    {
      label: intl.formatMessage({
        id: 'ES__DEADLINES_TOOLBAR__FILTER__DURATION',
        defaultMessage: 'Počet dnů',
      }),
      datakey: 'duration',
      filterkey: 'duration',
      defaultOperation: ApiFilterOperation.EQ,
      FilterComponent: FieldFilter.FilterNumberCell,
    },
  ];

  return filtersFields;
}
