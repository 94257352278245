import React, {useContext} from 'react';
import {FormattedMessage} from 'react-intl';
import {FormAutocomplete, FormLocalDateField, FormSelect, FormTextField, useStaticListSource,} from '@eas/common-web';
import {useCountries} from '@modules/dict-country/dict-country-api';
import {useLegalForms} from '@modules/dict-legal-form/dict-legal-form-api';
import {StaticDataContext} from '@components/evidence/static-data-provider/static-data-provider';
import {autocompleteLabelMapper} from '@components/form/misc/label-mappers';

export function UnspecifiedSubjectFields() {
  const countries = useCountries();
  const legalForms = useLegalForms();
  const { subjectSexes } = useContext(StaticDataContext);
  const subjectSexesSource = useStaticListSource(subjectSexes);

  return (
    <>
      <FormTextField
        name="firstName"
        label={
          <FormattedMessage
            id="ES__SUBJECTS__DETAIL__FIELD_LABEL__FIRST_NAME"
            defaultMessage="Jméno"
          />
        }
      />
      <FormTextField
        name="lastName"
        label={
          <FormattedMessage
            id="ES__SUBJECTS__DETAIL__FIELD_LABEL__LAST_NAME"
            defaultMessage="Příjmení"
          />
        }
      />
      <FormTextField
        name="middleName"
        label={
          <FormattedMessage
            id="ES__SUBJECTS__DETAIL__FIELD_LABEL__MIDDLE_NAME"
            defaultMessage="Prostřední jméno"
          />
        }
      />
      <FormTextField
        name="maidenFirstName"
        label={
          <FormattedMessage
            id="ES__SUBJECTS__DETAIL__FIELD_LABEL__MAIDEN_FIRST_NAME"
            defaultMessage="Rodné jméno"
          />
        }
      />
      <FormTextField
        name="maidenLastName"
        label={
          <FormattedMessage
            id="ES__SUBJECTS__DETAIL__FIELD_LABEL__MAIDEN_NAME"
            defaultMessage="Rodné příjmení"
          />
        }
      />
      <FormTextField
        name="addressing"
        label={
          <FormattedMessage
            id="ES__SUBJECTS__DETAIL__FIELD_LABEL__ADDRESSING"
            defaultMessage="Oslovení"
          />
        }
      />
      <FormSelect
        name="sex"
        label={
          <FormattedMessage
            id="ES__SUBJECTS__DETAIL__FIELD_LABEL__SEX"
            defaultMessage="Pohlaví"
          />
        }
        source={subjectSexesSource}
        valueIsId={true}
      />
      <FormTextField
        name="idCardNumber"
        label={
          <FormattedMessage
            id="ES__SUBJECTS__DETAIL__FIELD_LABEL__ID_CARD_NUMBER"
            defaultMessage="Číslo OP"
          />
        }
      />
      <FormTextField
        name="passportNumber"
        label={
          <FormattedMessage
            id="ES__SUBJECTS__DETAIL__FIELD_LABEL__PASSPORT_NUMBER"
            defaultMessage="Číslo pasu"
          />
        }
      />
      <FormTextField
        name="titleBefore"
        label={
          <FormattedMessage
            id="ES__SUBJECTS__DETAIL__FIELD_LABEL__TITLE_BEFORE"
            defaultMessage="Titul před"
          />
        }
      />
      <FormTextField
        name="titleAfter"
        label={
          <FormattedMessage
            id="ES__SUBJECTS__DETAIL__FIELD_LABEL__TITLE_AFTER"
            defaultMessage="Titul za"
          />
        }
      />
      <FormLocalDateField
        name="birthDate"
        label={
          <FormattedMessage
            id="ES__SUBJECTS__DETAIL__FIELD_LABEL__BIRTH_DATE"
            defaultMessage="Datum narození"
          />
        }
        maxDatePicker={new Date().toISOString()}
      />
      <FormTextField
        name="birthNumber"
        label={
          <FormattedMessage
            id="ES__SUBJECTS__DETAIL__FIELD_LABEL__BIRTH_NUMBER"
            defaultMessage="Rodné číslo"
          />
        }
      />
      <FormTextField
        name="birthPlace"
        label={
          <FormattedMessage
            id="ES__SUBJECTS__DETAIL__FIELD_LABEL__BIRTH_PLACE"
            defaultMessage="Místo narození"
          />
        }
      />
      <FormTextField
        name="birthDistrict"
        label={
          <FormattedMessage
            id="ES__SUBJECTS__DETAIL__FIELD_LABEL__BIRTH_DISTRICT"
            defaultMessage="Okres narození"
          />
        }
      />
      <FormAutocomplete
        name="birthCountry"
        source={countries}
        label={
          <FormattedMessage
            id="ES__SUBJECTS__DETAIL__FIELD_LABEL__BIRTH_COUNTRY"
            defaultMessage="Stát narození"
          />
        }
        labelMapper={autocompleteLabelMapper}
      />
      <FormTextField
        name="companyName"
        label={
          <FormattedMessage
            id="ES__SUBJECTS__DETAIL__FIELD_LABEL__NAME"
            defaultMessage="Název společnosti"
          />
        }
      />
      <FormTextField
        name="ico"
        label={
          <FormattedMessage
            id="ES__SUBJECTS__DETAIL__FIELD_LABEL__ICO"
            defaultMessage="IČO"
          />
        }
      />
      <FormTextField
        name="dic"
        label={
          <FormattedMessage
            id="ES__SUBJECTS__DETAIL__FIELD_LABEL__DIC"
            defaultMessage="DIČ"
          />
        }
      />
      <FormTextField
        name="taxRegistrationNumber"
        label={
          <FormattedMessage
            id="ES__SUBJECTS__DETAIL__FIELD_LABEL__TAX_REGISTRATION_NUMBER"
            defaultMessage="Daňové registrační číslo"
          />
        }
      />
      <FormTextField
        name="esIdCode"
        label={
          <FormattedMessage
            id="ES__SUBJECTS__DETAIL__FIELD_LABEL__ES_ID_CODE"
            defaultMessage="ES ID kód"
          />
        }
      />
      <FormTextField
        name="legalEntityIdCode"
        label={
          <FormattedMessage
            id="ES__SUBJECTS__DETAIL__FIELD_LABEL__LEGAL_ENTITY_ID_CODE"
            defaultMessage="ID kód právnické osoby"
          />
        }
      />
      <FormTextField
        name="eori"
        label={
          <FormattedMessage
            id="ES__SUBJECTS__DETAIL__FIELD_LABEL__EORI"
            defaultMessage="EORI"
          />
        }
      />
      <FormTextField
        name="exciseNumber"
        label={
          <FormattedMessage
            id="ES__SUBJECTS__DETAIL__FIELD_LABEL__EXCISE_NUMBER"
            defaultMessage="Číslo pro účely spotřebních daní"
          />
        }
      />
      <FormAutocomplete
        name="legalForm"
        source={legalForms}
        label={
          <FormattedMessage
            id="ES__SUBJECTS__DETAIL__FIELD_LABEL__LEGAL_FORM"
            defaultMessage="Právní forma"
          />
        }
        labelMapper={autocompleteLabelMapper}
      />
    </>
  );
}
