import { stubFalse } from 'lodash';
import React, {
  ReactElement,
  Ref,
  forwardRef,
  useContext,
  useEffect,
} from 'react';
import { useIntl } from 'react-intl';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Typography from '@material-ui/core/Typography';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import RemoveIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Gesture from '@material-ui/icons/Gesture';
import LocalPostOfficeIcon from '@material-ui/icons/LocalPostOffice';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import {
  ActionButtonComponentProps,
  ActionButtonHandle,
  DetailContext,
  DetailHandle,
  DetailMode,
  TableFieldToolbarButton,
  TableFieldToolbarProps,
} from '@eas/common-web';
import { DetailActionButton } from '@components/action-button/detail-action-button';
import { useStyles } from '@components/form/table-field/form-table-toolbar-styles';
import { Document, DocumentAttachment } from '@models';
import { AttachmentType, DocumentType, UIElement } from '@enums';
import { CopyDraftButton } from '../drafts/dialog-actions/copy-draft-hook';
import {
  AttachmentBulkCreateDialog,
  AttachmentDialog,
} from './attachment-dialog';
import { AttachmentFieldProps } from './attachment-field';
import {
  useAddAttachmentBulkValidationSchema,
  useAddAttachmentValidationSchema,
} from './attachment-schema';
import { useAddAttachmentBulkDialog } from './dialog-actions/add-attachment-bulk-hook';
import { useAddAttachmentDialog } from './dialog-actions/add-attachment-hook';
import { useConvertToPdfDialog } from './dialog-actions/convert-to-pdf-hook';
import { CopyAttachmentButton } from './dialog-actions/copy-attachment-hook';
import { useDeleteAttachmentDialog } from './dialog-actions/delete-attachment-hook';
import { useDownloadValidationReportDialog } from './dialog-actions/download-validation-report-hook';
import { useEditAttachmentDialog } from './dialog-actions/edit-attachment-hook';
import { useRevalidateAttachmentDialog } from './dialog-actions/revalidate-attachment-hook';
import { useSealAttachmentDialog } from './dialog-actions/seal-attachment-hook';
import { useSignAttachmentDialog } from './dialog-actions/sign-attachment-hook';

export const AttachmentToolbar = forwardRef(function AttachmentToolbar(
  {
    selectedIndex,
    setSelectedIndex,
    attachments,
    attachmentType,
    defaultName,
    hasPermission,
    disabled = false,
    onAddedAttachment,
    showAddButton = true,
    showEditButton = true,
    showDeleteButton = true,
    showRevalidateButton = true,
    showDownloadReportButton = true,
    disableEditButton = stubFalse,
  }: TableFieldToolbarProps & AttachmentFieldProps,
  ref: Ref<ActionButtonHandle>
) {
  const intl = useIntl();
  const classes = useStyles();

  const { source, isLocked } =
    useContext<DetailHandle<Document>>(DetailContext);

  const { callApi: addAttachmentApiCall } = useAddAttachmentDialog();
  const { callApi: editAttachmentApiCall } = useEditAttachmentDialog();
  const { callApi: deleteAttachmentApiCall } = useDeleteAttachmentDialog();
  const { callApi: addAttachmentBulkApiCall } = useAddAttachmentBulkDialog();
  const { callApi: revalidateAttachmentApiCall, RevalidateButton } =
    useRevalidateAttachmentDialog();
  const { callApi: downloadReportApiCall, DownloadValidationReportButton } =
    useDownloadValidationReportDialog();
  const { callApi: convertToPdfApiCall } = useConvertToPdfDialog();
  const { callApi: sealAttachmentApiCall } = useSealAttachmentDialog();
  const { callApi: signAttachmentApiCall } = useSignAttachmentDialog();

  useEffect(
    () => {
      setSelectedIndex(undefined);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [source]
  );

  const validationSchema = useAddAttachmentValidationSchema();
  const addAttachmentBulkValidationSchema =
    useAddAttachmentBulkValidationSchema();

  return (
    <div className={classes.tableActions}>
      <ButtonGroup
        size="small"
        variant="outlined"
        className={classes.buttonGroup}
      >
        {showAddButton && (
          <>
            <DetailActionButton
              promptKey={`ADD_ATTACHMENT_${attachmentType}`}
              apiCall={(id, values) => addAttachmentApiCall(id, values)}
              onSuccess={async () => {
                source.refresh();
                setSelectedIndex(undefined);
              }}
              onResult={onAddedAttachment}
              ButtonComponent={({
                onClick,
                label,
              }: ActionButtonComponentProps) => (
                <TableFieldToolbarButton
                  IconComponent={ControlPointIcon}
                  show={true}
                  disabled={
                    isLocked ||
                    disabled ||
                    !hasPermission(UIElement.Attachment.ADD_ATTACHMENT)
                  }
                  title={label}
                  onClick={onClick}
                  color="primary"
                  variant="contained"
                >
                  <Typography variant="body2">
                    {intl.formatMessage({
                      id: 'ES__ATTACHMENTS__ADD_ATTACHMENT__BUTTON',
                      defaultMessage: 'Přidat',
                    })}
                  </Typography>
                </TableFieldToolbarButton>
              )}
              buttonLabel={intl.formatMessage({
                id: 'ES__ATTACHMENTS__ADD_ATTACHMENT',
                defaultMessage: 'Přidat přílohu',
              })}
              formInitialValues={
                {
                  name: defaultName,
                  type: attachmentType,
                } as DocumentAttachment
              }
              dialogTitle={intl.formatMessage({
                id: 'ES__ATTACHMENTS__ADD_ATTACHMENT__TITLE',
                defaultMessage: 'Příloha',
              })}
              dialogText={intl.formatMessage({
                id: 'ES__ATTACHMENTS__ADD_ATTACHMENT__TEXT',
                defaultMessage:
                  'Vyplněním formuláře přidáte k dokumentu novou přílohu.',
              })}
              FormFields={function Fields() {
                return (
                  <AttachmentDialog
                    documentId={source.data?.id ?? ''}
                    documentType={source.data?.type}
                  />
                );
              }}
              formValidationSchema={validationSchema}
              dialogWidth={700}
              modes={[DetailMode.VIEW, DetailMode.EDIT]}
            />
            {attachmentType === AttachmentType.OUTGOING && (
              <CopyAttachmentButton
                disabled={
                  disabled ||
                  !hasPermission(UIElement.Attachment.COPY_ATTACHMENT)
                }
              />
            )}
            {[AttachmentType.INTERNAL, AttachmentType.OUTGOING].includes(
              attachmentType!
            ) && (
              <CopyDraftButton
                disabled={
                  disabled || !hasPermission(UIElement.Attachment.COPY_DRAFT)
                }
              />
            )}
            {attachmentType === AttachmentType.OTHER && (
              <DetailActionButton
                promptKey={`ADD_ATTACHMENT_BULK_${attachmentType}`}
                apiCall={addAttachmentBulkApiCall}
                buttonLabel={intl.formatMessage({
                  id: 'ES__DOCUMENT_DELIVERED__ACTIONBAR__ADD_ATTACHMENT_BULK',
                  defaultMessage: 'Přidat přílohy hromadně',
                })}
                dialogTitle={intl.formatMessage({
                  id: 'ES__DOCUMENT_DELIVERED__DIALOG_ADD_ATTACHMENT_BULK__TITLE',
                  defaultMessage: 'Přidat přílohy hromadně',
                })}
                dialogText={intl.formatMessage({
                  id: 'ES__DOCUMENT_DELIVERED__DIALOG_ADD_ATTACHMENT_BULK__TEXT',
                  defaultMessage:
                    'Vyplněním formuláře se k dokumentu vygenerují nové přílohy.',
                })}
                ButtonComponent={({
                  onClick,
                  label,
                }: ActionButtonComponentProps) => (
                  <TableFieldToolbarButton
                    IconComponent={ControlPointIcon}
                    show={true}
                    disabled={
                      isLocked ||
                      disabled ||
                      !hasPermission(UIElement.Attachment.ADD_ATTACHMENT_BULK)
                    }
                    title={label}
                    onClick={onClick}
                    color="primary"
                    variant="contained"
                  >
                    <Typography variant="body2">
                      {intl.formatMessage({
                        id: 'ES__ATTACHMENTS__ADD_ATTACHMENT_BULK__BUTTON',
                        defaultMessage: 'Přidat hromadně',
                      })}
                    </Typography>
                  </TableFieldToolbarButton>
                )}
                FormFields={AttachmentBulkCreateDialog}
                formValidationSchema={addAttachmentBulkValidationSchema}
                dialogWidth={700}
              />
            )}
          </>
        )}
        {showEditButton && (
          <DetailActionButton
            ref={ref}
            buttonDisabled={selectedIndex === undefined}
            promptKey={`EDIT_ATTACHMENT_${attachmentType}`}
            apiCall={(id, formData) => editAttachmentApiCall(id, formData)}
            buttonLabel={intl.formatMessage({
              id: 'ES__ATTACHMENTS__EDIT_ATTACHMENT',
              defaultMessage: 'Upravit přílohu',
            })}
            dialogTitle={intl.formatMessage({
              id: 'ES__ATTACHMENTS__ADD_ATTACHMENT__TITLE',
              defaultMessage: 'Příloha',
            })}
            dialogText={intl.formatMessage({
              id: 'ES__ATTACHMENTS__ADD_ATTACHMENT__TEXT',
              defaultMessage: 'Vyplněním formuláře upravíte přílohu.',
            })}
            dialogWidth={700}
            FormFields={function Fields() {
              return (
                <AttachmentDialog
                  documentId={source.data?.id ?? ''}
                  documentType={source.data?.type}
                  disabled={
                    selectedIndex !== undefined &&
                    disableEditButton(attachments[selectedIndex])
                  }
                />
              );
            }}
            onSuccess={async () => {
              source.refresh();
              setSelectedIndex(undefined);
            }}
            ButtonComponent={({
              onClick,
              label,
            }: ActionButtonComponentProps) => (
              <TableFieldToolbarButton
                IconComponent={EditIcon}
                show={true}
                disabled={
                  selectedIndex === undefined ||
                  disabled ||
                  !hasPermission(UIElement.Attachment.EDIT_ATTACHMENT) ||
                  disableEditButton(attachments[selectedIndex])
                }
                title={label}
                onClick={onClick}
                color="default"
              >
                <Typography variant="body2">
                  {intl.formatMessage({
                    id: 'ES__ATTACHMENTS__EDIT_ATTACHMENT__BUTTON',
                    defaultMessage: 'Upravit',
                  })}
                </Typography>
              </TableFieldToolbarButton>
            )}
            formInitialValues={
              selectedIndex !== undefined
                ? attachments?.[selectedIndex]
                : ({} as DocumentAttachment)
            }
            dialogShowConfirm={
              selectedIndex !== undefined &&
              !disableEditButton(attachments[selectedIndex])
            }
            formValidationSchema={validationSchema}
            modes={[DetailMode.VIEW, DetailMode.EDIT]}
          />
        )}

        {showDeleteButton && (
          <DetailActionButton
            buttonDisabled={selectedIndex === undefined}
            promptKey={`DELETE_ATTACHMENT_${attachmentType}`}
            apiCall={(id) =>
              deleteAttachmentApiCall(id, attachments![selectedIndex!].id)
            }
            onSuccess={async () => {
              source.refresh();
              setSelectedIndex(undefined);
            }}
            buttonLabel={intl.formatMessage({
              id: 'ES__ATTACHMENTS__DELETE_ATTACHMENT',
              defaultMessage: 'Smazat přílohu',
            })}
            dialogTitle={intl.formatMessage({
              id: 'ES__ATTACHMENTS__EDIT_ATTACHMENT__TITLE',
              defaultMessage: 'Příloha',
            })}
            dialogText={intl.formatMessage({
              id: 'ES__ATTACHMENTS__EDIT_ATTACHMENT__TEXT',
              defaultMessage: 'Skutečně chcete smazat přílohu?',
            })}
            ButtonComponent={({
              onClick,
              label,
            }: ActionButtonComponentProps) => (
              <TableFieldToolbarButton
                IconComponent={RemoveIcon}
                show={true}
                disabled={
                  selectedIndex === undefined ||
                  disabled ||
                  !hasPermission(UIElement.Attachment.DELETE_ATTACHMENT)
                }
                title={label}
                onClick={onClick}
                color="secondary"
              >
                <Typography variant="body2">
                  {intl.formatMessage({
                    id: 'ES__ATTACHMENTS__DELETE_ATTACHMENT__BUTTON',
                    defaultMessage: 'Smazat',
                  })}
                </Typography>
              </TableFieldToolbarButton>
            )}
            modes={[DetailMode.VIEW, DetailMode.EDIT]}
          />
        )}
      </ButtonGroup>
      <ButtonGroup className={classes.buttonGroup}>
        {source.data?.type === DocumentType.DELIVERED && (
          <>
            {showRevalidateButton && (
              <RevalidateButton
                disabled={
                  selectedIndex === undefined ||
                  disabled ||
                  !attachments![selectedIndex!].file ||
                  !hasPermission(UIElement.Attachment.REVALIDATE_ATTACHMENT)
                }
                apiCall={async () =>
                  await revalidateAttachmentApiCall(
                    attachments![selectedIndex!].id
                  )
                }
              />
            )}
            {showDownloadReportButton && (
              <DownloadValidationReportButton
                apiCall={async () =>
                  await downloadReportApiCall(attachments![selectedIndex!].id)
                }
                disabled={
                  selectedIndex === undefined ||
                  disabled ||
                  !attachments![selectedIndex!].validation?.pdfReportId ||
                  !hasPermission(
                    UIElement.Attachment.DOWNLOAD_VALIDATION_REPORT
                  )
                }
              />
            )}
          </>
        )}
        {source?.data?.type === DocumentType.OUTGOING && (
          <>
            <DetailActionButton
              buttonDisabled={selectedIndex === undefined}
              promptKey="CONVERT_TO_PDF"
              onSuccess={async () => {
                source.refresh();
                setSelectedIndex(undefined);
              }}
              apiCall={(id) =>
                convertToPdfApiCall(id, attachments![selectedIndex!].id)
              }
              buttonLabel={intl.formatMessage({
                id: 'ES__ATTACHMENTS__CONVERT_TO_PDF',
                defaultMessage: 'Převést do PDF',
              })}
              dialogTitle={intl.formatMessage({
                id: 'ES__ATTACHMENTS__CONVERT_TO_PDF__TITLE',
                defaultMessage: 'Převod do PDF',
              })}
              dialogText={intl.formatMessage({
                id: 'ES__ATTACHMENTS__CONVERT_TO_PDF__TEXT',
                defaultMessage: 'Opravdu chcete přílohu převést do PDF?',
              })}
              ButtonComponent={({
                onClick,
                label,
              }: ActionButtonComponentProps) => (
                <TableFieldToolbarButton
                  IconComponent={PictureAsPdfIcon}
                  show={true}
                  disabled={
                    selectedIndex === undefined ||
                    disabled ||
                    !attachments![selectedIndex!].file ||
                    !hasPermission(UIElement.Attachment.CONVERT_TO_PDF)
                  }
                  title={label}
                  onClick={onClick}
                  color="default"
                >
                  <Typography variant="body2">
                    {intl.formatMessage({
                      id: 'ES__ATTACHMENTS__CONVERT_TO_PDF__BUTTON',
                      defaultMessage: 'Převést do PDF',
                    })}
                  </Typography>
                </TableFieldToolbarButton>
              )}
              modes={[DetailMode.VIEW, DetailMode.EDIT]}
            />
            <DetailActionButton
              buttonDisabled={selectedIndex === undefined}
              promptKey="SEAL_ATTACHMENT"
              onSuccess={async () => {
                source.refresh();
                setSelectedIndex(undefined);
              }}
              apiCall={(id) =>
                sealAttachmentApiCall(id, attachments![selectedIndex!].id)
              }
              buttonLabel={intl.formatMessage({
                id: 'ES__ATTACHMENTS__SEAL_ATTACHMENT',
                defaultMessage: 'Opatřit el. pečetí',
              })}
              dialogTitle={intl.formatMessage({
                id: 'ES__ATTACHMENTS__SEAL_ATTACHMENT__TITLE',
                defaultMessage: 'Opatření el. pečetí',
              })}
              dialogText={intl.formatMessage({
                id: 'ES__ATTACHMENTS__SEAL_ATTACHMENT__TEXT',
                defaultMessage: 'Opravdu chcete přílohu opatřit el. pečetí?',
              })}
              ButtonComponent={({
                onClick,
                label,
              }: ActionButtonComponentProps) => (
                <TableFieldToolbarButton
                  IconComponent={LocalPostOfficeIcon}
                  show={true}
                  disabled={
                    selectedIndex === undefined ||
                    disabled ||
                    !attachments![selectedIndex!].file ||
                    !hasPermission(UIElement.Attachment.SIGN_ATTACHMENT)
                  }
                  title={label}
                  onClick={onClick}
                  color="default"
                >
                  <Typography variant="body2">
                    {intl.formatMessage({
                      id: 'ES__ATTACHMENTS__SEAL_ATTACHMENT__BUTTON',
                      defaultMessage: 'Opatřit el. pečetí',
                    })}
                  </Typography>
                </TableFieldToolbarButton>
              )}
              modes={[DetailMode.VIEW, DetailMode.EDIT]}
            />
            <DetailActionButton
              buttonDisabled={selectedIndex === undefined}
              promptKey="SIGN_ATTACHMENT"
              submitCallback={async () => {
                await signAttachmentApiCall(
                  attachments![selectedIndex!].document.id,
                  attachments![selectedIndex!].id
                );
                source.refresh();
                setSelectedIndex(undefined);
              }}
              buttonLabel={intl.formatMessage({
                id: 'ES__ATTACHMENTS__SIGN_ATTACHMENT',
                defaultMessage: 'Podepsat',
              })}
              dialogTitle={intl.formatMessage({
                id: 'ES__ATTACHMENTS__SIGN_ATTACHMENT__TITLE',
                defaultMessage: 'Podepsat',
              })}
              dialogText={intl.formatMessage({
                id: 'ES__ATTACHMENTS__SIGN_ATTACHMENT__TEXT',
                defaultMessage: 'Opravdu chcete přílohu podepsat?',
              })}
              ButtonComponent={({
                onClick,
                label,
              }: ActionButtonComponentProps) => (
                <TableFieldToolbarButton
                  IconComponent={Gesture}
                  show={true}
                  disabled={
                    selectedIndex === undefined ||
                    disabled ||
                    !attachments![selectedIndex!].file ||
                    !hasPermission(UIElement.Attachment.SIGN_ATTACHMENT)
                  }
                  title={label}
                  onClick={onClick}
                  color="default"
                >
                  <Typography variant="body2">
                    {intl.formatMessage({
                      id: 'ES__ATTACHMENTS__SIGN_ATTACHMENT__BUTTON',
                      defaultMessage: 'Podepsat',
                    })}
                  </Typography>
                </TableFieldToolbarButton>
              )}
              modes={[DetailMode.VIEW, DetailMode.EDIT]}
            />
          </>
        )}
      </ButtonGroup>
    </div>
  );
}) as (
  p: TableFieldToolbarProps &
    AttachmentFieldProps & {
      ref: Ref<ActionButtonHandle>;
    }
) => ReactElement;
