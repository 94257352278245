import React from 'react';
import { useIntl } from 'react-intl';
import { Evidence } from '@eas/common-web';
import { useInfFiles } from '../inf-files';
import { useColumns } from './inf-file-all-columns';

export function InfFilesAll() {
  const intl = useIntl();
  const columns = useColumns();

  const { evidenceBag } = useInfFiles({
    evidenceProps: {
      version: 3,
      tableProps: {
        columns,
        tableName: intl.formatMessage({
          id: 'ES__INF_FILES__ALL__TABLE__TITLE',
          defaultMessage: 'INF spisy',
        }),
      },
      detailProps: {
        toolbarProps: {
          showBtn: (btn) => !['NEW'].includes(btn),
        },
      },
    },
  });

  return <Evidence {...evidenceBag} />;
}
