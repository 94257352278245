import React from 'react';
import { useIntl } from 'react-intl';
import { ApiFilterOperation, Evidence } from '@eas/common-web';
import { EsFileState } from '@enums';
import { useCourtFiles } from '../court-files';
import { useColumns } from './court-file-register-new-columns';

export function CourtFilesRegisterNew() {
  const intl = useIntl();
  const columns = useColumns();

  const { evidenceBag } = useCourtFiles({
    evidenceProps: {
      version: 10,
      tableProps: {
        columns,
        defaultPreFilters: [
          {
            field: 'state.id',
            operation: ApiFilterOperation.EQ,
            value: EsFileState.REGISTER_NEW,
          },
        ],
        tableName: intl.formatMessage({
          id: 'ES__COURT_FILES_REGISTER_NEW__TABLE__TITLE',
          defaultMessage: 'Soudní spisy - Rejstřík - nové spisy',
        }),
      },
      detailProps: {
        toolbarProps: {
          showBtn: (button) => {
            if (button === 'NEW') {
              return false;
            }

            return true;
          },
        },
      },
    },
  });

  return <Evidence {...evidenceBag} />;
}
