import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Tabs } from '@composite/tabs/tabs';
import { TabsConfig } from '@composite/tabs/tabs-types';
import { Content } from './fields/content/content';
import { Dispatches } from './fields/dispatches/dispatches';
import { General } from './fields/general/general';
import { History } from './fields/history/history';

export function Fields() {
  const config: TabsConfig = [
    {
      label: (
        <FormattedMessage
          id="ES__DOCUMENT_OUTGOING__DETAIL__PANEL_TITLE__GENERAL"
          defaultMessage="Obecné"
        />
      ),
      key: 'GENERAL',
      content: <General />,
      validationKeys: ['summary', 'date'],
    },
    {
      label: (
        <FormattedMessage
          id="ES__DOCUMENT_OUTGOING__DETAIL__PANEL_TITLE__CONTENT"
          defaultMessage="Obsah"
        />
      ),
      key: 'CONTENT',
      content: <Content />,
    },
    {
      label: (
        <FormattedMessage
          id="ES__DOCUMENT_OUTGOING__DETAIL__PANEL_TITLE__DISPATCHES"
          defaultMessage="Vypravení"
        />
      ),
      key: 'DISPATCHES',
      content: <Dispatches />,
    },
    {
      label: (
        <FormattedMessage
          id="ES__DOCUMENT_OUTGOING__DETAIL__PANEL_TITLE__HISTORY"
          defaultMessage="Historie"
        />
      ),
      key: 'HISTORY',
      content: <History />,
    },
  ];

  return <Tabs config={config} />;
}
