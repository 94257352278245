import React from 'react';
import { useIntl } from 'react-intl';
import { DictionaryEvidence, Evidence } from '@eas/common-web';
import { useDictionaryEvidence } from '@composite/evidence/dictionary-evidence';
import { useDictionaryHookedSources } from '@components/evidence/dictionary-evidence/dictionary-hooked-sources';
import { DictLegalForm } from '@models';
import {
  EntityName,
  EvidenceApiUrl,
  ExportTags,
  HistoryEntityType,
} from '@enums';
import { Fields } from './dict-legal-form-fields';
import { useValidationSchema } from './dict-legal-form-schema';

export function DictLegalForms() {
  const validationSchema = useValidationSchema();
  const intl = useIntl();
  const { columnName, columnActive, columnOrder } =
    DictionaryEvidence.useDictionaryColumns<DictLegalForm>();

  const evidence = useDictionaryEvidence<DictLegalForm>({
    version: 2,
    identifier: 'LEGAL_FORMS',
    apiProps: {
      url: EvidenceApiUrl.DICT_LEGAL_FORM,
      hookedSources: useDictionaryHookedSources({
        entityType: HistoryEntityType.LEGAL_FORM,
      }),
    },
    tableProps: {
      columns: [columnName, columnActive, columnOrder],
      tableName: intl.formatMessage({
        id: 'ES__DICT_LEGAL_FORMS__TABLE__TITLE',
        defaultMessage: 'Právní formy',
      }),
      reportTag: ExportTags.DICT_LEGAL_FORMS,
      defaultSorts: [
        { field: 'name', datakey: 'name', order: 'ASC', type: 'FIELD' },
      ],
      showBulkActionButton: false,
      showNamedSettingsButton: false,
    },
    detailProps: {
      entityName: EntityName.LEGAL_FORM,
      validationSchema,
      GeneralFieldsComponent: Fields,
      toolbarProps: {
        title: intl.formatMessage({
          id: 'ES__DICT_LEGAL_FORMS__DETAIL__TITLE',
          defaultMessage: 'Právní forma',
        }),
      },
    },
  });

  return <Evidence {...evidence} />;
}
