import {merge} from 'lodash';
import {useContext} from 'react';
import {useIntl} from 'react-intl';
import {DetailContext, DetailToolbarBtnName, EmptyComponent, EvidenceProps,} from '@eas/common-web';
import {useAuthoredEvidence} from '@composite/evidence/authored-evidence';
import {Decision} from '@models';
import {EntityName, EvidenceApiUrl, ExportTags, UIElement} from '@enums';
import {ActionBar} from './decision-actionbar';
import {useColumns} from './decision-columns';
import {Fields} from './decision-fields';
import {useDecisionsHookedSources} from './decision-hooked-sources';
import {useDecisionPermission} from './decision-permission';
import {Toolbar} from './decision-toolbar';

export function useDecisions({
  evidenceProps,
}: {
  evidenceProps?: Partial<EvidenceProps<Decision>>;
}) {
  const intl = useIntl();
  const columns = useColumns();

  const props = merge(
    {
      version: 14,
      identifier: 'DECISIONS',
      apiProps: {
        url: EvidenceApiUrl.DECISIONS,
        hookedSources: useDecisionsHookedSources,
      },
      tableProps: {
        columns: evidenceProps?.tableProps?.columns ?? columns,
        reportTag: ExportTags.DECISIONS,
        defaultSorts: [],
        enableSearchHighlighting: true,
        searchHighlight: [
          'text.legalSentence',
          'text.abstract',
          'text.introduction',
          'text.statement',
          'text.narration',
          'text.argumentation',
          'text.differentOpinion',
          'anonymizedText.legalSentence',
          'anonymizedText.abstract',
          'anonymizedText.introduction',
          'anonymizedText.statement',
          'anonymizedText.narration',
          'anonymizedText.argumentation',
          'anonymizedText.differentOpinion',
        ],
      },
      detailProps: {
        entityName: EntityName.DECISION,
        GeneralFieldsComponent: EmptyComponent,
        FieldsComponent: Fields,
        toolbarProps: {
          title: intl.formatMessage({
            id: 'ES__DECISIONS__DETAIL__TITLE',
            defaultMessage: 'Rozhodnutí',
          }),
          subtitle: (value: Decision) => value?.number ?? '',
          disableBtn: disableBtn(),
          showBtn: (btn: string) => !['NEW', 'REMOVE'].includes(btn),
          ActionBar,
          After: Toolbar,
        },
      },
    },
    evidenceProps
  );

  const evidenceBag = useAuthoredEvidence<Decision>(props);

  return { evidenceBag, props };
}

export function disableBtn() {
  return function useDisableBtn(button: DetailToolbarBtnName) {
    const { source } = useContext(DetailContext);

    const { hasPermission } = useDecisionPermission(source.data);

    if (
      button === 'EDIT' &&
      !hasPermission(UIElement.Decision.EDIT_DECISION_BUTTON)
    ) {
      return true;
    }

    return false;
  };
}
