import { format } from 'date-fns';
import { noop } from 'lodash';
import React, { useContext, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import {
  ApiFilterOperation,
  DialogHandle,
  DictionaryFieldDialog,
  FieldFilter,
  FormDateField,
  FormTextField,
  PrimaryDetailActionbarButton,
  TableContext,
  TableField,
  TableFieldCellProps,
  TableFieldCells,
  TableHandle,
  TableSelectedContext,
  TableToolbar,
  TableToolbarProps,
  useScrollableSource,
} from '@eas/common-web';
import { FilterContext } from '@composite/filter/filter-context';
import { Redirect } from '@composite/redirect/redirect';
import { Remove } from '@composite/remove/remove';
import { TableActionButton } from '@components/action-button/table-action-button';
import { DeliveredDocument, Document, EsFile } from '@models';
import {
  DocumentType,
  EsFileState,
  EvidenceApiUrl,
  EvidenceBrowserUrl,
} from '@enums';
import { createRepeatedScreening, createScreening } from './screening-api';

const useStyles = makeStyles((theme) => ({
  label: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '5px 10px',
  },
  labelText: {
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
  },
  link: {
    color: 'black',
    textDecoration: 'underline',
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  warning: {
    marginTop: 15,
    color: theme.palette.error.main,
  },
}));

export const toolbarFactory = (
  removeDeliveredDocument: () => void,
  selectDeliveredDocument: (doc: DeliveredDocument) => void,
  deliveredDocument?: DeliveredDocument
) => {
  return function Toolbar({ before, after }: TableToolbarProps) {
    const classes = useStyles();
    const intl = useIntl();

    const dialogRef = useRef<DialogHandle>(null);

    const { source } = useContext<TableHandle<EsFile>>(TableContext);
    const { selected } = useContext(TableSelectedContext);
    const {
      activeExtender,
      extenders,
      reducers,
      removeExtender,
      removeReducer,
    } = useContext(FilterContext);

    const deliveredDocuments = useScrollableSource<Document>({
      url: `${EvidenceApiUrl.DOCUMENTS}/list`,
      params: {
        filters: [
          {
            field: 'type.id',
            operation: ApiFilterOperation.EQ,
            value: DocumentType.DELIVERED,
          },
          {
            field: 'esFile.id',
            operation: ApiFilterOperation.IS_NULL,
          },
        ],
      },
    });

    const hasVisibleExtender = !!extenders.find((e) => e.visible);
    const hasReducer = reducers.length !== 0;

    const closedEsFiles = source.items
      .filter((item) => selected.includes(item.id))
      .filter((item) => item.state === EsFileState.REGISTRY);

    const openedEsFiles = source.items
      .filter((item) => selected.includes(item.id))
      .filter((item) => item.state !== EsFileState.REGISTRY)
      .map((item) => item.id);

    function RescreeningRedirectCell(props: TableFieldCellProps<EsFile>) {
      return (
        <>
          <Redirect
            url={EvidenceBrowserUrl.ES_FILES_ALL}
            id={props.value}
            showRedirectInCurrent={false}
          />
        </>
      );
    }

    const removeAll = async () => {
      reducers?.forEach(() => {
        removeReducer(0);
      });

      extenders?.forEach(() => {
        removeExtender(0);
      });
    };

    return (
      <>
        <TableToolbar before={before} after={after} />
        <div className={classes.label}>
          <Typography className={classes.labelText} component="span">
            {deliveredDocument?.id && (
              <>
                <Redirect
                  url={(id) =>
                    `${EvidenceBrowserUrl.DOCUMENTS_DELIVERED_UNPROCESSED}/${id}?view=CONTENT`
                  }
                  id={deliveredDocument.id}
                />
                <Remove
                  id={deliveredDocument.id}
                  removeDialogTitle={intl.formatMessage({
                    id: 'ES__SCREENING__REMOVE_DOCUMENTS__TITLE',
                    defaultMessage: 'Varování',
                  })}
                  removeDialogText={intl.formatMessage({
                    id: 'ES__SCREENING__REMOVE_DOCUMENTS__TEXT',
                    defaultMessage: 'Skutečně chcete odebrat dokument?',
                  })}
                  removeCallback={removeDeliveredDocument}
                />
                Dokument: {deliveredDocument.incomingNumber}{' '}
              </>
            )}
            {!deliveredDocument?.id && (
              <>
                <span
                  className={classes.link}
                  style={{ cursor: source.loading ? 'not-allowed' : 'pointer' }}
                  onClick={() => {
                    if (source.loading) {
                      return;
                    } else {
                      dialogRef.current?.open();
                    }
                  }}
                >
                  <FormattedMessage
                    id="ES__SCREENING__SELECT_DELIVERED_DOCUMENT"
                    defaultMessage="Vybrat doručený dokument"
                  />
                </span>
                <DictionaryFieldDialog<Document>
                  ref={dialogRef}
                  columns={[
                    {
                      name: 'Datum doručení',
                      datakey: 'deliveryDate',
                      width: 200,
                      CellComponent: TableFieldCells.DateCell,
                    },
                    {
                      name: 'Došlé číslo',
                      datakey: 'incomingNumber',
                      width: 200,
                    },
                    {
                      name: 'Stručný obsah',
                      datakey: 'summary',
                      width: 200,
                    },
                    {
                      name: 'Zpracovaný',
                      datakey: 'processed',
                      width: 100,
                      CellComponent: TableFieldCells.BooleanCell,
                    },
                  ]}
                  filtersFields={[
                    {
                      label: 'Datum doručení',
                      datakey: 'deliveryDate',
                      filterkey: 'deliveryDate',
                      defaultOperation: ApiFilterOperation.EQ,
                      FilterComponent: FieldFilter.FilterDateCell,
                    },
                    {
                      label: 'Došlé číslo',
                      datakey: 'incomingNumber',
                      filterkey: 'incomingNumber',
                      defaultOperation: ApiFilterOperation.CONTAINS,
                      FilterComponent: FieldFilter.FilterTextCell,
                    },
                    {
                      label: 'Stručný obsah',
                      datakey: 'summary',
                      filterkey: 'summary',
                      defaultOperation: ApiFilterOperation.CONTAINS,
                      FilterComponent: FieldFilter.FilterTextCell,
                    },
                    {
                      label: 'Zpracovaný',
                      datakey: 'processed',
                      filterkey: 'processed',
                      defaultOperation: ApiFilterOperation.EQ,
                      FilterComponent: FieldFilter.FilterBooleanCell,
                    },
                  ]}
                  preFilters={[
                    {
                      field: 'type.id',
                      operation: ApiFilterOperation.EQ,
                      value: DocumentType.DELIVERED,
                    },
                  ]}
                  onChange={(doc) =>
                    selectDeliveredDocument(doc as DeliveredDocument)
                  }
                  source={deliveredDocuments}
                />
              </>
            )}
          </Typography>
          <div>
            {deliveredDocument?.id && (
              <TableActionButton
                ButtonComponent={PrimaryDetailActionbarButton}
                promptKey="CREATE_SCREENING"
                apiCall={createScreening}
                buttonLabel={intl.formatMessage({
                  id: 'ES__SCREENING__TABLE_TOOLBAR__CREATE_SCREENING',
                  defaultMessage: 'Vytvořit Lustr',
                })}
                dialogTitle={intl.formatMessage({
                  id: 'ES__SCREENING__TABLE_TOOLBAR__CREATE_SCREENING__TITLE',
                  defaultMessage: 'Varování',
                })}
                dialogText={intl.formatMessage({
                  id: 'ES__SCREENING__TABLE_TOOLBAR__CREATE_SCREENING__TEXT',
                  defaultMessage:
                    'Vyplněním data a potvrzením okna bude vytvořen lustr.',
                })}
                dialogWidth={500}
                buttonDisabled={
                  (!hasVisibleExtender && !hasReducer) ||
                  activeExtender !== undefined ||
                  source.loading
                }
                formInitialValues={{
                  deliveredDocument,
                  date: format(new Date(), 'yyyy-MM-dd'),
                }}
                FormFields={() => (
                  <>
                    <FormTextField
                      name="deliveredDocument.incomingNumber"
                      label={
                        <FormattedMessage
                          id="ES__SCREENING__FIELD_LABEL__DELIVERED_DOCUMENT"
                          defaultMessage="Doručený dokument"
                        />
                      }
                      disabled={true}
                    />
                    <FormDateField
                      name="date"
                      representation="local-date"
                      label={
                        <FormattedMessage
                          id="ES__SCREENING__FIELD_LABEL__DATE"
                          defaultMessage="Datum"
                        />
                      }
                    />
                  </>
                )}
                onSuccess={removeAll}
              />
            )}
            <TableActionButton
              ButtonComponent={PrimaryDetailActionbarButton}
              promptKey="RESCREENING"
              apiCall={createRepeatedScreening(openedEsFiles)}
              buttonLabel={intl.formatMessage({
                id: 'ES__SCREENING__TABLE_TOOLBAR__RESCREENING',
                defaultMessage: 'Opakovaný Lustr',
              })}
              dialogTitle={intl.formatMessage({
                id: 'ES__SCREENING__TABLE_TOOLBAR__RESCREENING__TITLE',
                defaultMessage: 'Varování',
              })}
              dialogText={intl.formatMessage({
                id: 'ES__SCREENING__TABLE_TOOLBAR__RESCREENING__TEXT',
                defaultMessage:
                  'Vyplněním data a potvrzením okna bude vytvořen opakovaný lustr.',
              })}
              dialogWidth={500}
              buttonDisabled={
                (!hasVisibleExtender && !hasReducer) ||
                activeExtender !== undefined ||
                source.count === 0 ||
                source.loading
              }
              formInitialValues={{
                date: format(new Date(), 'yyyy-MM-dd'),
              }}
              FormFields={() => (
                <>
                  <FormDateField
                    name="date"
                    label={
                      <FormattedMessage
                        id="ES__SCREENING__FIELD_LABEL__DATE"
                        defaultMessage="Datum"
                      />
                    }
                  />
                  {!!closedEsFiles.length && (
                    <>
                      <Typography variant="body1" className={classes.warning}>
                        <FormattedMessage
                          id="ES__SCREENING__TABLE_TOOLBAR__RESCREENING__WARNING"
                          defaultMessage="UPOZORNĚNÍ: Následující spisy jsou uzavřeny a není možné k nim vykonat akci Opakovaného lustra."
                        />
                      </Typography>
                      <TableField
                        maxRows={10}
                        showToolbar={false}
                        value={closedEsFiles}
                        showRadioCond={() => false}
                        showDetailBtnCond={() => false}
                        visibleActionsColumn={false}
                        columns={[
                          {
                            datakey: 'id',
                            name: '',
                            width: 45,
                            CellComponent: RescreeningRedirectCell,
                          },
                          {
                            name: intl.formatMessage({
                              id: 'ES__ES_FILES__TABLE__COLUMN__FILE_NUMBER',
                              defaultMessage: 'Spisová značka',
                            }),
                            datakey: 'number.value',
                            width: 300,
                          },
                          {
                            name: intl.formatMessage({
                              id: 'ES__ES_FILES__TABLE__COLUMN__EVIDENCE_NUMBER',
                              defaultMessage: 'Evidenční číslo spisové služby',
                            }),
                            datakey: 'evidenceNumber',
                            width: 300,
                          },
                        ]}
                        onChange={noop}
                      />
                    </>
                  )}
                </>
              )}
              onSuccess={removeAll}
            />
          </div>
        </div>
      </>
    );
  };
};
