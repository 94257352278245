export enum ExportTags {
  // ASSIGNING_RULES = 'ASSIGNING_RULES',
  // ASSIGNED_FILE_NUMBERS = 'ASSIGNED_FILE_NUMBERS',
  ES_FILES = 'ES_FILES',
  // DOCUMENTS_DISPATCHES = 'DOCUMENTS_DISPATCHES',
  DECISIONS = 'DECISIONS',
  DOCUMENTS = 'DOCUMENT',
  DICT_ACT_TYPES = 'DICTIONARY_ACT_TYPE',
  DICT_BARCODE_PRINTERS = 'DICTIONARY_BARCODE_PRINTER',
  // DICT_BRIEF_SUMMARIES = 'DICTIONARY_BRIEF_SUMMARIES',
  DICT_CONCERNED_AUTHORITY_TYPES = 'DICTIONARY_CONCERNED_AUTHORITY_TYPE',
  DICT_COUNTRIES = 'DICTIONARY_COUNTRY',
  DICT_DELIVERY_TYPE = 'DICTIONARY_DELIVERY_TYPE',
  DICT_DISPATCH_TYPES = 'DICTIONARY_DISPATCH_TYPE',
  DICT_DOCUMENT_ORIGIN = 'DICTIONARY_DOCUMENT_ORIGIN',
  DICT_ENVELOPE_CONTENTS = 'DICTIONARY_ENVELOPE_CONTENT',
  DICT_ENVELOPE_SETTING = 'DICTIONARY_ENVELOPE_SETTING',
  DICT_HOLIDAYS = 'DICTIONARY_HOLIDAY',
  DICT_PHYSICAL_STORAGE = 'DICTIONARY_PHYSICAL_STORAGE',
  DICT_PROCEEDINGS_SUBJECTS = 'DICTIONARY_PROCEEDINGS_SUBJECT',
  // DICT_NOTES = 'DICTIONARY_NOTES',
  DICT_OPPONENTS = 'DICTIONARY_OPPONENT',
  DICT_LEGAL_FORMS = 'DICTIONARY_LEGAL_FORM',
  DICT_POST_OFFICES = 'DICTIONARY_POST_OFFICE',
  // DICT_PROPOSER_DISTINCTIONS = 'DICTIONARY_PROPOSER_DISTINCTION',
  // DICT_PROPOSER_TYPES = 'DICTIONARY_PROPOSER_TYPE',
  DICT_PROCESSING_TYPES = 'DICTIONARY_PROCESSING_TYPE',
  // DICT_REGULATION_ORDERS = 'DICT_REGULATION_ORDERS',
  DICT_SENATES = 'DICTIONARY_SENATE',
  DICT_SETTLEMENT_METHODS = 'DICTIONARY_SETTLEMENT_METHOD',
  DICT_SUBJECT_INDEX = 'DICTIONARY_SUBJECT_INDEX',
  DICT_SUBMISSION_CONTENT_SPR = 'DICTIONARY_SUBMISSION_CONTENT_SPR',
  // DICT_TASK_NAMES = 'DICTIONARY_TASK_NAME',
  DICT_ZIP_CODES = 'DICTIONARY_ZIP_CODE',
  // ES_FILES = 'ES_FILES',
  ESS_INCOMING_BATCHES_GRID = 'ESS_INCOMING_BATCHES_GRID',
  ESS_OUTGOING_BATCHES_GRID = 'ESS_OUTGOING_BATCHES_GRID',
  EXPORT_REQUESTS = 'EXPORT_REQUEST',
  EXPORT_TEMPLATES = 'EXPORT_TEMPLATES',
  DICT_JUDGES = 'DICTIONARY_JUDGE',
  // NOTIFICATIONS = 'NOTIFICATIONS',
  // NOTIFICATION_TEMPLATES = 'NOTIFICATION_TEMPLATES',
  // SUBJECTS = 'SUBJECTS',
  // SEQUENCES_GRID = 'SEQUENCES_GRID',
  // TASKS = 'TASKS',
  USER_ROLES = 'USER_ROLES',
  USERS = 'USERS',
  // USER_SUBSTITUTIONS = 'USER_SUBSTITUTIONS',
  // VIEWING_REQUESTS = 'VIEWING_REQUESTS',
}
