import { useIntl } from 'react-intl';
import {
  ApiFilterOperation,
  TableCells,
  TableColumn,
  TableFilterCells,
} from '@eas/common-web';
import { ParticipantCell } from '@modules/es-file/es-file-columns';
import {
  autocompleteLabelMapper,
  multipleAutocompleteColumnMapper,
  subjectContactColumnMapper,
} from '@components/form/misc/label-mappers';
import { SprFile } from '@models';
import { Agenda, Role } from '@enums';
import { useUserRoles } from '../role/role-api';
import { useContacts } from '../subject/subject-api';

export function useSprColumns() {
  const intl = useIntl();
  const useSolverUsers = () =>
    useUserRoles({
      filters: [
        {
          field: 'role.id',
          operation: ApiFilterOperation.EQ,
          value: Role.SOLVER,
        },
        {
          field: 'agendas.id',
          operation: ApiFilterOperation.EQ,
          value: Agenda.SPR,
        },
      ],
    });

  const columnDeliveryDate: TableColumn<SprFile> = {
    datakey: 'deliveryDate',
    name: intl.formatMessage({
      id: 'ES__SPR_FILES__TABLE__COLUMN__DELIVERY_DATE',
      defaultMessage: 'Datum doručení',
    }),
    width: 150,
    CellComponent: TableCells.DateCell,
    FilterComponent: TableFilterCells.FilterDateCell,
    sortable: true,
    filterable: true,
  };

  const columnIncomingNumber: TableColumn<SprFile> = {
    datakey: 'incomingNumber',
    name: intl.formatMessage({
      id: 'ES__SPR_FILES__TABLE__COLUMN__INCOMING_NUMBER',
      defaultMessage: 'Došlé číslo',
    }),
    width: 100,
    CellComponent: TableCells.TextCell,
    FilterComponent: TableFilterCells.FilterTextCell,
    sortable: true,
    filterable: true,
  };

  const columnJid: TableColumn<SprFile> = {
    datakey: 'jid',
    name: intl.formatMessage({
      id: 'ES__SPR_FILES__TABLE__COLUMN__JID',
      defaultMessage: 'JID',
    }),
    width: 200,
    CellComponent: TableCells.TextCell,
    sortable: true,
    filterable: true,
  };

  const columnSubmissionContent: TableColumn<SprFile> = {
    datakey: 'submissionContent',
    name: intl.formatMessage({
      id: 'ES__SPR_FILES__TABLE__COLUMN__SUBMISSION_CONTENT',
      defaultMessage: 'Obsah podání',
    }),
    width: 300,
    CellComponent: TableCells.TextCell,
    FilterComponent: TableFilterCells.FilterTextCell,
    sortable: true,
    filterable: true,
  };

  const columnWriters: TableColumn<SprFile> = {
    datakey: 'writers',
    filterkey: 'writers.contact.id',
    sortkey: 'writers.contact.name',
    name: intl.formatMessage({
      id: 'ES__SPR_FILES__TABLE__COLUMN__WRITERS',
      defaultMessage: 'Pisatelé',
    }),
    width: 200,
    CellComponent: ParticipantCell('writers'),
    valueMapper: subjectContactColumnMapper,
    FilterComponent: TableFilterCells.useFilterAutocompleteCellFactory(
      useContacts,
      autocompleteLabelMapper
    ),
    filterOperation: ApiFilterOperation.EQ,
    filterable: true,
    sortable: true,
  };

  const columnSolverUserRoles: TableColumn<SprFile> = {
    datakey: 'solverUserRoles',
    filterkey: 'solverUserRoles.id',
    sortkey: 'solverUserRoles.concatenated',
    name: intl.formatMessage({
      id: 'ES__SPR_FILES__TABLE__COLUMN__SOLVER_USER_ROLES',
      defaultMessage: 'Řešitelé',
    }),
    width: 200,
    CellComponent: TableCells.TextCell,
    valueMapper: multipleAutocompleteColumnMapper,
    FilterComponent: TableFilterCells.useFilterAutocompleteCellFactory(
      useSolverUsers,
      autocompleteLabelMapper
    ),
    filterOperation: ApiFilterOperation.EQ,
    filterable: true,
    sortable: true,
  };

  return {
    columnDeliveryDate,
    columnIncomingNumber,
    columnJid,
    columnSubmissionContent,
    columnWriters,
    columnSolverUserRoles,
  };
}
