enum Element {
  /**
   * Spisová značka
   */
  FILE_NUMBER = 'FILE_NUMBER',

  /**
   * Fyzické uložení
   */
  PHYSICAL_STORAGE = 'PHYSICAL_STORAGE',

  /**
   * Čárový kód
   */
  BAR_CODE = 'BAR_CODE',

  /**
   * Datum vytvoření
   */
  CREATED = 'CREATED',

  /**
   * Agenda
   */
  AGENDA = 'AGENDA',

  /**
   * Stav
   */
  STATE = 'STATE',

  /**
   * Typ řízení
   */
  PROCESSING_TYPE = 'PROCESSING_TYPE',

  /**
   * Zdrojové spisové značky
   */
  SOURCE_FILES = 'SOURCE_FILES',

  /**
   * Napadené spisové značky
   */
  CHALLENGED_FILES = 'CHALLENGED_FILES',

  /**
   * Způsob vyřízení
   */
  SETTLEMENT_METHODS = 'SETTLEMENT_METHODS',

  /**
   * Datum rozhodnutí
   */
  DECISION_DATE = 'DECISION_DATE',

  /**
   * Datum uzavření
   */
  CLOSING_DATE = 'CLOSING_DATE',

  /**
   * Poznámky
   */
  NOTES = 'NOTES',

  /**
   * Navrhující senát
   */
  SENATE = 'SENATE',

  /**
   * Soudce zpravodaj
   */
  JUDGE = 'JUDGE',

  /**
   * Vedoucí rejstříku
   */
  INDEX_USER_ROLE = 'INDEX_USER_ROLE',

  /**
   * Analytik - indexace
   */
  ANALYST_USER_ROLE = 'ANALYST_USER_ROLE',

  /**
   * Úlohy
   */
  TASKS = 'TASKS',

  /**
   * Koncepty
   */
  DRAFTS = 'DRAFTS',

  /**
   * AKCE: Nový spis
   */
  CREATE_PLENARY_OPINION_FILE_BUTTON = 'CREATE_PLENARY_OPINION_FILE_BUTTON',

  /**
   * AKCE: Upravit spis
   */
  UPDATE_PLENARY_OPINION_FILE_BUTTON = 'UPDATE_PLENARY_OPINION_FILE_BUTTON',

  /**
   * AKCE: Smazat spis
   */
  DELETE_PLENARY_OPINION_FILE_BUTTON = 'DELETE_PLENARY_OPINION_FILE_BUTTON',

  /**
   * AKCE: Obal spisu
   */
  CREATE_FILE_COVER_BUTTON = 'CREATE_FILE_COVER_BUTTON',

  /**
   * AKCE: Znovu otevřít
   */
  REOPEN_PLENARY_OPINION_FILE_BUTTON = 'REOPEN_PLENARY_OPINION_FILE_BUTTON',
}

export default Element;
