import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import {
  ApiFilterOperation,
  EmptyComponent,
  TableCells,
  TableColumn,
  TableColumnAlign,
  TableContext,
  Tooltip,
  useStaticListSource,
} from '@eas/common-web';
import { FilterAutocompleteCell } from '@composite/filter/filter-cells/filter-autocomplete-cell';
import {
  FilterDateCell,
  FilterDateWithoutOperationCell,
} from '@composite/filter/filter-cells/filter-date-cell';
import { FilterNumberCell } from '@composite/filter/filter-cells/filter-number-cell';
import { FilterSelectCell } from '@composite/filter/filter-cells/filter-select-cell';
import { FilterTextCell } from '@composite/filter/filter-cells/filter-text-cell';
import { Redirect } from '@composite/redirect/redirect';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';
import {
  autocompleteLabelMapper,
  multipleAutocompleteColumnMapper,
} from '@components/form/misc/label-mappers';
import { ChallengedAct, EsFile } from '@models';
import { EvidenceBrowserUrl } from '@enums';
import { useOpponents } from '../dict-opponent/dict-opponent-api';
import { useProceedingsSubjects } from '../dict-proceedings-subject/dict-proceedings-subject-api';
import { useSenates } from '../dict-senate/dict-senate-api';
import { useSettlementMethods } from '../dict-settlement-method/dict-settlement-method-api';

export function useColumns(): TableColumn<EsFile>[] {
  const intl = useIntl();

  const { agendas, documentTypes, participantTypes } =
    useContext(StaticDataContext);
  const useAgendas = () => useStaticListSource(agendas);
  const useTypes = () => useStaticListSource(documentTypes);
  const useParticipantTypes = () => useStaticListSource(participantTypes);

  return [
    {
      datakey: 'id',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__REDIRECT',
        defaultMessage: 'Přesměrování',
      }),
      NameComponent: EmptyComponent,
      width: 50,
      CellComponent: function Cell(props) {
        return (
          <>
            <Redirect
              url={EvidenceBrowserUrl.ES_FILES_ALL}
              id={props.value}
              showRedirectInCurrent={false}
            />
          </>
        );
      },
      align: TableColumnAlign.LEFT,
    },
    {
      datakey: 'number.value',
      sortkey: 'number.valueSort',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__FILE_NUMBER',
        defaultMessage: 'Spisová značka',
      }),
      width: 150,
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterTextCell,
      sortable: true,
      filterable: true,
      filterGroup: 2,
      filterOrder: 2,
    },
    {
      datakey: 'participants',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__PARTICIPANTS',
        defaultMessage: 'Účastník',
      }),
      filterPath: 'participants',
      width: 200,
      // TODO update on CellComponent this will break down exports
      CellComponent: function Cell({ rowValue }) {
        const { classes } = useContext(TableContext);
        let valueToDisplay = '';

        valueToDisplay = (rowValue?.participants ?? [])
          .map((participant) => participant.label)
          ?.join(';\n\n');

        return (
          <Tooltip title={valueToDisplay} placement="top-start" type="HOVER">
            <Typography className={classes.tableCell}>
              {valueToDisplay}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      datakey: 'participants.label',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__PARTICIPANT_NAME',
        defaultMessage: 'Účastník',
      }),
      filterPath: 'participants',
      width: 300,
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterTextCell,
      sortable: true,
      filterable: true,
      visible: false,
      filterGroup: 1,
      filterOrder: 1,
    },
    {
      datakey: 'participants.type.id',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__PARTICIPANT_TYPE',
        defaultMessage: 'Účastník - Typ',
      }),
      filterPath: 'participants',
      width: 0,
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterSelectCell(useParticipantTypes),
      filterOperation: ApiFilterOperation.EQ,
      filterable: true,
      visible: false,
      filterGroup: 1,
      filterOrder: 1,
    },
    {
      datakey: 'participants.contact.subject.firstName',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__PARTICIPANT_FIRST_NAME',
        defaultMessage: 'Účastník - Jméno',
      }),
      filterPath: 'participants',
      width: 300,
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterTextCell,
      sortable: true,
      filterable: true,
      visible: false,
      filterGroup: 1,
      filterOrder: 1,
    },
    {
      datakey: 'participants.contact.subject.lastName',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__PARTICIPANT_LAST_NAME',
        defaultMessage: 'Účastník - Příjmení',
      }),
      filterPath: 'participants',
      width: 300,
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterTextCell,
      sortable: true,
      filterable: true,
      visible: false,
      filterGroup: 1,
      filterOrder: 1,
    },
    {
      datakey: 'participants.contact.subject.companyName',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__PARTICIPANT_COMPANY_NAME',
        defaultMessage: 'Účastník - Firma',
      }),
      filterPath: 'participants',
      width: 300,
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterTextCell,
      sortable: true,
      filterable: true,
      visible: false,
      filterGroup: 1,
      filterOrder: 1,
    },
    {
      datakey: 'participants.contact.subject.function',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__PARTICIPANT_FUNCTION',
        defaultMessage: 'Účastník - Funkce',
      }),
      filterPath: 'participants',
      width: 300,
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterTextCell,
      sortable: true,
      filterable: true,
      visible: false,
      filterGroup: 1,
      filterOrder: 2,
    },
    {
      datakey: 'participants.contact.subject.otherIdentification',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__PARTICIPANT_OTHER_ID',
        defaultMessage: 'Účastník - Jiná identifikace',
      }),
      filterPath: 'participants',
      width: 300,
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterTextCell,
      sortable: true,
      filterable: true,
      visible: false,
      filterGroup: 1,
      filterOrder: 3,
    },
    {
      datakey: 'participants.contact.address.street',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__PARTICIPANT_STREET',
        defaultMessage: 'Účastník - Ulice',
      }),
      filterPath: 'participants',
      width: 300,
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterTextCell,
      sortable: true,
      filterable: true,
      visible: false,
      filterGroup: 1,
      filterOrder: 4,
    },
    {
      datakey: 'participants.contact.address.descriptiveNumber',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__PARTICIPANT_DESCRIPTIVE_NUMBER',
        defaultMessage: 'Účastník - Číslo popisné',
      }),
      filterPath: 'participants',
      width: 300,
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterTextCell,
      sortable: true,
      filterable: true,
      visible: false,
      filterGroup: 1,
      filterOrder: 5,
    },
    {
      datakey: 'participants.contact.address.orientationNumber',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__PARTICIPANT_ORIENTATION_NUMBER',
        defaultMessage: 'Účastník - Číslo orientační',
      }),
      filterPath: 'participants',
      width: 300,
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterTextCell,
      sortable: true,
      filterable: true,
      visible: false,
      filterGroup: 1,
      filterOrder: 6,
    },
    {
      datakey: 'participants.contact.address.city',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__PARTICIPANT_CITY',
        defaultMessage: 'Účastník - Město',
      }),
      filterPath: 'participants',
      width: 300,
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterTextCell,
      sortable: true,
      filterable: true,
      visible: false,
      filterGroup: 1,
      filterOrder: 7,
    },
    {
      datakey: 'participants.contact.address.zipCode.name',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__PARTICIPANT_ZIP',
        defaultMessage: 'Účastník - PSČ',
      }),
      filterPath: 'participants',
      width: 300,
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterTextCell,
      sortable: true,
      filterable: true,
      visible: false,
      filterGroup: 1,
      filterOrder: 8,
    },
    {
      datakey: 'participants.contact.subject.ico',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__PARTICIPANT_ICO',
        defaultMessage: 'Účastník - IČO',
      }),
      filterPath: 'participants',
      width: 300,
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterTextCell,
      sortable: true,
      filterable: true,
      visible: false,
      filterGroup: 1,
      filterOrder: 9,
    },
    {
      datakey: 'participants.contact.subject.birthDate',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__PROPOSER_BIRTH_DATE',
        defaultMessage: 'Účastník - Datum narození',
      }),
      filterPath: 'participants',
      width: 300,
      CellComponent: TableCells.DateCell,
      filterOperation: ApiFilterOperation.EQ,
      FilterComponent: FilterDateCell,
      sortable: true,
      filterable: true,
      visible: false,
      filterGroup: 1,
      filterOrder: 10,
    },
    {
      datakey: 'challengedActs',
      filterkey: 'challengedActs.concernedAuthority.id',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__CONCERNED_AUTHORITY',
        defaultMessage: 'Napadený akt - název dotčeného orgánu',
      }),
      width: 200,
      valueMapper: ({ value }) =>
        value
          ?.map((act: ChallengedAct) => act.concernedAuthority.name)
          .join(','),
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterAutocompleteCell(
        useOpponents,
        autocompleteLabelMapper
      ),
      sortable: false,
      filterable: true,
      filterGroup: 2,
      filterOrder: 11,
    },
    {
      datakey: 'challengedActsExternalFileNumber',
      displaykey: 'challengedActs',
      filterkey: 'challengedActs.externalFileNumber',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__EXTERNAL_FILE_NUMBER',
        defaultMessage: 'Napadený akt - spisová značka / číslo aktu',
      }),
      width: 200,
      valueMapper: ({ value }) =>
        value?.map((act: ChallengedAct) => act.externalFileNumber).join(','),
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterTextCell,
      sortable: false,
      filterable: true,
      filterGroup: 2,
      filterOrder: 12,
    },
    {
      datakey: 'settlementMethods',
      filterkey: 'settlementMethods.id',
      sortkey: 'settlementMethods.concatenated',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__SETTLEMENT_METHODS',
        defaultMessage: 'Způsob vyřízení',
      }),
      width: 300,
      valueMapper: multipleAutocompleteColumnMapper,
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterAutocompleteCell(
        useSettlementMethods,
        autocompleteLabelMapper
      ),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
      filterGroup: 2,
      filterOrder: 14,
    },
    {
      datakey: 'proceedingsSubjects',
      filterkey: 'proceedingsSubjects.id',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__PROCEEDINGS_SUBJECT',
        defaultMessage: 'Předmět řízení',
      }),
      width: 300,
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterAutocompleteCell(
        useProceedingsSubjects,
        autocompleteLabelMapper
      ),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
      visible: false,
      filterGroup: 2,
      filterOrder: 13,
    },
    {
      datakey: 'agenda',
      filterkey: 'agenda.id',
      sortkey: 'agenda.name',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__AGENDA',
        defaultMessage: 'Agenda',
      }),
      width: 300,
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterSelectCell(useAgendas),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
      visible: false,
      filterGroup: 2,
      filterOrder: 1,
    },
    {
      datakey: 'senate.name',
      filterkey: 'senate.id',
      sortkey: 'senate.name',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__SENATE',
        defaultMessage: 'Číslo senátu',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterAutocompleteCell(
        useSenates,
        autocompleteLabelMapper
      ),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
      filterGroup: 2,
      filterOrder: 3,
    },
    {
      datakey: 'number.order',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__ORDINAL_NUMBER',
        defaultMessage: 'Pořadové číslo',
      }),
      width: 150,
      CellComponent: TableCells.NumberCell,
      FilterComponent: FilterNumberCell,
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
      filterGroup: 2,
      filterOrder: 4,
    },
    {
      datakey: 'number.year',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__YEAR',
        defaultMessage: 'Rok',
      }),
      width: 100,
      CellComponent: TableCells.NumberCell,
      FilterComponent: FilterNumberCell,
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
      filterGroup: 2,
      filterOrder: 5,
    },
    {
      datakey: 'deliveryDate',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__PROPOSAL_DATE',
        defaultMessage: 'Datum doručení',
      }),
      width: 150,
      CellComponent: TableCells.DateCell,
      sortable: true,
    },
    {
      datakey: 'deliveryDateFrom',
      filterkey: 'deliveryDate',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__PROPOSAL_DATE_FROM',
        defaultMessage: 'Datum doručení od',
      }),
      width: 300,
      CellComponent: TableCells.DateCell,
      FilterComponent: FilterDateWithoutOperationCell,
      filterOperation: ApiFilterOperation.GTE,
      sortable: true,
      filterable: true,
      visible: false,
      filterGroup: 2,
      filterOrder: 6,
    },
    {
      datakey: 'deliveryDateTo',
      filterkey: 'deliveryDate',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__PROPOSAL_DATE_TO',
        defaultMessage: 'Datum doručení do',
      }),
      width: 300,
      CellComponent: TableCells.DateCell,
      FilterComponent: FilterDateWithoutOperationCell,
      filterOperation: ApiFilterOperation.LTE,
      sortable: true,
      filterable: true,
      visible: false,
      filterGroup: 2,
      filterOrder: 7,
    },
    {
      datakey: 'discussionDate',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__SUBMISSION_DATE',
        defaultMessage: 'Datum projednání',
      }),
      width: 150,
      CellComponent: TableCells.DateCell,
      sortable: true,
      filterGroup: 2,
      filterOrder: 8,
    },
    {
      datakey: 'discussionDateFrom',
      filterkey: 'discussionDate',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__SUBMISSION_DATE_FROM',
        defaultMessage: 'Datum projednání - vyřízení od',
      }),
      width: 300,
      CellComponent: TableCells.DateCell,
      FilterComponent: FilterDateWithoutOperationCell,
      filterOperation: ApiFilterOperation.GTE,
      sortable: true,
      filterable: true,
      visible: false,
      filterGroup: 2,
      filterOrder: 9,
    },
    {
      datakey: 'discussionDateTo',
      filterkey: 'discussionDate',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__SUBMISSION_DATE_TO',
        defaultMessage: 'Datum projednání - vyřízení do',
      }),
      width: 300,
      CellComponent: TableCells.DateCell,
      FilterComponent: FilterDateWithoutOperationCell,
      filterOperation: ApiFilterOperation.LTE,
      sortable: true,
      filterable: true,
      visible: false,
      filterGroup: 2,
      filterOrder: 10,
    },
    {
      datakey: 'submissionContent',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__SUBMISSION_CONTENT_SPR',
        defaultMessage: 'Obsah podání SPR',
      }),
      width: 300,
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterTextCell,
      sortable: true,
      filterable: true,
      visible: false,
      filterGroup: 3,
      filterOrder: 1,
    },
    {
      datakey: 'documents.summary',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__DELIVERED_DOCUMENT_SUMMARY',
        defaultMessage: 'Dokument - Stručný obsah',
      }),
      width: 300,
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterTextCell,
      sortable: true,
      filterable: true,
      visible: false,
      filterGroup: 3,
      filterOrder: 2,
    },
    {
      datakey: 'documents.deliveryDate',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__DELIVERED_DOCUMENT_SUBMISSION_DATE',
        defaultMessage: 'Dokument - Datum',
      }),
      width: 300,
      CellComponent: TableCells.DateCell,
      FilterComponent: FilterDateCell,
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
      visible: false,
      filterGroup: 3,
      filterOrder: 3,
    },
    {
      datakey: 'documents.type',
      filterkey: 'documents.type.id',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__DELIVERED_DOCUMENT_TYPE',
        defaultMessage: 'Dokument - Typ dokumentu',
      }),
      width: 300,
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterSelectCell(useTypes),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
      visible: false,
      filterGroup: 3,
      filterOrder: 4,
    },
  ];
}
