import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';
import { ApiFilterOperation, Evidence } from '@eas/common-web';
import { useDefaultIndexRole } from '@modules/role/role-api';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';
import { Agenda, EsFileState } from '@enums';
import { disableBtnFactory } from '../es-file/es-file-utils';
import { useEsFiles } from '../es-file/es-files';
import { useColumns } from './plenary-opinion-file-columns';
import { Toolbar } from './plenary-opinion-file-toolbar';

export function PlenaryOpinionFiles() {
  const intl = useIntl();
  const columns = useColumns();

  const { defaultPlenaryOpinionProcessingType } = useContext(StaticDataContext);
  const [defaultPlenaryOpinionIndexRole] = useDefaultIndexRole(
    Agenda.PLENARY_OPINION
  );

  const defaultPreFilters = [
    {
      field: 'agenda.id',
      operation: ApiFilterOperation.EQ,
      value: Agenda.PLENARY_OPINION,
    },
  ];

  const defaultPlenaryOpinionFile = {
    id: uuidv4(),
    agenda: Agenda.PLENARY_OPINION,
    state: EsFileState.PROPOSED,
    deliveryDate: new Date().toISOString(),
    indexUserRole: defaultPlenaryOpinionIndexRole!,
    processingType: defaultPlenaryOpinionProcessingType!,
  };

  const { evidenceBag } = useEsFiles({
    evidenceProps: {
      version: 9,
      tableProps: {
        columns,
        defaultPreFilters,
        tableName: intl.formatMessage({
          id: 'ES__PLENARY_OPINION_FILES__TABLE__TITLE',
          defaultMessage: 'Spisy stanoviska pléna',
        }),
        toolbarProps: {
          after: <Toolbar />,
        },
      },
      detailProps: {
        toolbarProps: {
          title: intl.formatMessage({
            id: 'ES__PLENARY_OPINION_FILES__DETAIL__TITLE',
            defaultMessage: 'Spis stanoviska pléna',
          }),
          disableBtn: disableBtnFactory(defaultPlenaryOpinionFile),
        },
        initNewItem: () => defaultPlenaryOpinionFile,
      },
    },
  });

  return <Evidence {...evidenceBag} />;
}
