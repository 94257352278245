import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import SyncIcon from '@material-ui/icons/Sync';
import {
  DetailActionButton,
  DetailContext,
  DetailHandle,
  DetailMode,
  TableFieldToolbarButton,
} from '@eas/common-web';
import {
  BusinessNaturalPerson,
  Contact,
  NaturalPerson,
  Subject,
} from '@models';
import { SubjectType } from '@enums';
import { IdentifyDialog } from './identify-dialog';

export function IdentifyButton({
  disabled,
  selectedContact,
}: {
  disabled?: boolean;
  selectedContact?: Contact;
}) {
  const intl = useIntl();
  const { source, refreshAll } =
    useContext<DetailHandle<Subject>>(DetailContext);

  return (
    <>
      <DetailActionButton
        promptKey="IDENTIFY_CONTACT"
        submitCallback={async ({ selectedSubject }) => {
          const {
            subjectType,
            deliveryAddress,
            address,
            email,
            phoneNumber,
            ...subject
          } = selectedSubject;

          const contacts = (source?.data?.contacts ?? []).filter(
            (c: Contact) => c.id !== selectedContact?.id
          );

          await source.update(
            {
              ...source.data,
              type: subjectType,
              ...subject,
              contacts: [
                ...contacts,
                { ...selectedContact, address, email, phone: phoneNumber },
              ],
            },
            source.data!
          );

          refreshAll();
        }}
        ButtonComponent={({ onClick, label }) => (
          <TableFieldToolbarButton
            IconComponent={SyncIcon}
            show={true}
            disabled={disabled || false}
            title={label}
            onClick={onClick}
            color="default"
          >
            <Typography variant="body2">
              {intl.formatMessage({
                id: 'ES__CONTACTS_TOOLBAR__IDENTIFY_CONTACT__BUTTON',
                defaultMessage: 'Ztotožnit',
              })}
            </Typography>
          </TableFieldToolbarButton>
        )}
        buttonLabel={intl.formatMessage({
          id: 'ES__CONTACTS__IDENTIFY_CONTACT__BUTTON',
          defaultMessage: 'Ztotožnit subjekt',
        })}
        dialogTitle={intl.formatMessage({
          id: 'ES__CONTACTS__IDENTIFY_CONTACT__TITLE',
          defaultMessage: 'Ztotožnění subjektu',
        })}
        dialogText={intl.formatMessage(
          {
            id: 'ES__CONTACTS__IDENTIFY_CONTACT__TEXT',
            defaultMessage:
              'Ztotožnění proběhlo na základě těchto údajů:{br}{values}Vyberte subjekt, jehož údaje chcete převzít.',
          },
          {
            br: <br />,
            values:
              source?.data?.type === SubjectType.NATURAL_PERSON ? (
                <div style={{ marginLeft: 15, marginBottom: 10 }}>
                  - 1. Číslo DS: <b>{selectedContact?.dataBox?.identifier}</b>
                  <br />- 2. Jméno, příjmení, datum narození:{' '}
                  <b>
                    {[
                      (source?.data as NaturalPerson).firstName,
                      (source?.data as NaturalPerson).lastName,
                      (source?.data as NaturalPerson).birthDate
                        ? new Date(
                            (source?.data as NaturalPerson).birthDate!
                          ).toLocaleDateString()
                        : null,
                    ]
                      .filter((v) => v)
                      .join(', ')}
                  </b>
                </div>
              ) : source?.data?.type === SubjectType.BUSINESS_NATURAL_PERSON ||
                source?.data?.type === SubjectType.LEGAL_ENTITY ? (
                <div style={{ marginLeft: 15, marginBottom: 10 }}>
                  - 1. IČO: <b>{(source?.data as BusinessNaturalPerson).ico}</b>
                  <br />- 2. Číslo DS:{' '}
                  <b>{selectedContact?.dataBox?.identifier}</b>
                  <br />- 3. Název
                </div>
              ) : (
                <div style={{ marginLeft: 15, marginBottom: 10 }}>
                  - 1. IČO: <b>{(source?.data as BusinessNaturalPerson).ico}</b>
                  <br />- 2. Číslo DS:{' '}
                  <b>{selectedContact?.dataBox?.identifier}</b>
                  <br />- 3. Jméno, příjmení, datum narození:{' '}
                  <b>
                    {[
                      (source?.data as NaturalPerson).firstName,
                      (source?.data as NaturalPerson).lastName,
                      (source?.data as NaturalPerson).birthDate
                        ? new Date(
                            (source?.data as NaturalPerson).birthDate!
                          ).toLocaleDateString()
                        : null,
                    ]
                      .filter((v) => v)
                      .join(', ')}
                  </b>
                </div>
              ),
          }
        )}
        dialogWidth={1100}
        modes={[DetailMode.NEW, DetailMode.EDIT, DetailMode.VIEW]}
        FormFields={() => <IdentifyDialog id={selectedContact!.id} />}
      />
    </>
  );
}
