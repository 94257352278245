import React, { useContext, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import AutoSizer from 'react-virtualized-auto-sizer';
import { grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import {
  ApiFilterOperation,
  EvidenceContext,
  SplitScreen,
  Table,
  TableContext,
  TableHandle,
  useScrollableSource,
} from '@eas/common-web';
import { Filter } from '@composite/filter/filter';
import { FilterOutButton } from '@composite/filter/filter-out-button';
import { FilterProvider } from '@composite/filter/filter-provider';
import { FilterState } from '@composite/filter/filter-types';
import { CourtFile, EsFile } from '@models';
import { EsFileState, EvidenceApiUrl } from '@enums';
import { useDefaults } from './hooks/defaults-hook';
import { useScreeningForDocument } from './hooks/document-hook';
import { useColumns } from './screening-columns';
import { toolbarFactory } from './screening-table-toolbar';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  wrapper: {
    display: 'flex',
    flexGrow: 1,
    flexShrink: 1,
    height: 'calc(100vh - 67px)',
  },
  wrapperLeft: {
    height: '100%',
    backgroundColor: 'white',
    overflowY: 'auto',
    minWidth: 400,
  },
  wrapperRight: {
    height: '100%',
    minWidth: 400,
    overflowY: 'auto',
  },
  toolbarWrapper: {
    backgroundColor: 'red',
  },
  searchBarWrapper: {
    backgroundColor: 'red',
  },
});

export function Screening() {
  const tableRef = useRef<TableHandle<EsFile>>(null);
  const tableSource = useScrollableSource<CourtFile>({
    url: EvidenceApiUrl.ES_FILES + '/list',
  });

  const { defaultSubmissionDate } = useDefaults();

  const defaults = [
    {
      id: 'deliveryDateFrom',
      field: 'deliveryDate',
      operation: ApiFilterOperation.GTE,
      value: defaultSubmissionDate,
      visible: true,
    },
    {
      id: 'participants.type.id',
      field: 'participants.type.id',
      operation: ApiFilterOperation.EQ,
      value: 'PROPOSER',
      visible: true,
      object: { id: 'PROPOSER', name: 'Navrhovatel' },
    },
  ];

  return (
    <EvidenceContext.Provider
      value={
        {
          tableRef,
          tableSource,
        } as EvidenceContext<EsFile>
      }
    >
      {defaultSubmissionDate && (
        <FilterProvider settingsKey="SCREENING" defaults={defaults}>
          <TableContext.Provider
            value={tableRef.current as TableHandle<EsFile>}
          >
            <Filters
              defaults={defaults}
              defaultSubmissionDate={defaultSubmissionDate}
            />
          </TableContext.Provider>
        </FilterProvider>
      )}
    </EvidenceContext.Provider>
  );
}

const Filters = ({
  defaultSubmissionDate,
  defaults,
}: {
  defaultSubmissionDate?: string;
  defaults?: FilterState[];
}) => {
  const { tableRef, tableSource } = useContext(EvidenceContext);

  const {
    deliveredDocument,
    removeDeliveredDocument,
    selectDeliveredDocument,
  } = useScreeningForDocument();

  const classes = useStyles();
  const columns = useColumns();
  const intl = useIntl();

  const defaultTitle = useRef(document.title);

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <AutoSizer>
          {({ height, width }) => (
            <div style={{ height, width, display: 'flex' }}>
              <SplitScreen
                rightMinWidth={955}
                defaultTitle={defaultTitle.current}
                rightTitle={intl.formatMessage({
                  id: 'ES__SCREENING__RIGHT_TITLE',
                  defaultMessage: 'Lustrum',
                })}
                leftTitle={intl.formatMessage({
                  id: 'ES__SCREENING__LEFT_TITLE',
                  defaultMessage: 'Lustrum',
                })}
              >
                <div className={classes.wrapperLeft}>
                  <Table
                    version={15}
                    ref={tableRef}
                    tableId="SCREENING_TABLE"
                    columns={columns}
                    source={tableSource}
                    height={height}
                    tableName="Lustrace"
                    showColumnButton={false}
                    showFilterButton={false}
                    showReportButton={false}
                    showBulkActionButton={false}
                    showSearchbar={false}
                    disabledActiveRow={true}
                    defaultSorts={[]}
                    onRowClick={(id) => {
                      tableRef.current?.toggleRowSelection(id!);
                    }}
                    createRowStyle={(item: EsFile) =>
                      item.state === EsFileState.REGISTRY
                        ? {
                            color: grey[500],
                            backgroundColor: grey[100],
                          }
                        : {}
                    }
                    ToolbarComponent={toolbarFactory(
                      removeDeliveredDocument,
                      selectDeliveredDocument,
                      deliveredDocument
                    )}
                    toolbarProps={{
                      after: <FilterOutButton />,
                    }}
                  />
                </div>
                <div className={classes.wrapperRight}>
                  {!!defaultSubmissionDate && (
                    <Filter
                      title={
                        <FormattedMessage
                          id="ES__FILTER__TOOLBAR__TITLE"
                          defaultMessage="Lustrum"
                        />
                      }
                      initVisibility={[
                        'participants.label',
                        'participants.type.id',
                        'participants.name',
                        'participants.contact.subject.firstName',
                        'participants.contact.subject.lastName',
                        'participants.contact.subject.companyName',
                        'participants.contact.subject.ico',
                        'agenda',
                        'number.value',
                        'senate.name',
                        'challengedActs',
                        'challengedActsExternalFileNumber',
                        'submissionContent',
                        'documents.summary',
                      ]}
                      defaults={defaults ?? []}
                      groupLabels={{
                        1: intl.formatMessage({
                          id: 'ES__SCREENING__LABEL_GROUP_1',
                          defaultMessage: 'Atributy navrhovatele / pisatele',
                        }),
                        2: intl.formatMessage({
                          id: 'ES__SCREENING__LABEL_GROUP_2',
                          defaultMessage: 'Atributy spisu',
                        }),
                        3: intl.formatMessage({
                          id: 'ES__SCREENING__LABEL_GROUP_2',
                          defaultMessage: 'Ostatní',
                        }),
                      }}
                    />
                  )}
                </div>
              </SplitScreen>
            </div>
          )}
        </AutoSizer>
      </div>
    </div>
  );
};
