import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import {
  ApiFilterOperation,
  EmptyComponent,
  FieldFilter,
  TableCells,
  TableColumn,
  TableColumnAlign,
  TableFieldCells,
  TableFieldColumn,
  TableFilterCells,
  useStaticListSource,
} from '@eas/common-web';
import { Redirect } from '@composite/redirect/redirect';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';
import {
  autocompleteColumnMapper,
  autocompleteLabelMapper,
  multipleAutocompleteColumnMapper,
} from '@components/form/misc/label-mappers';
import {
  CourtFile,
  DictSubjectIndex,
  EsFileAutocomplete,
  PlenaryOpinionFile,
} from '@models';
import { Agenda, EsFileState, EvidenceBrowserUrl } from '@enums';
import { useOpponents } from '../dict-opponent/dict-opponent-api';
import { useSenates } from '../dict-senate/dict-senate-api';
import { useSubjectIndexes } from '../dict-subject-index/dict-subject-index-api';
import { useEsFiles } from '../es-file/es-file-api';
import { useJudges } from '../judge/judge-api';

export function useColumns(): TableColumn<PlenaryOpinionFile>[] {
  const intl = useIntl();
  const { states } = useContext(StaticDataContext);

  const useStates = () =>
    useStaticListSource(
      states.filter((state) =>
        state?.forAgendas?.includes(Agenda.PLENARY_OPINION)
      )
    );

  const useCourtFiles = () =>
    useEsFiles({
      filters: [
        {
          field: 'agenda.id',
          operation: ApiFilterOperation.EQ,
          value: Agenda.COURT,
        },
        {
          field: 'number',
          operation: ApiFilterOperation.NOT_NULL,
        },
      ],
    });

  return [
    {
      datakey: 'id',
      name: intl.formatMessage({
        id: 'ES__PLENARY_OPINION_FILES__TABLE__COLUMN__REDIRECT',
        defaultMessage: 'Přesměrování',
      }),
      NameComponent: EmptyComponent,
      width: 50,
      CellComponent: function Cell(props) {
        return (
          <>
            <Redirect
              url={EvidenceBrowserUrl.PLENARY_OPINION_FILES}
              id={props.value}
              showRedirectInCurrent={false}
            />
          </>
        );
      },
      align: TableColumnAlign.LEFT,
    },
    {
      datakey: 'number.value',
      sortkey: 'number.valueSort',
      name: intl.formatMessage({
        id: 'ES__PLENARY_OPINION_FILES__TABLE__COLUMN__FILE_NUMBER',
        defaultMessage: 'Spisová značka',
      }),
      width: 300,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'jid',
      name: intl.formatMessage({
        id: 'ES__PLENARY_OPINION_FILES__TABLE__COLUMN__JID',
        defaultMessage: 'JID',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'senate',
      filterkey: 'senate.id',
      sortkey: 'senate.name',
      name: intl.formatMessage({
        id: 'ES__PLENARY_OPINION_FILES__TABLE__COLUMN__SENATE',
        defaultMessage: 'Navrhující senát',
      }),
      width: 300,
      CellComponent: TableCells.TextCell,
      valueMapper: autocompleteColumnMapper,
      FilterComponent: TableFilterCells.useFilterAutocompleteCellFactory(
        useSenates,
        autocompleteLabelMapper
      ),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'created',
      name: intl.formatMessage({
        id: 'ES__PLENARY_OPINION_FILES__TABLE__COLUMN__CREATED',
        defaultMessage: 'Datum vytvoření',
      }),
      width: 300,
      CellComponent: TableCells.DateTimeCell,
      FilterComponent: TableFilterCells.FilterDateTimeCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'sourceFiles',
      filterkey: 'sourceFiles.id',
      sortkey: 'sourceFiles.concatenated',
      name: intl.formatMessage({
        id: 'ES__PLENARY_OPINION_FILES__TABLE__COLUMN__SOURCE_FILES',
        defaultMessage: 'Zdrojové spisové značky',
      }),
      width: 300,
      valueMapper: multipleAutocompleteColumnMapper,
      CellComponent: TableCells.TextCell,
      FilterComponent:
        TableFilterCells.useFilterAutocompleteCellFactory<EsFileAutocomplete>(
          useCourtFiles,
          autocompleteLabelMapper
        ),
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'challengedFiles',
      filterkey: 'challengedFiles.id',
      sortkey: 'challengedFiles.concatenated',
      name: intl.formatMessage({
        id: 'ES__PLENARY_OPINION_FILES__TABLE__COLUMN__CHALLENGED_FILES',
        defaultMessage: 'Napadené spisové značky',
      }),
      width: 300,
      valueMapper: multipleAutocompleteColumnMapper,
      CellComponent: TableCells.TextCell,
      FilterComponent:
        TableFilterCells.useFilterAutocompleteCellFactory<EsFileAutocomplete>(
          useCourtFiles,
          autocompleteLabelMapper
        ),
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'subjectIndexes',
      filterkey: 'subjectIndexes.id',
      sortkey: 'subjectIndexes.concatenated',
      name: intl.formatMessage({
        id: 'ES__PLENARY_OPINION_FILES__TABLE__COLUMN__SUBJECT_INDEXES',
        defaultMessage: 'Věcný rejstřík',
      }),
      width: 300,
      valueMapper: multipleAutocompleteColumnMapper,
      CellComponent: TableCells.TextCell,
      FilterComponent:
        TableFilterCells.useFilterAutocompleteCellFactory<DictSubjectIndex>(
          useSubjectIndexes,
          autocompleteLabelMapper
        ),
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'state',
      filterkey: 'state.id',
      sortkey: 'state.name',
      name: intl.formatMessage({
        id: 'ES__PLENARY_OPINION_FILES__TABLE__COLUMN__STATE',
        defaultMessage: 'Stav',
      }),
      width: 300,
      CellComponent: TableCells.TextCell,
      valueMapper: TableCells.useSelectCellFactory(useStates),
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(useStates),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'closingDate',
      name: intl.formatMessage({
        id: 'ES__PLENARY_OPINION_FILES__TABLE__COLUMN__CLOSING_DATE',
        defaultMessage: 'Datum uzavření',
      }),
      width: 300,
      CellComponent: TableCells.DateTimeCell,
      FilterComponent: TableFilterCells.FilterDateTimeCell,
      sortable: true,
      filterable: true,
    },
  ];
}

export function useCourtFileColumns(): TableFieldColumn<CourtFile>[] {
  const { states, courtFileTypes } = useContext(StaticDataContext);

  const useCourtFileTypes = () => useStaticListSource(courtFileTypes);
  const useStates = () => useStaticListSource(states);

  return [
    {
      name: 'Spisová značka',
      datakey: 'number.value',
      sortkey: 'number.valueSort',
      width: 300,
    },
    {
      name: 'Datum doručení',
      datakey: 'deliveryDate',
      sortkey: 'deliveryDate',
      CellComponent: TableFieldCells.DateCell,
      width: 300,
    },
    {
      name: 'Soudce zpravodaj',
      datakey: 'judge',
      sortkey: 'judge.name',
      CellComponent: TableFieldCells.useAutocompleteCellFactory({
        dataHook: useJudges,
        multiple: false,
      }),
      width: 300,
    },
    {
      name: 'Odpůrce',
      datakey: 'opponent',
      sortkey: 'opponent.name',
      CellComponent: TableFieldCells.useAutocompleteCellFactory({
        dataHook: useOpponents,
        multiple: false,
      }),
      width: 300,
    },
    {
      name: 'Typ soudního spisu',
      datakey: 'type',
      sortkey: 'type.name',
      CellComponent: TableFieldCells.useSelectCellFactory(useCourtFileTypes),
      width: 300,
    },
    {
      name: 'Stav',
      datakey: 'state',
      sortkey: 'state.name',
      CellComponent: TableFieldCells.useSelectCellFactory(useStates),
      width: 300,
    },
  ];
}

export function useCourtFileFilterColumns() {
  const { states, courtFileTypes } = useContext(StaticDataContext);
  const useStates = () =>
    useStaticListSource(
      states.filter(
        (state) =>
          state.forAgendas?.includes(Agenda.COURT) &&
          state.id !== EsFileState.REGISTRY
      )
    );
  const useCourtFileTypes = () => useStaticListSource(courtFileTypes);

  return [
    {
      label: 'Spisová značka',
      datakey: 'number.value',
      filterkey: 'number.value',
      defaultOperation: ApiFilterOperation.CONTAINS,
      FilterComponent: FieldFilter.FilterTextCell,
    },
    {
      label: 'Datum doručení',
      datakey: 'deliveryDate',
      filterkey: 'deliveryDate',
      defaultOperation: ApiFilterOperation.LTE,
      FilterComponent: FieldFilter.FilterDateCell,
    },
    {
      label: 'Soudce zpravodaj',
      datakey: 'judge',
      filterkey: 'judge.id',
      defaultOperation: ApiFilterOperation.EQ,
      FilterComponent: FieldFilter.FilterAutocompleteCell(
        useJudges,
        autocompleteLabelMapper
      ),
    },
    {
      label: 'Odpůrce',
      datakey: 'opponent',
      filterkey: 'opponent.id',
      defaultOperation: ApiFilterOperation.EQ,
      FilterComponent: FieldFilter.FilterAutocompleteCell(
        useOpponents,
        autocompleteLabelMapper
      ),
    },
    {
      label: 'Typ soudního spisu',
      datakey: 'type',
      filterkey: 'type.id',
      defaultOperation: ApiFilterOperation.EQ,
      FilterComponent: FieldFilter.FilterSelectCell(useCourtFileTypes),
    },
    {
      label: 'Stav',
      datakey: 'state',
      filterkey: 'state.id',
      defaultOperation: ApiFilterOperation.EQ,
      FilterComponent: FieldFilter.FilterSelectCell(useStates),
    },
  ];
}
