import {noop} from 'lodash';
import React, {useContext} from 'react';
import {FormattedMessage} from 'react-intl';
import {
  FileField,
  FormCustomField,
  FormDateTimeField,
  FormFileField,
  FormPanel,
  FormSelect,
  FormTextField,
  useFormSelector,
  useStaticListSource,
} from '@eas/common-web';
import {Redirect} from '@composite/redirect/redirect';
import {StaticDataContext} from '@components/evidence/static-data-provider/static-data-provider';
import {Dispatch} from '@models';
import {DispatchDeliveryMethod, EvidenceBrowserUrl} from '@enums';
import {DataBoxFields} from './fields/data-box-fields';
import {LetterFields} from './fields/letter-fields';
import {NoticeBoardFields} from './fields/notice-board-fields';

export function Fields() {
  const { dispatchMethods, dispatchDeliveryMethods, documentDispatchStates } =
    useContext(StaticDataContext);
  const methodsSource = useStaticListSource(dispatchMethods);
  const deliveryMethodsSource = useStaticListSource(dispatchDeliveryMethods);
  const statesSource = useStaticListSource(documentDispatchStates);

  const { esFile, deliveryMethod, document, envelope } = useFormSelector(
    (data: Dispatch) => ({
      esFile: data.document?.esFile,
      deliveryMethod: data.dispatchDeliveryMethod,
      document: data.document,
      envelope: data.envelope,
    })
  );

  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ES__DOCUMENT_DISPATCHES__DETAIL__PANEL_TITLE__GENERAL"
            defaultMessage="Obecné"
          />
        }
        expandable={false}
      >
        <FormTextField
          name="document.esFile.number.value"
          label={
            <FormattedMessage
              id="ES__DOCUMENT_DISPATCHES__DETAIL__FIELD_LABEL__ES_FILE_FILE_NUMBER"
              defaultMessage="Spisová značka"
            />
          }
          after={
            esFile?.id && (
              <Redirect url={EvidenceBrowserUrl.ES_FILES_ALL} id={esFile.id} />
            )
          }
          disabled={true}
        />
        <FormTextField
          name="jid"
          label={
            <FormattedMessage
              id="ES__DOCUMENT_DISPATCHES__DETAIL__FIELD_LABEL__JID"
              defaultMessage="JID"
            />
          }
          disabled={true}
        />
        <FormTextField
          name="document.referenceNumber"
          label={
            <FormattedMessage
              id="ES__DOCUMENT_DISPATCHES__DETAIL__FIELD_LABEL__DOCUMENT_REFERENCE_NUMBER"
              defaultMessage="Číslo jednací"
            />
          }
          after={
            document?.id && (
              <Redirect
                url={EvidenceBrowserUrl.DOCUMENTS_OUTGOING}
                id={document!.id}
              />
            )
          }
          disabled={true}
        />
        <FormTextField
          name="recipient.label"
          label={
            <FormattedMessage
              id="ES__DOCUMENT_DISPATCHES__DETAIL__FIELD_LABEL__RECIPIENT"
              defaultMessage="Adresát"
            />
          }
          disabled={true}
        />
        <FormSelect
          name="method"
          label={
            <FormattedMessage
              id="ES__DOCUMENT_DISPATCHES__DETAIL__FIELD_LABEL__METHOD"
              defaultMessage="Způsob vypravení"
            />
          }
          source={methodsSource}
          valueIsId={true}
        />
        <FormSelect
          name="state"
          label={
            <FormattedMessage
              id="ES__DOCUMENT_DISPATCHES__DETAIL__FIELD_LABEL__STATE"
              defaultMessage="Stav vypravení"
            />
          }
          source={statesSource}
          valueIsId={true}
          disabled={true}
        />
        <FormSelect
          name="dispatchDeliveryMethod"
          label={
            <FormattedMessage
              id="ES__DOCUMENT_DISPATCHES__DETAIL__FIELD_LABEL__DELIVERY_METHOD"
              defaultMessage="Způsob doručení vypravení"
            />
          }
          source={deliveryMethodsSource}
          valueIsId={true}
          disabled={true}
        />
        <FormDateTimeField
          name="dispatchTime"
          representation="local-date-time"
          label={
            <FormattedMessage
              id="ES__DOCUMENT_DISPATCHES__DETAIL__FIELD_LABEL__DISPATCH_TIME"
              defaultMessage="Datum vypravení"
            />
          }
          disabled={true}
        />
        <FormDateTimeField
          name="deliveryTime"
          representation="local-date-time"
          label={
            <FormattedMessage
              id="ES__DOCUMENT_DISPATCHES__DETAIL__FIELD_LABEL__DELIVERY_TIME"
              defaultMessage="Datum doručení"
            />
          }
          disabled={true}
        />
        <FormTextField
          name="nonDeliveryReason"
          label={
            <FormattedMessage
              id="ES__DOCUMENT_DISPATCHES__DETAIL__FIELD_LABEL__NON_DELIVERY_REASON"
              defaultMessage="Důvod nedoručení"
            />
          }
          disabled={deliveryMethod === DispatchDeliveryMethod.DATA_BOX}
        />
        <FormTextField
          name={
            deliveryMethod === DispatchDeliveryMethod.LETTER
              ? 'yoursFileNumber'
              : 'recipient.fileNumber'
          }
          label={
            <FormattedMessage
              id="ES__DOCUMENT_DISPATCHES__DETAIL__FIELD_LABEL__RECIPIENT_FILE_NUMBER"
              defaultMessage="Vaše spisová značka"
            />
          }
        />
        <FormTextField
          name={
            deliveryMethod === DispatchDeliveryMethod.LETTER
              ? 'yoursReferenceNumber'
              : 'recipient.referenceNumber'
          }
          label={
            <FormattedMessage
              id="ES__DOCUMENT_DISPATCHES__DETAIL__FIELD_LABEL__RECIPIENT_REFERENCE_NUMBER"
              defaultMessage="Vaše číslo jednací"
            />
          }
        />
        <FormCustomField
          disabled={true}
          label={
            <FormattedMessage
              id="ES__DOCUMENT_DISPATCHES__DETAIL__FIELD_LABEL__ENVELOPE"
              defaultMessage="Obálka"
            />
          }
        >
          {envelope && (
            <FileField
              disabled={true}
              value={{
                ...envelope?.file,
                name: `${envelope.name} (${envelope?.file.name})`,
              }}
              onChange={noop}
            />
          )}
        </FormCustomField>
        <FormFileField
          name="deliveryNote.file"
          label={
            <FormattedMessage
              id="ES__DOCUMENT_DISPATCHES__DETAIL__FIELD_LABEL__DELIVERY_NOTE"
              defaultMessage="Doručenka"
            />
          }
          disabled={true}
        />
        <FormTextField
          name="barCode"
          label={
            <FormattedMessage
              id="ES__DOCUMENT_DISPATCHES__DETAIL__FIELD_LABEL__BAR_CODE"
              defaultMessage="Čárový kód"
            />
          }
          disabled={true}
        />
      </FormPanel>
      {deliveryMethod === DispatchDeliveryMethod.DATA_BOX && (
        <DataBoxFields display="IN_DISPATCH" />
      )}
      {deliveryMethod === DispatchDeliveryMethod.LETTER && <LetterFields />}
      {deliveryMethod === DispatchDeliveryMethod.NOTICE_BOARD && (
        <NoticeBoardFields />
      )}
    </>
  );
}
