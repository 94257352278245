import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  DetailContext,
  DetailHandle,
  EvidenceContext,
  FormContext,
  StompSubscription,
  WebsocketContext,
  useFormSelector,
} from '@eas/common-web';
import { EsFileFields } from '@modules/document/fields/es-file/es-file';
import { AttachmentField } from '@composite/attachments/attachment-field';
import { Dispatch, Document, DocumentAttachment } from '@models';
import { AttachmentType, DispatchState } from '@enums';
import { DISPATCH_SOURCE } from '../../../document/document-hooked-sources';
import { useOutgoingDocumentPermission } from '../../../document/document-permission';

export function Content() {
  const { setFieldValue } = useContext(FormContext);
  const { hookedSources } = useContext(EvidenceContext);
  const { source } = useContext<DetailHandle<Document>>(DetailContext);
  const { subscribe, unsubscribe } = useContext(WebsocketContext);

  const [subscriptions, setSubscriptions] = useState<StompSubscription[]>([]);

  const { attachments, summary, id } = useFormSelector((data: Document) => ({
    attachments: data.attachments ?? [],
    summary: data.summary ?? '',
    id: data.id,
  }));

  const outgoingAttachments = attachments.filter(
    (a) => a.type === AttachmentType.OUTGOING
  );

  const deliveryNoteAttachments = attachments.filter(
    (a) => a.type === AttachmentType.DELIVERY_NOTE
  );

  const envelopeAttachments = attachments.filter(
    (a) => a.type === AttachmentType.ENVELOPE
  );

  const { hasPermission } = useOutgoingDocumentPermission();

  const disabled =
    hookedSources[DISPATCH_SOURCE].source.items.filter(
      (dispatch: Dispatch) => dispatch.state !== DispatchState.PREPARED
    ).length > 0;

  useEffect(() => {
    return () => {
      subscriptions.forEach((subscription) => {
        unsubscribe(subscription);
      });
    };
  }, [subscriptions]);

  const handleOnAddedAttachment = async (result: DocumentAttachment) => {
    const subscription = result?.id
      ? subscribe(`/topic/entity/${result.id}/update`, () => {
          result.version = (result?.version ?? 0) + 1;
          setFieldValue('attachments', [
            ...(source.data?.attachments ?? []),
            result,
          ]);
        })
      : undefined;
    if (subscription !== undefined) {
      setSubscriptions((items) => [...items, subscription]);
    }
  };

  return (
    <>
      <EsFileFields />
      <AttachmentField
        label={
          <FormattedMessage
            id="ES__DOCUMENT_OUTGOING__DETAIL__PANEL_TITLE__OUTGOING"
            defaultMessage="Přílohy odchozího dokumentu"
          />
        }
        documentId={id}
        attachments={outgoingAttachments}
        attachmentType={AttachmentType.OUTGOING}
        defaultName={summary}
        disabled={disabled}
        hasPermission={hasPermission}
        onAddedAttachment={handleOnAddedAttachment}
      />

      <AttachmentField
        label={
          <FormattedMessage
            id="ES__DOCUMENT_OUTGOING__DETAIL__PANEL_TITLE__ENVELOPES"
            defaultMessage="Obálky"
          />
        }
        documentId={id}
        attachments={envelopeAttachments}
        attachmentType={AttachmentType.ENVELOPE}
        defaultName={summary}
        showToolbar={false}
        disabled={disabled}
        hasPermission={hasPermission}
      />
      <AttachmentField
        label={
          <FormattedMessage
            id="ES__DOCUMENT_OUTGOING__DETAIL__PANEL_TITLE__DELIVERY_NOTES"
            defaultMessage="Doručenky"
          />
        }
        documentId={id}
        attachments={deliveryNoteAttachments}
        attachmentType={AttachmentType.DELIVERY_NOTE}
        defaultName={summary}
        showToolbar={false}
        disabled={disabled}
        hasPermission={hasPermission}
      />
    </>
  );
}
