import React from 'react';
import { useFormSelector } from '@eas/common-web';
import { EsFileFields } from '@modules/document/fields/es-file/es-file';
import { HistoryField } from '@composite/history/history-field';
import { DeliveredDocument } from '@models';
import { useHistoryDescriptors } from './../../document-delivered-history-descriptors';

export function History() {
  const historyDescriptors = useHistoryDescriptors();

  const { processed } = useFormSelector((data: DeliveredDocument) => ({
    processed: data.processed,
  }));

  return (
    <>
      {processed && <EsFileFields />}
      <HistoryField descriptors={historyDescriptors} />
    </>
  );
}
