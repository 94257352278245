import { EsFileState, Role, UIElement } from '@enums';

export const disciplinaryProceedingsFileAccessMap = new Map([
  [
    EsFileState.NEW,
    new Map([
      [
        Role.INDEX,
        [
          UIElement.DisciplinaryProceedingsFile.PHYSICAL_STORAGE,
          UIElement.DisciplinaryProceedingsFile.PROPOSER,
          UIElement.DisciplinaryProceedingsFile.PARTICIPANT,
          UIElement.DisciplinaryProceedingsFile.LEGAL_ADVISER,
          UIElement.DisciplinaryProceedingsFile.DECIDING_AUTHORITY,
          UIElement.DisciplinaryProceedingsFile.NOTES,
          UIElement.DisciplinaryProceedingsFile
            .CREATE_DISCIPLINARY_PROCEEDINGS_FILE_BUTTON,
          UIElement.DisciplinaryProceedingsFile
            .DELETE_DISCIPLINARY_PROCEEDINGS_FILE_BUTTON,
          UIElement.DisciplinaryProceedingsFile
            .UPDATE_DISCIPLINARY_PROCEEDINGS_FILE_BUTTON,
          UIElement.DisciplinaryProceedingsFile
            .UPDATE_DISCIPLINARY_PROCEEDINGS_FILE_STATE_BUTTON,
          UIElement.DisciplinaryProceedingsFile
            .UPDATE_DISCIPLINARY_PROCEEDINGS_FILE_AGENDA_BUTTON,
          UIElement.DisciplinaryProceedingsFile.CREATE_FILE_COVER_BUTTON,
          UIElement.DisciplinaryProceedingsFile.INDEX_USER_ROLE,
          UIElement.Document.ASSING_DELIVERED_DOCUMENT_BUTTON,
        ],
      ],
      [
        Role.ADMIN,
        [
          UIElement.DisciplinaryProceedingsFile.DECIDING_AUTHORITY,
          UIElement.DisciplinaryProceedingsFile
            .UPDATE_DISCIPLINARY_PROCEEDINGS_FILE_BUTTON,
        ],
      ],
    ]),
  ],
  [
    EsFileState.IN_PROGRESS,
    new Map([
      [
        Role.INDEX,
        [
          UIElement.DisciplinaryProceedingsFile.PHYSICAL_STORAGE,
          UIElement.DisciplinaryProceedingsFile.PROPOSER,
          UIElement.DisciplinaryProceedingsFile.PARTICIPANT,
          UIElement.DisciplinaryProceedingsFile.LEGAL_ADVISER,
          UIElement.DisciplinaryProceedingsFile.DECIDING_AUTHORITY,
          UIElement.DisciplinaryProceedingsFile.VIEWINGS,
          UIElement.DisciplinaryProceedingsFile.SETTLEMENT_METHOD,
          UIElement.DisciplinaryProceedingsFile.DECISION_DATE,
          UIElement.DisciplinaryProceedingsFile.DECISION_DELIVERY_DATE,
          UIElement.DisciplinaryProceedingsFile.NOTES,
          UIElement.DisciplinaryProceedingsFile.DRAFTS,
          UIElement.DisciplinaryProceedingsFile
            .UPDATE_DISCIPLINARY_PROCEEDINGS_FILE_STATE_BUTTON,
          UIElement.DisciplinaryProceedingsFile
            .UPDATE_DISCIPLINARY_PROCEEDINGS_FILE_BUTTON,
          UIElement.DisciplinaryProceedingsFile.INDEX_USER_ROLE,
          UIElement.Document.ASSING_DELIVERED_DOCUMENT_BUTTON,
          UIElement.Document.CREATE_INTERNAL_DOCUMENT_BUTTON,
          UIElement.Document.CREATE_OUTGOING_DOCUMENT_BUTTON,
          UIElement.DisciplinaryProceedingsFile.CREATE_FILE_COVER_BUTTON,
          UIElement.Document.CREATE_VOTING_PROTOCOL_BUTTON,
        ],
      ],
      [
        Role.ADMIN,
        [
          UIElement.DisciplinaryProceedingsFile.DECIDING_AUTHORITY,
          UIElement.DisciplinaryProceedingsFile
            .UPDATE_DISCIPLINARY_PROCEEDINGS_FILE_BUTTON,
        ],
      ],
      [
        Role.JUDGE,
        [
          UIElement.DisciplinaryProceedingsFile.DRAFTS,
          UIElement.DisciplinaryProceedingsFile.CREATE_FILE_COVER_BUTTON,
        ],
      ],
    ]),
  ],
  [
    EsFileState.CLOSED,
    new Map([
      [
        Role.LUSTRUM,
        [
          UIElement.DisciplinaryProceedingsFile
            .REOPEN_DISCIPLINARY_PROCEEDINGS_FILE_BUTTON,
        ],
      ],
      [
        Role.INDEX,
        [
          UIElement.DisciplinaryProceedingsFile
            .REOPEN_DISCIPLINARY_PROCEEDINGS_FILE_BUTTON,
          UIElement.DisciplinaryProceedingsFile.CREATE_FILE_COVER_BUTTON,
        ],
      ],
    ]),
  ],
  [
    EsFileState.SHREDDED,
    new Map([
      [
        Role.INDEX,
        [UIElement.DisciplinaryProceedingsFile.CREATE_FILE_COVER_BUTTON],
      ],
    ]),
  ],
]);
