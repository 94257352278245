/**
 * Typ subjektu
 */
export enum SubjectType {
  /**
   * Fyzická osoba
   */
  NATURAL_PERSON = 'NATURAL_PERSON',

  /**
   * Podnikající fyzická osoba
   */
  BUSINESS_NATURAL_PERSON = 'BUSINESS_NATURAL_PERSON',

  /**
   * Právnická osoba
   */
  LEGAL_ENTITY = 'LEGAL_ENTITY',

  /**
   * Neurčeno
   */
  UNSPECIFIED = 'UNSPECIFIED',
}

/**
 * Priorita kontaktu
 */
export enum ContactPriority {
  /**
   * Hlavní kontakt
   */
  PRIMARY_CONTACT = 'PRIMARY_CONTACT',

  /**
   * Vedlejší kontakt
   */
  OTHER_CONTACT = 'OTHER_CONTACT',
}

/**
 * Typ subjektu pro komunikaci s ISDS
 */
export enum IsdsSubjectType {
  /**
   * Fyzická osoba
   */
  NATURAL_PERSON = 'NATURAL_PERSON',

  /**
   * Podnikající fyzická osoba
   */
  BUSINESS_NATURAL_PERSON = 'BUSINESS_NATURAL_PERSON',

  /**
   * Právnická osoba
   */
  LEGAL_ENTITY = 'LEGAL_ENTITY',

  /**
   * Orgán veřejné moci
   */
  PUBLIC_AUTHORITY = 'PUBLIC_AUTHORITY',
}

/**
 * Typ kontaktu
 */
export enum ContactType {
  /**
   * Doručovací adresa
   */
  DELIVERY_ADDRESS = 'DELIVERY_ADDRESS',

  /**
   * Přechodné bydliště
   */
  TEMPORARY_RESIDENCE = 'TEMPORARY_RESIDENCE',

  /**
   * Trvalé bydliště
   */
  PERMANENT_RESIDENCE = 'PERMANENT_RESIDENCE',

  /**
   * Sídlo firmy
   */
  HEADQUARTERS = 'HEADQUARTERS',

  /**
   * Pobočka firmy
   */
  COMPANY_BRANCH = 'COMPANY_BRANCH',

  /**
   * Neurčeno
   */
  NOT_SPECIFIED = 'NOT_SPECIFIED',
}

/**
 * Typ datové schránky
 */
export enum DataBoxType {
  /**
   * Fyzická osoba
   */
  NATURAL_PERSON = 'NATURAL_PERSON',

  /**
   * Podnikající fyzická osoba
   */
  BUSINESS_NATURAL_PERSON = 'BUSINESS_NATURAL_PERSON',

  /**
   * Právnická osoba
   */
  LEGAL_ENTITY = 'LEGAL_ENTITY',

  /**
   * Orgán veřejné moci
   */
  PUBLIC_AUTHORITY = 'PUBLIC_AUTHORITY',
}

/**
 * Pohlaví subjektu
 */
export enum SubjectSex {
  /**
   * Muž
   */
  MALE = 'MALE',

  /**
   * Žena
   */
  FEMALE = 'FEMALE',
}
