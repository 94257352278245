import React, { ReactNode, createContext, useMemo } from 'react';
import { DictionaryObject } from '@eas/common-web';
import {
  useDecisionStates,
  usePublishInCollection,
} from '@modules/decision/decision-api';
import { useDefaultProcessingType } from '@modules/dict-processing-type/dict-processing-type-api';
import {
  useDefaultSettlementMethods,
  useSettlementMethodTags,
} from '@modules/dict-settlement-method/dict-settlement-method-api';
import {
  useDispatchDeliveryMethods,
  useEnvelopeTypes as useDispatchEnvelopeTypes,
  useMethods as useDispatchMethods,
} from '@modules/document-dispatch/document-dispatch-api';
import {
  useDeliveryMethods as useDocumentDeliveryMethods,
  useDispatchStates as useDocumentDispatchStates,
  useOriginators as useDocumentOriginators,
  useStates as useDocumentStates,
  useTypes as useDocumentTypes,
  useOutgoingDocumentDispatchStates,
  useValidationCertificationTypes,
  useValidationEidasTypes,
  useValidationStatuses,
} from '@modules/document/document-api';
import {
  useAgendas,
  useAnonymizationRequests,
  useCourtFileTypes,
  useDecisionForms,
  useDisciplinaryProceedingsProposers,
  useLegalAdviserTypes,
  useParticipantTypes,
  useRegulationEntityTypes,
  useRegulationTypes,
  useStates,
} from '@modules/es-file/es-file-api';
import { useJudgeFunctions } from '@modules/judge/judge-api';
import { useNaspisLogEventTypes } from '@modules/naspis-log-record/naspis-log-record-api';
import { useNotificationEvents } from '@modules/notification/notification-api';
import { useRestServices } from '@modules/rest-message/rest-message-api';
import { useDefaultableAgendas, useRoles } from '@modules/role/role-api';
import {
  useDataBoxTypes,
  useIsdsSubjectTypes,
  useSexes as useSubjectSexes,
  useTypes as useSubjectTypes,
} from '@modules/subject/subject-api';
import {
  useStates as useTaskStates,
  useTypes as useTaskTypes,
} from '@modules/task/task-api';
import { useStates as useUserSubstitutionStates } from '@modules/user-substitution/user-substitution-api';
import {
  useViewingMethods,
  useViewingRequestStates,
} from '@modules/viewing-request/viewing-request-api';
import { useDeadlineStates } from '@composite/deadlines/deadline-api';
import { useTemplates } from '@composite/drafts/draft-api';
import { useEntityTypes, useEventTypes } from '@composite/history/history-api';
import { DictProcessingType, DictSettlementMethod, StateObject } from '@models';
import { Agenda } from '@enums';

export interface StaticDataContextType {
  agendas: DictionaryObject[];
  anonymizationRequests: DictionaryObject[];
  courtFileTypes: DictionaryObject[];
  dataBoxTypes: DictionaryObject[];
  deadlineStates: DictionaryObject[];
  decisionForms: DictionaryObject[];
  decisionStates: DictionaryObject[];
  defaultableAgendas: DictionaryObject[];
  defaultCourtProcessingType: DictProcessingType | null;
  defaultPlenaryOpinionProcessingType: DictProcessingType | null;
  defaultSettlementMethods: DictSettlementMethod[];
  disciplinaryProceedingsProposers: DictionaryObject[];
  dispatchDeliveryMethods: DictionaryObject[];
  dispatchEnvelopeTypes: DictionaryObject[];
  dispatchMethods: DictionaryObject[];
  documentDeliveryMethods: DictionaryObject[];
  documentDispatchStates: DictionaryObject[];
  documentOutgoingDispatchStates: DictionaryObject[];
  documentTypes: DictionaryObject[];
  documentStates: DictionaryObject[];
  historyEntityTypes: DictionaryObject[];
  historyEventTypes: DictionaryObject[];
  isdsSubjectTypes: DictionaryObject[];
  judgeFunctions: DictionaryObject[];
  legalAdviserTypes: DictionaryObject[];
  naspisLogEventTypes: DictionaryObject[];
  notificationEvents: DictionaryObject[];
  originators: DictionaryObject[];
  participantTypes: DictionaryObject[];
  regulationEntityTypes: DictionaryObject[];
  regulationTypes: DictionaryObject[];
  restServices: DictionaryObject[];
  roles: DictionaryObject[];
  settlementMethodTags: DictionaryObject[];
  states: StateObject[];
  subjectSexes: DictionaryObject[];
  subjectTypes: DictionaryObject[];
  taskTypes: DictionaryObject[];
  taskStates: DictionaryObject[];
  userSubstitutionStates: DictionaryObject[];
  publishInCollection: DictionaryObject[];
  validationCertificationTypes: DictionaryObject[];
  validationEidasTypes: DictionaryObject[];
  validationStatuses: DictionaryObject[];
  viewingMethods: DictionaryObject[];
  viewingRequestStates: DictionaryObject[];
  templates: DictionaryObject[];
}

export const StaticDataContext = createContext<StaticDataContextType>({
  agendas: [],
  anonymizationRequests: [],
  courtFileTypes: [],
  dataBoxTypes: [],
  deadlineStates: [],
  decisionForms: [],
  decisionStates: [],
  defaultableAgendas: [],
  defaultCourtProcessingType: null,
  defaultPlenaryOpinionProcessingType: null,
  defaultSettlementMethods: [],
  disciplinaryProceedingsProposers: [],
  dispatchDeliveryMethods: [],
  dispatchEnvelopeTypes: [],
  dispatchMethods: [],
  documentDeliveryMethods: [],
  documentDispatchStates: [],
  documentOutgoingDispatchStates: [],
  documentStates: [],
  documentTypes: [],
  historyEntityTypes: [],
  historyEventTypes: [],
  isdsSubjectTypes: [],
  judgeFunctions: [],
  legalAdviserTypes: [],
  naspisLogEventTypes: [],
  notificationEvents: [],
  originators: [],
  participantTypes: [],
  publishInCollection: [],
  regulationEntityTypes: [],
  regulationTypes: [],
  restServices: [],
  roles: [],
  settlementMethodTags: [],
  states: [],
  subjectSexes: [],
  subjectTypes: [],
  taskTypes: [],
  taskStates: [],
  userSubstitutionStates: [],
  validationCertificationTypes: [],
  validationEidasTypes: [],
  validationStatuses: [],
  viewingMethods: [],
  viewingRequestStates: [],
  templates: [],
});

export function StaticDataProvider({ children }: { children: ReactNode }) {
  const [agendas] = useAgendas();
  const [anonymizationRequests] = useAnonymizationRequests();
  const [courtFileTypes] = useCourtFileTypes();
  const [dataBoxTypes] = useDataBoxTypes();
  const [deadlineStates] = useDeadlineStates();
  const [decisionForms] = useDecisionForms();
  const [decisionStates] = useDecisionStates();
  const [defaultableAgendas] = useDefaultableAgendas();
  const [defaultCourtProcessingType] = useDefaultProcessingType(Agenda.COURT);
  const [defaultPlenaryOpinionProcessingType] = useDefaultProcessingType(
    Agenda.PLENARY_OPINION
  );
  const defaultSettlementMethods = useDefaultSettlementMethods();
  const [disciplinaryProceedingsPropsers] =
    useDisciplinaryProceedingsProposers();
  const [dispatchDeliveryMethods] = useDispatchDeliveryMethods();
  const [dispatchEnvelopeTypes] = useDispatchEnvelopeTypes();
  const [dispatchMethods] = useDispatchMethods();
  const [documentDeliveryMethods] = useDocumentDeliveryMethods();
  const [documentDispatchStates] = useDocumentDispatchStates();
  const [documentOutgoingDispatchStates] = useOutgoingDocumentDispatchStates();
  const [documentStates] = useDocumentStates();
  const [documentTypes] = useDocumentTypes();
  const [historyEntityTypes] = useEntityTypes();
  const [historyEventTypes] = useEventTypes();
  const [isdsSubjectTypes] = useIsdsSubjectTypes();
  const [judgeFunctions] = useJudgeFunctions();
  const [legalAdviserTypes] = useLegalAdviserTypes();
  const [naspisLogEventTypes] = useNaspisLogEventTypes();
  const [notificationEvents] = useNotificationEvents();
  const [originators] = useDocumentOriginators();
  const [participantTypes] = useParticipantTypes();
  const [publishInCollection] = usePublishInCollection();
  const [regulationEntityTypes] = useRegulationEntityTypes();
  const [regulationTypes] = useRegulationTypes();
  const [restServices] = useRestServices();
  const [roles] = useRoles();
  const [settlementMethodTags] = useSettlementMethodTags();
  const [states] = useStates();
  const [subjectSexes] = useSubjectSexes();
  const [subjectTypes] = useSubjectTypes();
  const [taskTypes] = useTaskTypes();
  const [taskStates] = useTaskStates();
  const [validationCertificationTypes] = useValidationCertificationTypes();
  const [validationEidasTypes] = useValidationEidasTypes();
  const [validationStatuses] = useValidationStatuses();
  const [viewingMethods] = useViewingMethods();
  const [viewingRequestStates] = useViewingRequestStates();
  const [userSubstitutionStates] = useUserSubstitutionStates();
  const [templates] = useTemplates();

  const isLoaded =
    !!agendas?.length &&
    !!anonymizationRequests?.length &&
    !!courtFileTypes?.length &&
    !!dataBoxTypes?.length &&
    !!deadlineStates?.length &&
    !!decisionForms?.length &&
    !!decisionStates?.length &&
    !!defaultableAgendas?.length &&
    !!defaultCourtProcessingType &&
    !!defaultPlenaryOpinionProcessingType &&
    !!defaultSettlementMethods &&
    !!disciplinaryProceedingsPropsers?.length &&
    !!dispatchDeliveryMethods?.length &&
    !!dispatchEnvelopeTypes?.length &&
    !!dispatchMethods?.length &&
    !!documentDeliveryMethods?.length &&
    !!documentDispatchStates?.length &&
    !!documentOutgoingDispatchStates?.length &&
    !!documentTypes?.length &&
    !!documentStates?.length &&
    !!historyEntityTypes?.length &&
    !!historyEventTypes?.length &&
    !!isdsSubjectTypes?.length &&
    !!judgeFunctions?.length &&
    !!legalAdviserTypes?.length &&
    !!naspisLogEventTypes?.length &&
    !!notificationEvents?.length &&
    !!originators?.length &&
    !!participantTypes?.length &&
    !!publishInCollection?.length &&
    !!regulationEntityTypes?.length &&
    !!regulationTypes?.length &&
    !!restServices?.length &&
    !!roles?.length &&
    !!settlementMethodTags?.length &&
    !!states?.length &&
    !!subjectSexes?.length &&
    !!subjectTypes?.length &&
    !!taskTypes?.length &&
    !!taskStates?.length &&
    !!templates?.length &&
    !!userSubstitutionStates?.length &&
    !!validationCertificationTypes?.length &&
    !!validationEidasTypes?.length &&
    !!validationStatuses?.length &&
    !!viewingMethods?.length &&
    !!viewingRequestStates?.length;

  const context = useMemo(
    () => ({
      agendas: agendas ?? [],
      anonymizationRequests: anonymizationRequests ?? [],
      courtFileTypes: courtFileTypes ?? [],
      dataBoxTypes: dataBoxTypes ?? [],
      deadlineStates: deadlineStates ?? [],
      decisionForms: decisionForms ?? [],
      decisionStates: decisionStates ?? [],
      defaultableAgendas: defaultableAgendas ?? [],
      defaultCourtProcessingType: defaultCourtProcessingType ?? null,
      defaultPlenaryOpinionProcessingType:
        defaultPlenaryOpinionProcessingType ?? null,
      defaultSettlementMethods: defaultSettlementMethods ?? [],
      disciplinaryProceedingsProposers: disciplinaryProceedingsPropsers ?? [],
      dispatchDeliveryMethods: dispatchDeliveryMethods ?? [],
      dispatchEnvelopeTypes: dispatchEnvelopeTypes ?? [],
      dispatchMethods: dispatchMethods ?? [],
      documentDeliveryMethods: documentDeliveryMethods ?? [],
      documentDispatchStates: documentDispatchStates ?? [],
      documentOutgoingDispatchStates: documentOutgoingDispatchStates ?? [],
      documentStates: documentStates ?? [],
      documentTypes: documentTypes ?? [],
      historyEntityTypes: historyEntityTypes ?? [],
      historyEventTypes: historyEventTypes ?? [],
      isdsSubjectTypes: isdsSubjectTypes ?? [],
      judgeFunctions: judgeFunctions ?? [],
      legalAdviserTypes: legalAdviserTypes ?? [],
      naspisLogEventTypes: naspisLogEventTypes ?? [],
      notificationEvents: notificationEvents ?? [],
      originators: originators ?? [],
      participantTypes: participantTypes ?? [],
      publishInCollection: publishInCollection ?? [],
      regulationEntityTypes: regulationEntityTypes ?? [],
      regulationTypes: regulationTypes ?? [],
      restServices: restServices ?? [],
      roles: roles ?? [],
      settlementMethodTags: settlementMethodTags ?? [],
      states: states ?? [],
      subjectSexes: subjectSexes ?? [],
      subjectTypes: subjectTypes ?? [],
      taskTypes: taskTypes ?? [],
      taskStates: taskStates ?? [],
      templates: templates ?? [],
      userSubstitutionStates: userSubstitutionStates ?? [],
      validationCertificationTypes: validationCertificationTypes ?? [],
      validationEidasTypes: validationEidasTypes ?? [],
      validationStatuses: validationStatuses ?? [],
      viewingMethods: viewingMethods ?? [],
      viewingRequestStates: viewingRequestStates ?? [],
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoaded]
  );

  return (
    <StaticDataContext.Provider value={context}>
      {isLoaded && children}
    </StaticDataContext.Provider>
  );
}
