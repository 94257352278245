import React from 'react';
import { useIntl } from 'react-intl';
import {
  ApiFilterOperation,
  EmptyComponent,
  TableCells,
  TableColumn,
  TableColumnAlign,
  TableFilterCells,
} from '@eas/common-web';
import { Redirect } from '@composite/redirect/redirect';
import {
  autocompleteLabelMapper,
  multipleAutocompleteColumnMapper,
} from '@components/form/misc/label-mappers';
import { InfFile } from '@models';
import { Agenda, EvidenceBrowserUrl, Role } from '@enums';
import { useUserRoles } from '../../role/role-api';
import { useInfColumns } from '../inf-file-columns';

export function useColumns(): TableColumn<InfFile>[] {
  const intl = useIntl();
  const { columnJid, columnDeliveryDate, columnIncomingNumber, columnWriters } =
    useInfColumns();
  const useSolverUsers = () =>
    useUserRoles({
      filters: [
        {
          field: 'role.id',
          operation: ApiFilterOperation.EQ,
          value: Role.SOLVER,
        },
        {
          field: 'agendas.id',
          operation: ApiFilterOperation.EQ,
          value: Agenda.INF,
        },
      ],
    });

  return [
    {
      datakey: 'id',
      name: intl.formatMessage({
        id: 'ES__INF_FILES_IN_PROGRESS__TABLE__COLUMN__REDIRECT',
        defaultMessage: 'Přesměrování',
      }),
      NameComponent: EmptyComponent,
      width: 50,
      CellComponent: function Cell(props) {
        return (
          <>
            <Redirect
              url={EvidenceBrowserUrl.INF_FILES_IN_PROGRESS}
              id={props.value}
              showRedirectInCurrent={false}
            />
          </>
        );
      },
      align: TableColumnAlign.LEFT,
    },
    columnDeliveryDate,
    columnIncomingNumber,
    {
      datakey: 'number.value',
      sortkey: 'number.valueSort',
      name: intl.formatMessage({
        id: 'ES__INF_FILES_IN_PROGRESS__TABLE__COLUMN__FILE_NUMBER',
        defaultMessage: 'Spisová značka',
      }),
      width: 300,
      CellComponent: TableCells.TextCell,
      FilterComponent: TableFilterCells.FilterTextCell,
      sortable: true,
      filterable: true,
    },
    columnJid,
    columnWriters,
    {
      datakey: 'solverUserRoles',
      filterkey: 'solverUserRoles.id',
      sortkey: 'solverUserRoles.concatenated',
      name: intl.formatMessage({
        id: 'ES__INF_FILES_IN_PROGRESS__TABLE__COLUMN__SOLVER_USER_ROLES',
        defaultMessage: 'Řešitelé',
      }),
      width: 300,
      CellComponent: TableCells.TextCell,
      valueMapper: multipleAutocompleteColumnMapper,
      FilterComponent: TableFilterCells.useFilterAutocompleteCellFactory(
        useSolverUsers,
        autocompleteLabelMapper
      ),
      filterOperation: ApiFilterOperation.EQ,
      filterable: true,
      sortable: true,
    },
    {
      datakey: 'administrativeDecision',
      name: intl.formatMessage({
        id: 'ES__INF_FILES_IN_PROGRESS__TABLE__COLUMN__ADMINISTRATIVE_DECISION',
        defaultMessage: 'Správní rozhodnutí',
      }),
      width: 300,
      CellComponent: TableCells.BooleanCell,
      FilterComponent: TableFilterCells.FilterBooleanCell,
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'complaint',
      name: intl.formatMessage({
        id: 'ES__INF_FILES_IN_PROGRESS__TABLE__COLUMN__COMPLAINT',
        defaultMessage: 'Stížnost',
      }),
      width: 300,
      CellComponent: TableCells.BooleanCell,
      FilterComponent: TableFilterCells.FilterBooleanCell,
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
    },
  ];
}
