import React, { useMemo, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  ActionButtonHandle,
  FormPanel,
  PagedTableField,
  ScrollableSource,
  TableFieldHandle,
  TableFieldToolbarProps,
} from '@eas/common-web';
import { FormTableCount } from '@components/form/table-field/form-table-count';
import { DictionaryObject, EsFileRegulation } from '@models';
import { useRegulationFieldColumns } from './regulation-columns';
import { RegulationToolbar } from './regulation-toolbar';

export interface RegulationFieldProps {
  regulationTypes: DictionaryObject[];
  regulationEntityTypes: DictionaryObject[];
  disabled: boolean;
  source?: ScrollableSource<EsFileRegulation>;
}

export function RegulationField({
  regulationEntityTypes,
  regulationTypes,
  disabled,
  source,
}: RegulationFieldProps) {
  const columns = useRegulationFieldColumns({ regulationTypes });

  const tableRef = useRef<TableFieldHandle>(null);
  const editRef = useRef<ActionButtonHandle>(null);

  const ToolbarComponent = useMemo(
    () =>
      function Toolbar(props: TableFieldToolbarProps) {
        return (
          <RegulationToolbar
            {...props}
            ref={editRef}
            regulationEntityTypes={regulationEntityTypes}
            regulationTypes={regulationTypes}
            disabled={disabled}
          />
        );
      },
    [disabled, regulationEntityTypes, regulationTypes]
  );

  if (!source) {
    return <> Missing REGULATION_SOURCE </>;
  }

  return (
    <FormPanel
      label={
        <FormattedMessage
          id="ES__REGULATIONS__PANEL_TITLE"
          defaultMessage="Předpisy"
        />
      }
      summary={<FormTableCount source={source} />}
    >
      <PagedTableField<EsFileRegulation>
        ref={tableRef}
        disabled={disabled}
        columns={columns}
        ToolbarComponent={ToolbarComponent}
        onRowClick={(_, index) => {
          if (!disabled) {
            tableRef.current?.setSelectedIndex(index);
            editRef.current?.executeAction();
          }
        }}
        maxRows={5}
        showDetailBtnCond={() => false}
        source={source}
        showRadioCond={() => false}
        showCheckboxCond={() => true}
      />
    </FormPanel>
  );
}
