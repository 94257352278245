import React from 'react';
import { useIntl } from 'react-intl';
import { ApiFilterOperation, Evidence } from '@eas/common-web';
import { EsFileState } from '@enums';
import { useCourtFiles } from '../court-files';
import { useColumns } from '../registry/court-file-registry-columns';
import { Toolbar } from './court-files-closed-toolbar';

export function CourtFilesClosed() {
  const intl = useIntl();
  const columns = useColumns();

  const { evidenceBag } = useCourtFiles({
    evidenceProps: {
      version: 5,
      tableProps: {
        columns,
        defaultPreFilters: [
          {
            field: 'state.id',
            operation: ApiFilterOperation.EQ,
            value: EsFileState.CLOSED,
          },
        ],
        tableName: intl.formatMessage({
          id: 'ES__COURT_FILES_CLOSED__TABLE__TITLE',
          defaultMessage: 'Soudní spisy - Uzavřené',
        }),
        toolbarProps: {
          after: <Toolbar />,
        },
      },
      detailProps: {
        toolbarProps: {
          showBtn: (button) => {
            if (button === 'NEW') {
              return false;
            }
            return true;
          },
        },
      },
    },
  });

  return <Evidence {...evidenceBag} />;
}
