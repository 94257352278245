import { useEffect, useMemo, useState } from 'react';
import { abortableFetch, useEventCallback } from '@eas/common-web';
import { EvidenceApiUrl } from '@enums';
import { AuthContextType, AuthType } from './auth-types';

/**
 *  Endpoint for getting information about types of currently supported authentications
 */
function authTypeCall() {
  return abortableFetch(EvidenceApiUrl.AUTH, {
    method: 'GET',
  });
}

export function useAuth(): AuthContextType {
  const [authType, setAuthType] = useState<AuthContextType['authTypes']>([]);

  const getAuthType = useEventCallback(async () => {
    try {
      const response = authTypeCall();
      const data: AuthType[] = await response.json();

      setAuthType(() => {
        return data.map((type) => {
          if (type === AuthType.KERBEROS) {
            return { type, loginUrl: `${EvidenceApiUrl.AUTH}/kerberos` };
          }

          return { type, loginUrl: '/' };
        });
      });
    } catch (e) {
      console.log(e);
    }
  });

  useEffect(() => {
    getAuthType();
  }, []);

  return useMemo(() => {
    return { authTypes: authType };
  }, [authType]);
}
