import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import Typography from '@material-ui/core/Typography/Typography';
import {
  DetailContext,
  DetailHandle,
  DetailMode,
  EvidenceContext,
  FormAutocomplete,
  FormCheckbox,
  FormContext,
  FormCustomField,
  FormLocalDateField,
  FormNumberField,
  FormPanel,
  FormSelect,
  FormTextArea,
  FormTextField,
  TextField,
  eqFilterParams,
  useFormSelector,
  useStaticListSource,
} from '@eas/common-web';
import { NOTE_SOURCE } from '@modules/decision/decision-hooked-sources';
import { NoteField } from '@composite/notes/note-field';
import { Redirect } from '@composite/redirect/redirect';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';
import { autocompleteLabelMapper } from '@components/form/misc/label-mappers';
import { Decision } from '@models';
import { EvidenceBrowserUrl, UIElement } from '@enums';
import { useSettlementMethods } from '../../../dict-settlement-method/dict-settlement-method-api';
import { useJudges } from '../../../judge/judge-api';
import { useDecisionPermission } from '../../decision-permission';

const getSyles = () => {
  const citationLeftPart = {
    width: 100,
    marginRight: 5,
  };

  const citationMiddlePart = {
    width: 100,
    marginRight: 5,
    marginLeft: 5,
  };

  const citationRightPart = {
    width: 100,
    marginLeft: 5,
  };

  return { citationLeftPart, citationMiddlePart, citationRightPart };
};

export function GeneralFields() {
  const { mode, source } = useContext<DetailHandle<Decision>>(DetailContext);
  const { setFieldValue } = useContext(FormContext);
  const { hookedSources } = useContext(EvidenceContext);
  const {
    decisionStates,
    decisionForms,
    anonymizationRequests,
    publishInCollection,
  } = useContext(StaticDataContext);

  const { hasPermission } = useDecisionPermission(source?.data);

  const { citationLeftPart, citationMiddlePart, citationRightPart } =
    getSyles();

  const { parallelCitation, esFile } = useFormSelector((data: Decision) => ({
    parallelCitation: data.parallelCitation,
    esFile: data.esFile,
  }));

  const statesSource = useStaticListSource(decisionStates);
  const formsSource = useStaticListSource(decisionForms);
  const anonymizationRequestsSource = useStaticListSource(
    anonymizationRequests
  );
  const publishInCollectionSource = useStaticListSource(publishInCollection);
  const judges = useJudges();
  const settlementMethods = useSettlementMethods(
    eqFilterParams({ field: 'agenda.id', value: source.data?.esFile?.agenda })
  );

  const noteSource = hookedSources[NOTE_SOURCE]?.source;

  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ES__DECISIONS__DETAIL__GENERAL_PANEL_TITLE"
            defaultMessage="Rozhodnutí"
          />
        }
      >
        <FormTextField
          name="esFile.number.value"
          label={
            <FormattedMessage
              id="ES__TASKS__DETAIL__FIELD_LABEL__FILE_NUMBER"
              defaultMessage="Spisová značka"
            />
          }
          after={
            esFile?.id && (
              <Redirect url={EvidenceBrowserUrl.ES_FILES_ALL} id={esFile.id} />
            )
          }
          disabled={!hasPermission(UIElement.Decision.FILE_NUMBER)}
        />

        <FormAutocomplete
          name="judge"
          label={
            <FormattedMessage
              id="ES__DECISIONS__DETAIL__FIELD_LABEL__JUDGE"
              defaultMessage="Soudce zpravodaj"
            />
          }
          source={judges}
          labelMapper={autocompleteLabelMapper}
          disabled={!hasPermission(UIElement.Decision.JUDGE)}
        />

        <FormSelect
          name="esFile.anonymizationRequest"
          source={anonymizationRequestsSource}
          label={
            <FormattedMessage
              id="ES__DECISIONS__DETAIL__FIELD_LABEL__ANONYMIZATION_REQUEST"
              defaultMessage="Žádost o anonymizaci"
            />
          }
          valueIsId={true}
          disabled={!hasPermission(UIElement.Decision.ANONYMIZATION_REQUEST)}
        />
        <FormCheckbox
          name="esFile.anonymize"
          label={
            <FormattedMessage
              id="ES__DECISIONS__DETAIL__FIELD_LABEL__ANONYMIZE"
              defaultMessage="Anonymizovat"
            />
          }
          disabled={!hasPermission(UIElement.Decision.ANONYMIZE)}
        />
        <FormSelect
          name="state"
          source={statesSource}
          label={
            <FormattedMessage
              id="ES__DECISINS__DETAIL__FIELD_LABEL__STATE"
              defaultMessage="Stav"
            />
          }
          valueIsId={true}
          disabled={!hasPermission(UIElement.Decision.STATE)}
        />
        <FormTextField
          name="number"
          label={
            <FormattedMessage
              id="ES__DECISIONS__DETAIL__FIELD_LABEL__NUMBER"
              defaultMessage="Číslo rozhodnutí"
            />
          }
          disabled={!hasPermission(UIElement.Decision.NUMBER)}
        />
        <FormTextField
          name="ecli"
          label={
            <FormattedMessage
              id="ES__DECISIONS__DETAIL__FIELD_LABEL__EUROPEAN_CASE_LAW_ID"
              defaultMessage="Identifikátor evropské judikatury"
            />
          }
          disabled={!hasPermission(UIElement.Decision.ECLI)}
        />
        <FormSelect
          name="form"
          source={formsSource}
          label={
            <FormattedMessage
              id="ES__DECISIONS__DETAIL__FIELD_LABEL__FORM"
              defaultMessage="Forma rozhodnutí"
            />
          }
          valueIsId={true}
          disabled={!hasPermission(UIElement.Decision.FORM)}
        />
        <FormAutocomplete
          name="settlementMethods"
          source={settlementMethods}
          label={
            <FormattedMessage
              id="ES__DECISIONS__DETAIL__FIELD_LABEL__SETTLEMENT_METHODS"
              defaultMessage="Způsoby vyřízení"
            />
          }
          labelMapper={autocompleteLabelMapper}
          multiple={true}
          disabled={!hasPermission(UIElement.Decision.SETTLEMENT_METHODS)}
        />
        <FormCheckbox
          name="allowPublishing"
          label={
            <FormattedMessage
              id="ES__DECISIONS__DETAIL__FIELD_LABEL__ALLOW_PUBLISHING"
              defaultMessage="Umožnit zveřejnění"
            />
          }
          disabled={!hasPermission(UIElement.Decision.ALLOW_PUBLISHING)}
        />
        <FormCheckbox
          name="takeOverIndexes"
          label={
            <FormattedMessage
              id="ES__DECISIONS__DETAIL__FIELD_LABEL__TAKE_OVER_INDEXES"
              defaultMessage="Převzít indexy"
            />
          }
          disabled={!hasPermission(UIElement.Decision.TAKE_OVER_INDEXES)}
        />
        <FormLocalDateField
          name="decisionDate"
          label={
            <FormattedMessage
              id="ES__DECISIONS__DETAIL__FIELD_LABEL__DECISION_DATE"
              defaultMessage="Datum rozhodnutí"
            />
          }
          disabled={!hasPermission(UIElement.Decision.DECISION_DATE)}
        />
        <FormLocalDateField
          name="publicationDate"
          label={
            <FormattedMessage
              id="ES__DECISIONS__DETAIL__FIELD_LABEL__PUBLICATION_DATE"
              defaultMessage="Datum vyhlášení"
            />
          }
          disabled={!hasPermission(UIElement.Decision.PUBLICATION_DATE)}
        />
        <FormLocalDateField
          name="madeAccessibleDate"
          label={
            <FormattedMessage
              id="ES__DECISIONS__DETAIL__FIELD_LABEL__MADE_ACCESSIBLE_DATE"
              defaultMessage="Datum zpřístupnění"
            />
          }
          disabled={!hasPermission(UIElement.Decision.MADE_ACCESSIBLE_DATE)}
        />
        <FormSelect
          name="publishInCollection"
          source={publishInCollectionSource}
          label={
            <FormattedMessage
              id="ES__DECISIONS__DETAIL__FIELD_LABEL__PUBLISH_IN_COLLECTION"
              defaultMessage="Publikovat ve sbírce"
            />
          }
          valueIsId={true}
          disabled={!hasPermission(UIElement.Decision.PUBLISH_IN_COLLECTION)}
        />
        <FormCustomField
          label={
            <FormattedMessage
              id="ES__DECISIONS__DETAIL__FIELD_LABEL__PARALLEL_CITATION_SB"
              defaultMessage="Paralelní citace (Sb.)"
            />
          }
        >
          {mode === DetailMode.VIEW && (
            <Typography>{`${parallelCitation?.sbNumber ?? ''}/${
              parallelCitation?.sbYear ?? ''
            } Sb.`}</Typography>
          )}
          {mode !== DetailMode.VIEW && (
            <>
              <TextField
                style={citationLeftPart}
                value={parallelCitation?.sbNumber}
                onChange={(value) =>
                  setFieldValue('parallelCitation.sbNumber', value)
                }
                disabled={
                  !hasPermission(UIElement.Decision.PARALLEL_CITATION_SB)
                }
              />
              /
              <TextField
                style={citationMiddlePart}
                value={parallelCitation?.sbYear}
                onChange={(value) =>
                  setFieldValue('parallelCitation.sbYear', value)
                }
                disabled={
                  !hasPermission(UIElement.Decision.PARALLEL_CITATION_SB)
                }
              />
              Sb.
            </>
          )}
        </FormCustomField>
        <FormCustomField
          label={
            <FormattedMessage
              id="ES__DECISIONS__DETAIL__FIELD_LABEL__PARALLEL_CITATION_SBNU"
              defaultMessage="Paralelní citace (SbNU)"
            />
          }
        >
          {mode === DetailMode.VIEW && (
            <Typography>{`${parallelCitation?.sbnuNumber ?? ''}/${
              parallelCitation?.sbnuPage ?? ''
            } SbNU ${parallelCitation?.sbnuVolume ?? ''}`}</Typography>
          )}
          {mode !== DetailMode.VIEW && (
            <>
              <TextField
                style={citationLeftPart}
                value={parallelCitation?.sbnuNumber}
                onChange={(value) =>
                  setFieldValue('parallelCitation.sbnuNumber', value)
                }
                disabled={
                  !hasPermission(UIElement.Decision.PARALLEL_CITATION_SBNU)
                }
              />
              /
              <TextField
                style={citationMiddlePart}
                value={parallelCitation?.sbnuPage}
                onChange={(value) =>
                  setFieldValue('parallelCitation.sbnuPage', value)
                }
                disabled={
                  !hasPermission(UIElement.Decision.PARALLEL_CITATION_SBNU)
                }
              />
              SbNU
              <TextField
                style={citationRightPart}
                value={parallelCitation?.sbnuVolume}
                onChange={(value) =>
                  setFieldValue('parallelCitation.sbnuVolume', value)
                }
                disabled={
                  !hasPermission(UIElement.Decision.PARALLEL_CITATION_SBNU)
                }
              />
            </>
          )}
        </FormCustomField>
        <FormTextField
          name="popularName"
          label={
            <FormattedMessage
              id="ES__DECISIONS__DETAIL__FIELD_LABEL__POPULAR_NAME"
              defaultMessage="Populární název"
            />
          }
          disabled={!hasPermission(UIElement.Decision.POPULAR_NAME)}
        />
        <FormNumberField
          name="importance"
          label={
            <FormattedMessage
              id="ES__DECISIONS__DETAIL__FIELD_LABEL__IMPORTANCE"
              defaultMessage="Význam"
            />
          }
          disabled={!hasPermission(UIElement.Decision.IMPORTANCE)}
        />
        <FormAutocomplete
          name="differentOpinion"
          label={
            <FormattedMessage
              id="ES__DECISIONS__DETAIL__FIELD_LABEL__DIFFERENT_OPINION"
              defaultMessage="Odlišné stanovisko"
            />
          }
          source={judges}
          labelMapper={autocompleteLabelMapper}
          multiple={true}
          disabled={!hasPermission(UIElement.Decision.DIFFERENT_OPINION)}
        />
        <FormTextArea
          name="note"
          label={
            <FormattedMessage
              id="ES__DECISIONS__DETAIL__FIELD_LABEL__NOTE"
              defaultMessage="Poznámka"
            />
          }
          disabled={!hasPermission(UIElement.Decision.NOTE)}
        />
        <FormTextField
          name="language"
          label={
            <FormattedMessage
              id="ES__DECISIONS__DETAIL__FIELD_LABEL__LANGUAGE"
              defaultMessage="Jazyk rozhodnutí"
            />
          }
          disabled={!hasPermission(UIElement.Decision.LANGUAGE)}
        />
        <FormCheckbox
          name="excludedFromNalus"
          label={
            <FormattedMessage
              id="ES__DECISIONS__DETAIL__FIELD_LABEL__EXCLUDED_FROM_NALUS"
              defaultMessage="Vyloučeno z NALUSu"
            />
          }
          disabled={!hasPermission(UIElement.Decision.EXCLUDED_FROM_NALUS)}
        />
      </FormPanel>
      <NoteField
        disabled={
          !hasPermission(UIElement.Decision.NOTES) || mode !== DetailMode.VIEW
        }
        source={noteSource}
      />
    </>
  );
}
