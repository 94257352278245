import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import {
  ApiFilterOperation,
  EmptyComponent,
  TableCells,
  TableColumn,
  TableColumnAlign,
  TableFilterCells,
  useStaticListSource,
} from '@eas/common-web';
import { Redirect } from '@composite/redirect/redirect';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';
import { autocompleteColumnMapper } from '@components/form/misc/label-mappers';
import { InfFile } from '@models';
import { Agenda, EvidenceBrowserUrl } from '@enums';

export function useColumns(): TableColumn<InfFile>[] {
  const intl = useIntl();
  const { states, disciplinaryProceedingsProposers } =
    useContext(StaticDataContext);

  const useStates = () =>
    useStaticListSource(
      states.filter((state) =>
        state?.forAgendas?.includes(Agenda.DISCIPLINARY_PROCEEDINGS)
      )
    );

  const useProposers = () =>
    useStaticListSource(disciplinaryProceedingsProposers);

  return [
    {
      datakey: 'id',
      name: intl.formatMessage({
        id: 'ES__DISCIPLINARY_PROCEEDINGS_FILES__TABLE__COLUMN__REDIRECT',
        defaultMessage: 'Přesměrování',
      }),
      NameComponent: EmptyComponent,
      width: 50,
      CellComponent: function Cell(props) {
        return (
          <>
            <Redirect
              url={EvidenceBrowserUrl.DISCIPLINARY_PROCEEDINGS_FILES}
              id={props.value}
              showRedirectInCurrent={false}
            />
          </>
        );
      },
      align: TableColumnAlign.LEFT,
    },
    {
      datakey: 'number.value',
      sortkey: 'number.valueSort',
      name: intl.formatMessage({
        id: 'ES__DISCIPLINARY_PROCEEDINGS_FILES__TABLE__COLUMN__FILE_NUMBER',
        defaultMessage: 'Spisová značka',
      }),
      width: 300,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'jid',
      name: intl.formatMessage({
        id: 'ES__DISCIPLINARY_PROCEEDINGS_FILES__TABLE__COLUMN__JID',
        defaultMessage: 'JID',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'created',
      name: intl.formatMessage({
        id: 'ES__DISCIPLINARY_PROCEEDINGS_FILES__TABLE__COLUMN__CREATED',
        defaultMessage: 'Datum vytvoření',
      }),
      width: 300,
      CellComponent: TableCells.DateTimeCell,
      FilterComponent: TableFilterCells.FilterDateTimeCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'proposer',
      filterkey: 'proposer.id',
      sortkey: 'proposer.name',
      name: intl.formatMessage({
        id: 'ES__DISCIPLINARY_PROCEEDINGS_FILES__TABLE__COLUMN__PROPOSER',
        defaultMessage: 'Navrhovatel',
      }),
      width: 300,
      CellComponent: TableCells.TextCell,
      valueMapper: TableCells.useSelectCellFactory(useProposers),
      FilterComponent:
        TableFilterCells.useFilterSelectCellFactory(useProposers),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'participant',
      filterkey: 'participant.name',
      sortkey: 'participant.name',
      name: intl.formatMessage({
        id: 'ES__DISCIPLINARY_PROCEEDINGS_FILES__TABLE__COLUMN__PARTICIPANT',
        defaultMessage: 'Účastník',
      }),
      width: 300,
      CellComponent: TableCells.TextCell,
      valueMapper: autocompleteColumnMapper,
      filterOperation: ApiFilterOperation.CONTAINS,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'state',
      filterkey: 'state.id',
      sortkey: 'state.name',
      name: intl.formatMessage({
        id: 'ES__DISCIPLINARY_PROCEEDINGS_FILES__TABLE__COLUMN__STATE',
        defaultMessage: 'Stav',
      }),
      width: 300,
      CellComponent: TableCells.TextCell,
      valueMapper: TableCells.useSelectCellFactory(useStates),
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(useStates),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'decisionDate',
      name: intl.formatMessage({
        id: 'ES__DISCIPLINARY_PROCEEDINGS_FILES__TABLE__COLUMN__DECISION_DATE',
        defaultMessage: 'Datum rozhodnutí',
      }),
      width: 300,
      CellComponent: TableCells.DateCell,
      FilterComponent: TableFilterCells.FilterDateCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'decisionDeliveryDate',
      name: intl.formatMessage({
        id: 'ES__DISCIPLINARY_PROCEEDINGS_FILES__TABLE__COLUMN__DECISION_DELIVERY_DATE',
        defaultMessage: 'Datum doručení rozhodnutí',
      }),
      width: 300,
      CellComponent: TableCells.DateCell,
      FilterComponent: TableFilterCells.FilterDateCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'closingDate',
      name: intl.formatMessage({
        id: 'ES__DISCIPLINARY_PROCEEDINGS_FILES__TABLE__COLUMN__CLOSING_DATE',
        defaultMessage: 'Datum uzavření',
      }),
      width: 300,
      CellComponent: TableCells.DateTimeCell,
      FilterComponent: TableFilterCells.FilterDateTimeCell,
      sortable: true,
      filterable: true,
    },
  ];
}
