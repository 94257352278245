import {
  DictionaryObject,
  Params,
  eqFilterParams,
  useAutocompleteSource,
  useFetch,
} from '@eas/common-web';
import { Document, InfFile, SprFile } from '@models';
import { Agenda, EvidenceApiUrl, ParticipantType } from '@enums';

/**
 * List all available outgoing document decision forms
 */
export function useDocumentTypes() {
  return useFetch<DictionaryObject[]>(`${EvidenceApiUrl.DOCUMENTS}/type/list`, {
    method: 'GET',
  });
}

/**
 * List all available outgoing document decision forms
 */
export function useDecisionForms() {
  return useFetch<DictionaryObject[]>(
    `${EvidenceApiUrl.DOCUMENTS}/outgoing/decision-form/list`,
    {
      method: 'GET',
    }
  );
}

/**
 * List all available document delivery methods.
 */
export function useDeliveryMethods() {
  return useFetch<DictionaryObject[]>(
    `${EvidenceApiUrl.DOCUMENTS}/delivered/method/list`,
    {
      method: 'GET',
      cacheResponse: true,
    }
  );
}

/**
 * List all available dispatch states
 */
export function useDispatchStates() {
  return useFetch<DictionaryObject[]>(
    `${EvidenceApiUrl.DOCUMENTS}/dispatch/state/list`,
    {
      method: 'GET',
      cacheResponse: true,
      cacheVersion: 1,
    }
  );
}

/**
 * List all available outgoing document dispatch states
 */
export function useOutgoingDocumentDispatchStates() {
  return useFetch<DictionaryObject[]>(
    `${EvidenceApiUrl.DOCUMENTS}/outgoing/dispatch-state/list`,
    {
      method: 'GET',
      cacheResponse: true,
    }
  );
}

/**
 * List all available validation eIDAS types
 */
export function useValidationEidasTypes() {
  return useFetch<DictionaryObject[]>(
    `${EvidenceApiUrl.DOCUMENTS}/validation/eidas-type/list`,
    {
      method: 'GET',
      cacheResponse: true,
    }
  );
}

/**
 * List all available validation statuses
 */
export function useValidationStatuses() {
  return useFetch<DictionaryObject[]>(
    `${EvidenceApiUrl.DOCUMENTS}/validation/status/list`,
    {
      method: 'GET',
      cacheResponse: true,
    }
  );
}

/**
 * List all available validation certification types
 */
export function useValidationCertificationTypes() {
  return useFetch<DictionaryObject[]>(
    `${EvidenceApiUrl.DOCUMENTS}/validation/certificate-type/list`,
    {
      method: 'GET',
      cacheResponse: true,
    }
  );
}

/**
 * List all available document originators.
 */
export function useOriginators() {
  return useFetch<DictionaryObject[]>(
    `${EvidenceApiUrl.DOCUMENTS}/delivered/originator/list`,
    {
      method: 'GET',
      cacheResponse: true,
    }
  );
}

/**
 * Static list of all processed state.
 */
export function useStates() {
  return [
    [
      {
        id: true as unknown as 'true',
        name: 'Zpracováno',
      },
      {
        id: false as unknown as 'false',
        name: 'Nezpracováno',
      },
    ],
  ];
}

/**
 * List all available document types.
 */
export function useTypes() {
  return useFetch<DictionaryObject[]>(`${EvidenceApiUrl.DOCUMENTS}/type/list`, {
    method: 'GET',
    cacheResponse: true,
  });
}

export function useDocuments(params?: Params) {
  return useAutocompleteSource<Document>({
    url: `${EvidenceApiUrl.DOCUMENTS}/autocomplete`,
    params,
  });
}

export async function postProcessGetDocument(data: Document) {
  if (
    !(
      data?.esFile?.id &&
      data.agenda &&
      [Agenda.INF, Agenda.SPR].includes(data.agenda)
    )
  ) {
    return data;
  }
  /**
   * Append writers related to esFile
   */
  const response = await fetch(
    `${EvidenceApiUrl.ES_FILES}/${data.esFile?.id}/participant/list`,
    {
      method: 'POST',
      headers: new Headers({ 'Content-Type': 'application/json' }),
      body: JSON.stringify({
        ...eqFilterParams({ field: 'type.id', value: ParticipantType.WRITER }),
        size: -1,
      }),
    }
  );

  const writers = await response.json();

  if (writers?.items?.length) {
    data.esFile = { ...data.esFile, writers: writers.items } as
      | SprFile
      | InfFile;
  }

  return data;
}
