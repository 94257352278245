import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import {
  ApiFilterOperation,
  EmptyComponent,
  TableCells,
  TableColumn,
  TableColumnAlign,
  TableFilterCells,
  useStaticListSource,
} from '@eas/common-web';
import { Redirect } from '@composite/redirect/redirect';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';
import { Dispatch } from '@models';
import { DispatchMethod, EvidenceBrowserUrl } from '@enums';

export function useColumns(): TableColumn<Dispatch>[] {
  const intl = useIntl();

  const { dispatchMethods } = useContext(StaticDataContext);
  const useMethods = () =>
    useStaticListSource(
      dispatchMethods.filter((method) => method.id !== DispatchMethod.LETTER) // dislike
    );

  return [
    {
      datakey: 'id',
      name: intl.formatMessage({
        id: 'ES__DOCUMENT_DISPATCHES_ELECTRONIC__TABLE__COLUMN__REDIRECT',
        defaultMessage: 'Přesměrování',
      }),
      NameComponent: EmptyComponent,
      width: 50,
      CellComponent: function Cell(props) {
        return (
          <>
            <Redirect
              url={EvidenceBrowserUrl.DOCUMENTS_DISPATCHES_ELECTRONIC}
              id={props.value}
              showRedirectInCurrent={false}
            />
          </>
        );
      },
      align: TableColumnAlign.LEFT,
    },
    {
      datakey: 'document.referenceNumber',
      sortkey: 'document.referenceNumberSort',
      filterkey: 'document.referenceNumber',
      name: intl.formatMessage({
        id: 'ES__DOCUMENT_DISPATCHES_ELECTRONIC__TABLE__COLUMN__DOCUMENT_REFERENCE_NUMBER',
        defaultMessage: 'Číslo jednací',
      }),
      width: 150,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'document.esFile.number.value',
      sortkey: 'document.esFile.number.valueSort',
      filterkey: 'document.esFile.number.value',
      name: intl.formatMessage({
        id: 'ES__DOCUMENT_DISPATCHES__TABLE__COLUMN__DOCUMENT_ES_FILE',
        defaultMessage: 'Spisová značka',
      }),
      width: 120,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'jid',
      name: intl.formatMessage({
        id: 'ES__DOCUMENT_DISPATCHES_ELECTRONIC__TABLE__COLUMN__JID',
        defaultMessage: 'JID',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'document.summary',
      sortkey: 'document.summary',
      filterkey: 'document.summary',
      name: intl.formatMessage({
        id: 'ES__DOCUMENT_DISPATCHES_ELECTRONIC__TABLE__COLUMN__DOCUMENT_SUMMARY',
        defaultMessage: 'Stručný obsah',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'recipient.name',
      sortkey: 'recipient.name',
      filterkey: 'recipient.name',
      name: intl.formatMessage({
        id: 'ES__DOCUMENT_DISPATCHES__TABLE__COLUMN__RECIPIENT_NAME',
        defaultMessage: 'Adresát',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'method',
      sortkey: 'method.name',
      filterkey: 'method.id',
      name: intl.formatMessage({
        id: 'ES__DOCUMENT_DISPATCHES_ELECTRONIC__TABLE__COLUMN__METHOD',
        defaultMessage: 'Způsob vypravení',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      valueMapper: TableCells.useSelectCellFactory(useMethods),
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(useMethods),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'recipient.dataBoxId',
      name: intl.formatMessage({
        id: 'ES__DOCUMENT_DISPATCHES_ELECTRONIC__TABLE__COLUMN__RECIPIENT_DATA_BOX_ID',
        defaultMessage: 'ID datové schránky',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'recipient.email',
      name: intl.formatMessage({
        id: 'ES__DOCUMENT_DISPATCHES_ELECTRONIC__TABLE__COLUMN__RECIPIENT_EMAIL',
        defaultMessage: 'Email',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
  ];
}
