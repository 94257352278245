import React, { useContext } from 'react';
import { DetailContext, DetailMode, EvidenceContext } from '@eas/common-web';
import { EsFileFields } from '@modules/document/fields/es-file/es-file';
import { DispatchField } from '@composite/dispatches/dispatch-field';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';
import { DISPATCH_SOURCE } from '../../../document/document-hooked-sources';
import { useOutgoingDocumentPermission } from '../../../document/document-permission';

export function Dispatches() {
  const { hookedSources } = useContext(EvidenceContext);
  const {
    documentDeliveryMethods,
    documentDispatchStates,
    dispatchDeliveryMethods,
  } = useContext(StaticDataContext);
  const { isLocked, mode } = useContext(DetailContext);

  const disabledDispatches = isLocked || mode !== DetailMode.VIEW;
  const dispatchSource = hookedSources[DISPATCH_SOURCE]?.source;

  const { hasPermission } = useOutgoingDocumentPermission();

  return (
    <>
      <EsFileFields />
      <DispatchField
        dispatchMethods={documentDeliveryMethods}
        dispatchStates={documentDispatchStates}
        dispatchDeliveryMethods={dispatchDeliveryMethods}
        disabled={disabledDispatches}
        source={dispatchSource}
        hasPermission={hasPermission}
      />
    </>
  );
}
