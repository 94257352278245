import clsx from 'clsx';
import { get, noop, stubFalse } from 'lodash';
import React, { Ref, forwardRef, useContext, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import MuiTooltip from '@material-ui/core/Tooltip';
import makeStyles from '@material-ui/core/styles/makeStyles';
import AddIcon from '@material-ui/icons/Add';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import {
  FormPanel,
  PagedTableFieldContext,
  TableField,
  TableFieldCells,
  TableFieldContext,
  TableFieldRowProps,
  useEventCallback,
} from '@eas/common-web';
import { Document, DocumentAttachment } from '@models';
import { AttachmentDialog } from '../attachments/attachment-dialog';
import { useAttachmentColumns } from './document-columns';
import { DocumentContext } from './document-context';

const useStyles = makeStyles((theme) => ({
  rowWrapper: {
    cursor: 'pointer',
    // background: '#e0e2e3',
    minHeight: 30,
    borderBottom: '1px solid #cdcdcd',
  },
  row: {
    boxSizing: 'border-box',
    minWidth: '100%',
    padding: '0px 16px 1px',
    '&:hover': {
      backgroundColor: `${theme.palette.highlight} !important`,
    },
    display: 'flex',
    alignItems: 'center',
    flexShrink: 0,
  },
  canceledRow: {
    textDecoration: 'line-through',
  },
  cellWrapper: {
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    paddingRight: 10,
    boxSizing: 'border-box',
  },
  tooltip: {
    backgroundColor: '#FEFFDE',
    color: 'black',
    border: '1px solid black',
    maxWidth: 500,
    textAlign: 'left',
    maxHeight: 300,
    overflow: 'auto',
    fontSize: 14,
  },
  tableSpeedDial: {
    width: 20,
    height: 20,
    minHeight: 20,
    boxShadow: 'none',
  },
  tableSpeedDialAction: {
    width: 32,
    height: 32,
    minHeight: 32,
  },
  tableSpeedDialTooltip: {
    margin: 0,
    paddingRight: 20,
    backgroundColor: 'transparent',
    zIndex: 1200,
  },
  tableSpeedDialIcon: {
    width: '20px !important',
    height: '20px !important',
  },
  tableSpeedDialButton: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: `white !important`,
    '&:hover': {
      backgroundColor: `${theme.palette.primary.light} !important`,
    },
  },
  tableSpeedDialButtonDisabled: {
    opacity: '0.5 !important',
  },
  tableRowActions: {
    flexShrink: 0,
    height: 20,
    verticalAlign: 'top',
    display: 'inline-flex',
    padding: 0,
    // overflow: 'hidden',
    '& svg': {
      cursor: 'pointer',
      width: 19,
      height: 19,
      padding: '2pt',
    },
  },
  attachments: {},
}));

export const DocumentTableFieldRow = forwardRef(function DocumentTableFieldRow(
  { value, ...props }: TableFieldRowProps<Document>,
  ref: Ref<HTMLDivElement>
) {
  const { page, rowsPerPage, source } = useContext(PagedTableFieldContext);
  const {
    filteredColumns,
    speedDialActions,
    actionColumnWidth,
    showSpeedDialCond,
    createRowStyle,
  } = useContext(TableFieldContext);
  const { openState, setOpenState } = useContext(DocumentContext);

  const columns = useAttachmentColumns(value.agenda);
  const classes = useStyles();
  const rowStyle = createRowStyle(value);

  const index = page * rowsPerPage + props.index;
  const open = openState[index];

  const documents = source.items;

  const attachments = documents[index].attachments ?? [];
  const documentType = documents[index].type;

  const handleOpen = useEventCallback(() => {
    setOpenState({
      ...openState,
      [index]: !open,
    });
  });

  const [openDialogActions, setOpenDialogActions] = useState(false);
  const SpeedDialActions = useMemo(
    () => (
      <>
        {speedDialActions.map((action, i) => {
          const visible = action?.show?.(value, index) ?? true;
          const disabled = action?.disabled?.(value, index) ?? false;

          if (!visible) {
            return null;
          }

          return (
            <SpeedDialAction
              key={action.name}
              icon={<action.Icon className={classes.tableSpeedDialIcon} />}
              FabProps={{
                disabled,
                classes: {
                  disabled: classes.tableSpeedDialButtonDisabled,
                },
              }}
              TooltipClasses={{ tooltip: classes.tooltip }}
              tooltipTitle={action.name}
              tooltipPlacement="top"
              open={true}
              onClick={() => action.onClick(value, index)}
              className={clsx(
                classes.tableSpeedDialAction,
                classes.tableSpeedDialButton
              )}
            />
          );
        })}
      </>
    ),
    [
      classes.tableSpeedDialAction,
      classes.tableSpeedDialButton,
      classes.tableSpeedDialButtonDisabled,
      classes.tableSpeedDialIcon,
      index,
      speedDialActions,
      classes.tooltip,
      value,
    ]
  );

  const showSpeedDialButton = showSpeedDialCond(value);

  return (
    <div ref={ref} className={classes.rowWrapper}>
      <div
        className={clsx(classes.row, {
          [classes.canceledRow]: value.canceledDate,
        })}
        onClick={handleOpen}
        style={rowStyle}
      >
        <div
          className={classes.tableRowActions}
          style={{ width: actionColumnWidth }}
        >
          {showSpeedDialButton ? (
            <MuiTooltip
              interactive={true}
              placement="left"
              classes={{
                tooltip: classes.tableSpeedDialTooltip,
              }}
              onOpen={() => setOpenDialogActions(true)}
              onClose={() => setOpenDialogActions(false)}
              open={openDialogActions}
              TransitionProps={{ timeout: 300 }}
              PopperProps={
                {
                  style: {
                    zIndex: 1200,
                  },
                } as any
              }
              title={SpeedDialActions}
            >
              <div className={classes.tableSpeedDialIcon}>
                <AddIcon
                  style={{
                    transition:
                      'transform 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                    transform: openDialogActions
                      ? 'rotate(45deg)'
                      : 'rotate(0)',
                  }}
                />
              </div>
            </MuiTooltip>
          ) : (
            <></>
          )}
        </div>
        {filteredColumns.map((column, i) => {
          const { CellComponent = TableFieldCells.TextCell } = column;
          return (
            <div
              key={i}
              className={classes.cellWrapper}
              style={{
                width: column.width,
              }}
            >
              <CellComponent
                index={index}
                value={get(value, column.datakey, '')}
                rowValue={value}
                column={column}
              />
              {column.datakey === 'id' &&
                attachments?.length > 0 &&
                (open ? (
                  <KeyboardArrowUpIcon onClick={handleOpen} color="primary" />
                ) : (
                  <KeyboardArrowDownIcon onClick={handleOpen} color="primary" />
                ))}
            </div>
          );
        })}
      </div>
      <div className={classes.attachments}>
        {!!attachments?.length && open && (
          <>
            <FormPanel
              label={
                <FormattedMessage
                  id="ES__ES_FILES__DETAIL__DOCUMENTS_PANEL_TITLE"
                  defaultMessage="Přílohy"
                />
              }
            >
              <TableField<DocumentAttachment>
                value={attachments}
                onChange={noop}
                columns={columns}
                FormFieldsComponent={() => (
                  <AttachmentDialog
                    documentType={documentType}
                    documentId={value.id}
                  />
                )}
                showRadioCond={stubFalse}
                maxRows={5}
                showToolbar={false}
              />
            </FormPanel>
          </>
        )}
      </div>
    </div>
  );
});
