import { abortableFetch, useEventCallback } from '@eas/common-web';
import { EvidenceApiUrl } from '@enums';

export function useDeleteRegulationDialog() {
  const callApi = useEventCallback(
    (esFileId: string, regulationIds: string[]) => {
      return abortableFetch(
        `${EvidenceApiUrl.ES_FILES}/${esFileId}/regulation`,
        {
          method: 'DELETE',
          headers: new Headers({
            'Content-Type': 'application/json',
          }),
          body: JSON.stringify(regulationIds),
        }
      );
    }
  );

  return {
    callApi,
  };
}
