import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  DetailContext,
  DetailMode,
  EvidenceContext,
  FormAutocomplete,
  FormDateTimeField,
  FormLocalDateField,
  FormLocalDateTimeField,
  FormPanel,
  FormSelect,
  FormTextField,
  eqFilterParams,
  useStaticListSource,
} from '@eas/common-web';
import { usePhysicalStorages } from '@modules/dict-physical-storage/dict-physical-storage-api';
import { NoteField } from '@composite/notes/note-field';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';
import { autocompleteLabelMapper } from '@components/form/misc/label-mappers';
import { Agenda, EsFileState, UIElement } from '@enums';
import { useProcessingTypes } from '../../../dict-processing-type/dict-processing-type-api';
import { useSettlementMethods } from '../../../dict-settlement-method/dict-settlement-method-api';
import { NOTE_SOURCE } from '../../../es-file/es-file-hooked-sources';
import { usePlenaryOpinionPermission } from '../../../es-file/es-file-permission';
import { ChallengedFileField } from './challenged-file-field';
import { SourceFileField } from './source-file-field';

export function GeneralFields() {
  const { hookedSources } = useContext(EvidenceContext);
  const { mode, isLocked, source } = useContext(DetailContext);
  const { states, agendas } = useContext(StaticDataContext);

  const agendaSource = useStaticListSource(agendas);
  const statesSource = useStaticListSource(
    states.filter(
      (state) =>
        state.forAgendas?.includes(Agenda.PLENARY_OPINION) &&
        ![EsFileState.SHREDDED, EsFileState.REGISTRY].includes(
          state.id as EsFileState
        )
    )
  );
  const processingTypes = useProcessingTypes();
  const noteSource = hookedSources[NOTE_SOURCE]?.source;

  const settlementMethods = useSettlementMethods(
    eqFilterParams({ field: 'agenda.id', value: Agenda.PLENARY_OPINION })
  );

  const physicalStorages = usePhysicalStorages();

  /**
   * Permissions
   */
  const { hasPermission } = usePlenaryOpinionPermission(source.data);

  const disabledNotes =
    isLocked ||
    mode !== DetailMode.VIEW ||
    !hasPermission(UIElement.PlenaryOpinionFile.NOTES);

  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ES__PLENARY_OPINION_FILES__DETAIL__GENERAL_PANEL_TITLE"
            defaultMessage="Spis"
          />
        }
      >
        <FormTextField
          name="number.value"
          label={
            <FormattedMessage
              id="ES__PLENARY_OPINION_FILES__DETAIL__FIELD_LABEL__FILE_NUMBER"
              defaultMessage="Spisová značka"
            />
          }
          disabled={!hasPermission(UIElement.PlenaryOpinionFile.FILE_NUMBER)}
        />
        <FormAutocomplete
          name="physicalStorage"
          label={
            <FormattedMessage
              id="ES__PLENARY_OPINION_FILES__DETAIL__FIELD_LABEL__PHYSICAL_STORAGE"
              defaultMessage="Fyzické uložení"
            />
          }
          source={physicalStorages}
          freeSolo={true}
          labelMapper={autocompleteLabelMapper}
          disabled={
            !hasPermission(UIElement.PlenaryOpinionFile.PHYSICAL_STORAGE)
          }
        />
        <FormTextField
          name="jid"
          label={
            <FormattedMessage
              id="ES__PLENARY_OPINION_FILES__DETAIL__FIELD_LABEL__JID"
              defaultMessage="JID"
            />
          }
          disabled={true}
        />
        <FormTextField
          name="barCode"
          label={
            <FormattedMessage
              id="ES__PLENARY_OPINION_FILES__DETAIL__FIELD_LABEL__BAR_CODE"
              defaultMessage="Čárový kód"
            />
          }
          disabled={!hasPermission(UIElement.PlenaryOpinionFile.BAR_CODE)}
        />
        {mode !== DetailMode.NEW && (
          <FormDateTimeField
            name="created"
            label={
              <FormattedMessage
                id="ES__PLENARY_OPINION_FILES__DETAIL__FIELD_LABEL__CREATED"
                defaultMessage="Datum vytvoření"
              />
            }
            disabled={!hasPermission(UIElement.PlenaryOpinionFile.CREATED)}
          />
        )}
        <FormSelect
          name="agenda"
          label={
            <FormattedMessage
              id="ES__PLENARY_OPINION_FILES__DETAIL__FIELD_LABEL__FILE_AGENDA"
              defaultMessage="Agenda"
            />
          }
          required
          source={agendaSource}
          disabled={!hasPermission(UIElement.PlenaryOpinionFile.AGENDA)}
          valueIsId={true}
        />
        <FormSelect
          name="state"
          source={statesSource}
          label={
            <FormattedMessage
              id="ES__PLENARY_OPINION_FILES__DETAIL__FIELD_LABEL__STATE"
              defaultMessage="Stav"
            />
          }
          valueIsId={true}
          disabled={!hasPermission(UIElement.PlenaryOpinionFile.STATE)}
        />
        <FormAutocomplete
          name="processingType"
          label={
            <FormattedMessage
              id="ES__PLENARY_OPINION_FILES__DETAIL__FIELD_LABEL__PROCEEDING_TYPE"
              defaultMessage="Typ řízení"
            />
          }
          labelMapper={autocompleteLabelMapper}
          source={processingTypes}
          disabled={
            !hasPermission(UIElement.PlenaryOpinionFile.PROCESSING_TYPE)
          }
        />
        <SourceFileField />
        <ChallengedFileField />
        <FormAutocomplete
          name="settlementMethods"
          label={
            <FormattedMessage
              id="ES__PLENARY_OPTINION_FILES__DETAIL__FIELD_LABEL__SETTLEMENT_METHODS"
              defaultMessage="Způsob vyřízení"
            />
          }
          source={settlementMethods}
          multiple={true}
          labelMapper={autocompleteLabelMapper}
          disabled={
            !hasPermission(UIElement.PlenaryOpinionFile.SETTLEMENT_METHODS)
          }
        />
        <FormLocalDateField
          name="decisionDate"
          label={
            <FormattedMessage
              id="ES__PLENARY_OPINION_FILES__DETAIL__FIELD_LABEL__DECISION_DATE"
              defaultMessage="Datum rozhodnutí"
            />
          }
          disabled={!hasPermission(UIElement.PlenaryOpinionFile.DECISION_DATE)}
          maxDatePicker={new Date().toISOString()}
        />
        <FormLocalDateTimeField
          name="closingDate"
          label={
            <FormattedMessage
              id="ES__PLENARY_OPINION_FILES__DETAIL__FIELD_LABEL__CLOSING_DATE"
              defaultMessage="Datum uzavření"
            />
          }
          disabled={!hasPermission(UIElement.PlenaryOpinionFile.CLOSING_DATE)}
        />
      </FormPanel>
      <NoteField disabled={disabledNotes} source={noteSource} />
    </>
  );
}
