import React from 'react';
import { useIntl } from 'react-intl';
import { ApiFilterOperation, Evidence } from '@eas/common-web';
import { EsFileState } from '@enums';
import { useSprFiles } from '../spr-files';
import { useColumns } from './spr-file-closed-columns';
import { Toolbar } from './spr-files-closed-toolbar';

export function SprFilesClosed() {
  const intl = useIntl();

  const columns = useColumns();

  const { evidenceBag } = useSprFiles({
    evidenceProps: {
      version: 10,
      tableProps: {
        columns,
        defaultPreFilters: [
          {
            field: 'state.id',
            operation: ApiFilterOperation.EQ,
            value: EsFileState.CLOSED,
          },
        ],
        tableName: intl.formatMessage({
          id: 'ES__SPR_FILES_CLOSED__TABLE__TITLE',
          defaultMessage: 'Spisy SPR - Uzavřené',
        }),
        toolbarProps: {
          after: <Toolbar />,
        },
      },
      detailProps: {
        toolbarProps: {
          showBtn: (btn) => !['NEW'].includes(btn),
          disableBtn: (btn) => !['REFRESH', 'SHARE'].includes(btn),
        },
      },
    },
  });

  return <Evidence {...evidenceBag} />;
}
