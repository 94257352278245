import { useHistoryDescriptors as useChallengedActHistoryDescriptors } from '@composite/challenged-acts/challenged-act-history-descriptors';
import { useHistoryDescriptors as useDeadlineHistoryDescriptors } from '@composite/deadlines/deadline-history-descriptors';
import { useHistoryDescriptors as useAccessoryParticipantHistoryDescriptors } from '@composite/deadlines/deadline-history-descriptors';
import { useHistoryDescriptors as useDraftHistoryDescriptors } from '@composite/drafts/draft-history-descriptors';
import { useCommonHistoryDescriptors } from '@composite/history/history-common-descriptors';
import { HistoryFieldDescriptor } from '@composite/history/history-types';
import { useHistoryDescriptors as useLegalAdviserHistoryDescriptors } from '@composite/legal-advisers/legal-adviser-history-descriptors';
import { useHistoryDescriptors as useNoteHistoryDescriptors } from '@composite/notes/note-history-descriptors';
import { useHistoryDescriptors as useProposerHistoryDescriptors } from '@composite/proposers/proposer-history-descriptors';
import { useHistoryDescriptors as useRegulationHistoryDescriptors } from '@composite/regulations/regulation-history-descriptors';
import { useHistoryDescriptors as useTaskHistoryDescriptors } from '@composite/tasks/task-history-descriptors';
import { useHistoryDescriptors as useViewingHistoryDescriptors } from '@composite/viewing/viewing-history-fields';
import { useHistoryDescriptors as useWriterHistoryDescriptors } from '@composite/writers/writer-history-descriptors';

// There has to be merged descriptors of all agendas as it is possible to change agenda of es file and history is preserved
export function useHistoryDescriptors(): HistoryFieldDescriptor[] {
  const commonDescriptors = useCommonHistoryDescriptors();
  const draftDescriptors = useDraftHistoryDescriptors();
  const noteDescriptors = useNoteHistoryDescriptors();
  const taskDescriptors = useTaskHistoryDescriptors();
  const writerDescriptors = useWriterHistoryDescriptors();
  const deadlineDescriptors = useDeadlineHistoryDescriptors();
  const challengedActDescriptors = useChallengedActHistoryDescriptors();
  const proposerDescriptors = useProposerHistoryDescriptors();
  const legalAdviserDescriptors = useLegalAdviserHistoryDescriptors();
  const accessoryParticipantsDescriptors =
    useAccessoryParticipantHistoryDescriptors();
  const regulationDescriptors = useRegulationHistoryDescriptors();
  const viewingDescriptors = useViewingHistoryDescriptors();

  const courtFileDescriptors = getCourtFileDescriptors();
  const sprFileDescriptors = getSprFileDescriptors();
  const infFileDescriptors = getInfFileDescriptors();
  const plenaryOpinionFileDescriptors = getPlenaryOpinionFileDescriptors();
  const disciplinaryProceedingFileDescriptors =
    getDisciplinaryProceedingsFileDescriptors();

  return [
    ...commonDescriptors,
    ...draftDescriptors,
    ...noteDescriptors,
    ...taskDescriptors,
    ...writerDescriptors,
    ...deadlineDescriptors,
    ...challengedActDescriptors,
    ...proposerDescriptors,
    ...legalAdviserDescriptors,
    ...accessoryParticipantsDescriptors,
    ...regulationDescriptors,
    ...viewingDescriptors,

    ...courtFileDescriptors,
    ...sprFileDescriptors,
    ...infFileDescriptors,
    ...plenaryOpinionFileDescriptors,
    ...disciplinaryProceedingFileDescriptors,
    {
      key: 'drafts',
      label: 'Koncepty',
    },
    {
      key: 'tasks',
      label: 'Úlohy',
    },
    {
      key: 'agenda',
      label: 'Agenda',
    },
    {
      key: 'barCode',
      label: 'Čárový kód',
    },
    {
      key: 'closingDate',
      label: 'Datum uzavření',
    },
    {
      key: 'documents',
      label: 'Dokumenty',
    },
    {
      key: 'essRecord',
      label: 'Spis v ESS',
    },
    {
      key: 'deliveryDate',
      label: 'Datum doručení',
    },
    {
      key: 'registeredInEss',
      label: 'Registrováno v ESS',
    },
    {
      key: 'evidenceNumber',
      label: 'Evidenční číslo spisové služby',
    },
    {
      key: 'jid',
      label: 'JID',
    },
    {
      key: 'indexUserRole',
      label: 'Uživatel v roli vedoucí rejstříku',
    },
    { key: 'notes', label: 'Poznámky' },
    {
      key: 'number.value',
      label: 'Spisová značka',
    },
    {
      key: 'number.order',
      label: 'Pořadí spisové značky',
    },
    {
      key: 'number.year',
      label: 'Rok spisové značky',
    },
    {
      key: 'number.valueSort',
      label: 'Formátovaná hodnota spisové značky pro řazení',
    },
    {
      key: 'number.type',
      label: 'Typ spisu',
    },
    {
      key: 'participants',
      label: 'Účastníci',
    },
    {
      key: 'regulations',
      label: 'Předpisy',
    },
    {
      key: 'settlementMethods',
      label: 'Způsoby vyřízení',
    },
    {
      key: 'state',
      label: 'Stav',
    },
    {
      key: 'elaborators',
      label: 'Zpracovatelé',
    },
  ];
}

const getCourtFileDescriptors = () => [
  {
    key: 'deadlines',
    label: 'Lhůty',
  },
  {
    key: 'accessoryParticipants',
    label: 'Vedlejší účastníci',
  },
  {
    key: 'analystUserRole',
    label: 'Uživatel v roli analytika',
  },
  {
    key: 'anonymizationRequest',
    label: 'Žádost o anonymizaci',
  },
  {
    key: 'anonymize',
    label: 'Anonymizovat',
  },
  {
    key: 'assistantUserRole',
    label: 'Uživatel v roli asistenta',
  },
  {
    key: 'challengedActs',
    label: 'Napadené akty',
  },
  {
    key: 'contestedOpinions',
    label: 'Stanoviska pléna',
  },
  {
    key: 'decidingAuthorityMembers',
    label: 'Členové rozhodujícího orgánu',
  },
  {
    key: 'decisionForm',
    label: 'Forma rozhodnutí',
  },
  {
    key: 'decisions',
    label: 'Rozhodnutí',
  },
  {
    key: 'deliveryDate',
    label: 'Datum doručení',
  },
  {
    key: 'discussionDate',
    label: 'Datum projednání',
  },
  {
    key: 'feasibility',
    label: 'Vykonatelnost',
  },
  {
    key: 'fileNumberEcli',
    label: 'Spisová značka ve formátu pro ECLI',
  },
  {
    key: 'incomingNumber',
    label: 'Došlé číslo',
  },
  {
    key: 'judge',
    label: 'Soudce zpravodaj',
  },
  {
    key: 'legalAdvisers',
    label: 'Právní zástupci',
  },
  {
    key: 'opinionsChallenged',
    label: 'Napadené spisové značky stanovisek',
  },
  {
    key: 'opinionsSourced',
    label: 'Zdrojové spisové značky stanovisek',
  },
  {
    key: 'ordinalNumber',
    label: 'Pořadové číslo',
  },
  {
    key: 'proceedingDuration',
    label: 'Trvání řízení ve dnech',
  },
  {
    key: 'proceedingDurationCalculation',
    label: 'Trvání řízení ve formátu rok:měsíc:týden',
  },
  {
    key: 'proceedingsSubjects',
    label: 'Předměty řízení',
  },
  {
    key: 'processingType',
    label: 'Typ řízení',
  },
  {
    key: 'proposers',
    label: 'Navrhovatelé',
  },
  {
    key: 'publicationDate',
    label: 'Datum vyhlášení',
  },
  {
    key: 'secretaryUserRole',
    label: 'Uživatel v roli tajemnice',
  },
  {
    key: 'senate',
    label: 'Senát',
  },
  {
    key: 'sourceFileNumber',
    label: 'Zdrojová spisová značka',
  },
  {
    key: 'statsDate',
    label: 'Datum pro statistiku',
  },
  {
    key: 'subjectIndexes',
    label: 'Věcný rejstřík',
  },
  {
    key: 'type',
    label: 'Typ',
  },
  {
    key: 'year',
    label: 'Senátní rok',
  },

  {
    key: 'opponent',
    label: 'Odpůrce',
  },
];

const getDisciplinaryProceedingsFileDescriptors = () => [
  {
    key: 'decidingAuthority.chairman',
    label: 'Rozhodující orgán - předseda',
  },
  {
    key: 'decidingAuthority.decidingAuthorityMembers',
    label: 'Rozhodující orgán - členové',
  },
  {
    key: 'decisionDate',
    label: 'Datum rozhodnutí',
  },
  {
    key: 'decisionDeliveryDate',
    label: 'Datum doručení rozhodnutí',
  },
  {
    key: 'legalAdviser',
    label: 'Právní zástupce',
  },
  {
    key: 'participant',
    label: 'Účastník',
  },
  {
    key: 'proposer',
    label: 'Navrhovatel',
  },
  {
    key: 'viewings',
    label: 'Nahlížení',
  },
];

const getInfFileDescriptors = () => [
  {
    key: 'administrativeDecision',
    label: 'Správní rozhodnutí',
  },
  {
    key: 'complaint',
    label: 'Stížnost',
  },
  {
    key: 'incomingNumber',
    label: 'Došlé číslo',
  },
  {
    key: 'settlementDate',
    label: 'Datum vyřízení',
  },
  {
    key: 'solverUserRoles',
    label: 'Uživatelé v roli řešitel',
  },
  {
    key: 'writers',
    label: 'Pisatelé',
  },
];

const getPlenaryOpinionFileDescriptors = () => [
  {
    key: 'analystUserRole',
    label: 'Uživatel v roli analytika',
  },
  {
    key: 'anonymizationRequest',
    label: 'Žádost o anonymizaci',
  },
  {
    key: 'anonymize',
    label: 'Anonymizovat',
  },
  {
    key: 'challengedFiles',
    label: 'Napadené spisové značky',
  },
  {
    key: 'challengedFiles',
    label: 'Napadené spisové značky',
  },
  {
    key: 'decisionDate',
    label: 'Datum rozhodnutí',
  },
  {
    key: 'decisions',
    label: 'Rozhodnutí',
  },
  {
    key: 'fileNumberEcli',
    label: 'Spisová značka ve formátu pro ECLI',
  },
  {
    key: 'judge',
    label: 'Soudce zpravodaj',
  },
  {
    key: 'proceedingsSubjects',
    label: 'Předměty řízení',
  },
  {
    key: 'processingType',
    label: 'Typ řízení',
  },
  {
    key: 'senate',
    label: 'Navrhující senát',
  },
  {
    key: 'sourceFiles',
    label: 'Zdrojové spisové značky',
  },
  {
    key: 'subjectIndexes',
    label: 'Věcný rejstřík',
  },
];

const getSprFileDescriptors = () => [
  {
    key: 'administrativeDecision',
    label: 'Správní rozhodnutí',
  },
  {
    key: 'incomingNumber',
    label: 'Došlé číslo',
  },
  {
    key: 'settlementDate',
    label: 'Datum vyřízení',
  },
  {
    key: 'solverUserRoles',
    label: 'Uživatelé v roli řešitel',
  },
  {
    key: 'submissionContent',
    label: 'Obsah podání',
  },
  {
    key: 'writers',
    label: 'Pisatelé',
  },
];
