import React from 'react';
import { EsFileFields } from '@modules/document/fields/es-file/es-file';
import { HistoryField } from '@composite/history/history-field';
import { useHistoryDescriptors } from './../../outgoing-document-history-descriptors';

export function History() {
  const historyDescriptors = useHistoryDescriptors();

  return (
    <>
      <EsFileFields />
      <HistoryField descriptors={historyDescriptors} />
    </>
  );
}
