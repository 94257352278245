import { noop } from 'lodash';
import React, { KeyboardEvent, useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';
import {
  DictionaryObject,
  FormAutocomplete,
  FormContext,
  FormSelect,
  FormTextField,
  InlineTableFieldCells,
  ListSource,
  TableFieldCellProps,
  TableFieldCells,
  TableFieldColumn,
  TableFieldContext,
  eqFilterParams,
  useEventCallback,
  useFormSelector,
  useStaticListSource,
} from '@eas/common-web';
import { useRegulationOrders } from '@modules/dict-regulation-order/dict-regulation-order-api';
import { autocompleteLabelMapper } from '@components/form/misc/label-mappers';
import { DictRegulationOrder, EsFileRegulation } from '@models';
import { EvidenceApiUrl, RegulationEntityType, RegulationType } from '@enums';
import { RegulationFieldProps } from './regulation-field';

export function useRegulationFieldColumns({
  regulationTypes,
}: {
  regulationTypes: DictionaryObject[];
}): TableFieldColumn<EsFileRegulation>[] {
  const intl = useIntl();

  const useRegulationTypes = () => useStaticListSource(regulationTypes);

  return [
    {
      name: intl.formatMessage({
        id: 'ES__REGULATIONS_TOOLBAR__COLUMN__LABEL',
        defaultMessage: 'Předpis',
      }),
      datakey: 'label',
      width: 350,
    },
    {
      name: intl.formatMessage({
        id: 'ES__REGULATIONS_TOOLBAR__COLUMN__REGULATION_TYPE',
        defaultMessage: 'Typ',
      }),
      datakey: 'regulationType',
      width: 250,
      CellComponent: TableFieldCells.useSelectCellFactory(useRegulationTypes),
    },
    {
      name: intl.formatMessage({
        id: 'ES__REGULATIONS_TOOLBAR__COLUMN__NAME',
        defaultMessage: 'Název',
      }),
      datakey: 'name',
      width: 400,
    },
  ];
}

export function useRegulationColumns(
  disabledAdd: boolean,
  handleRegulationTypeChange: (index: number) => void,
  collectionDatakey: string,
  { regulationTypes, regulationEntityTypes }: RegulationFieldProps
): TableFieldColumn<EsFileRegulation>[] {
  const intl = useIntl();
  const { setFieldValue, getFieldValues } = useContext(FormContext);

  const regulationTypeSource = useStaticListSource(regulationTypes);
  const regulationEntityTypeSource = useStaticListSource(regulationEntityTypes);

  const SelectCell = (
    source: ListSource<DictionaryObject>,
    notifyChange = noop
  ) => {
    return function Cell({
      column,
      index,
    }: TableFieldCellProps<EsFileRegulation>) {
      return (
        <FormSelect
          name={`${collectionDatakey}[${index}].${column.datakey}`}
          source={source}
          labelOptions={{ hide: true }}
          layoutOptions={{ noUnderline: true }}
          valueIsId={true}
          clearable={true}
          notifyChange={() => notifyChange(index)}
        />
      );
    };
  };

  const NameCell = ({
    index,
    column,
  }: TableFieldCellProps<EsFileRegulation>) => {
    const source = useRegulationOrders();

    return useMemo(
      () => (
        <FormAutocomplete
          name={`${collectionDatakey}[${index}].${column.datakey}`}
          labelOptions={{ hide: true }}
          layoutOptions={{ noUnderline: true }}
          source={source}
          clearable={true}
          freeSolo={true}
          notifyChange={async (value) => {
            const regulationName = value as DictRegulationOrder;

            const response = await fetch(
              `${EvidenceApiUrl.DICT_REGULATION_ORDER}/autocomplete`,
              {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(
                  eqFilterParams({ field: 'name', value: regulationName })
                ),
              }
            );

            const data = await response.json();

            if (data?.count === 1) {
              const regulationOrder = data.items[0];
              const { regulations } = getFieldValues();

              setFieldValue('regulations', [
                ...regulations.slice(0, index),
                {
                  ...regulations[index],
                  number: regulationOrder.number,
                  year: regulationOrder.year,
                  order: regulationOrder.regulationOrder,
                },
                ...regulations.slice(index + 1),
              ]);
            }
          }}
          labelMapper={autocompleteLabelMapper}
        />
      ),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [source]
    );
  };

  const OrderCell = ({
    index,
    column,
  }: TableFieldCellProps<EsFileRegulation>) => {
    const source = useRegulationOrders();
    const { saveRow } = useContext(TableFieldContext);

    const { regulations } = useFormSelector(
      (values: { regulations: any[] }) => ({
        regulations: values.regulations,
      })
    );

    const handleLastTab = useEventCallback(
      (e: KeyboardEvent, index: number) => {
        if (e.shiftKey || e.key === 'Shift') {
          e.stopPropagation();
        }

        if (
          e.key === 'Tab' &&
          !e.shiftKey &&
          index === regulations.length - 1 &&
          !disabledAdd
        ) {
          saveRow(regulations.length + 1 ?? 0, {
            regulationType:
              RegulationType.CONSTITUTIONAL_LAWS_AND_INTERNATIONAL_TREATIES,
            type: RegulationEntityType.PARAGRAPH,
          });
        }
      }
    );

    return useMemo(
      () => (
        <FormTextField
          name={`${collectionDatakey}[${index}].${column.datakey}`}
          labelOptions={{ hide: true }}
          layoutOptions={{ noUnderline: true }}
          onKeyDown={(e: KeyboardEvent) => handleLastTab(e, index)}
        />
      ),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [source]
    );
  };

  const columns: TableFieldColumn<EsFileRegulation>[] = [
    {
      datakey: 'regulationType',
      name: intl.formatMessage({
        id: 'ES__REGULATIONS_TOOLBAR__COLUMN__REGULATION_TYPE',
        defaultMessage: 'Typ předpisu',
      }),
      width: 150,
      CellComponent: SelectCell(
        regulationTypeSource,
        handleRegulationTypeChange
      ),
    },
    {
      datakey: 'number',
      name: intl.formatMessage({
        id: 'ES__REGULATIONS_TOOLBAR__COLUMN__NUMBER',
        defaultMessage: 'Číslo',
      }),
      width: 100,
      CellComponent: InlineTableFieldCells.useInlineNumberFieldFactory({
        collectionDatakey,
      }),
    },
    {
      datakey: 'year',
      name: intl.formatMessage({
        id: 'ES__REGULATIONS_TOOLBAR__COLUMN__YEAR',
        defaultMessage: 'Rok',
      }),
      width: 100,
      CellComponent: InlineTableFieldCells.useInlineNumberFieldFactory({
        collectionDatakey,
      }),
    },
    {
      datakey: 'type',
      name: intl.formatMessage({
        id: 'ES__REGULATIONS_TOOLBAR__COLUMN__TYPE',
        defaultMessage: 'Typ',
      }),
      width: 150,
      CellComponent: SelectCell(regulationEntityTypeSource),
    },
    {
      datakey: 'sectionOrArticlePart',
      name: intl.formatMessage({
        id: 'ES__REGULATIONS_TOOLBAR__COLUMN__SECTION_OR_ARTICLE_PART',
        defaultMessage: '§ / Čl.',
      }),
      width: 75,
      CellComponent: InlineTableFieldCells.useInlineTextFieldFactory({
        collectionDatakey,
      }),
    },
    {
      datakey: 'paragraph',
      name: intl.formatMessage({
        id: 'ES__REGULATIONS_TOOLBAR__COLUMN__PARAGRAPH',
        defaultMessage: 'Odstavec',
      }),
      width: 75,
      CellComponent: InlineTableFieldCells.useInlineTextFieldFactory({
        collectionDatakey,
      }),
    },
    {
      datakey: 'letter',
      name: intl.formatMessage({
        id: 'ES__REGULATIONS_TOOLBAR__COLUMN__LETTER',
        defaultMessage: 'Písmeno',
      }),
      width: 75,
      CellComponent: InlineTableFieldCells.useInlineTextFieldFactory({
        collectionDatakey,
      }),
    },
    {
      datakey: 'name',
      name: intl.formatMessage({
        id: 'ES__REGULATIONS_TOOLBAR__COLUMN__NAME',
        defaultMessage: 'Název',
      }),
      width: 350,
      CellComponent: NameCell,
    },
    {
      datakey: 'order',
      name: intl.formatMessage({
        id: 'ES__REGULATIONS_TOOLBAR__COLUMN__ORDER',
        defaultMessage: 'Index (#)',
      }),
      width: 75,
      CellComponent: OrderCell,
    },
  ];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => columns, [SelectCell, NameCell, columns]);
}
