import React from 'react';
import { useIntl } from 'react-intl';
import { ApiFilterOperation, Evidence } from '@eas/common-web';
import { DocumentType, EvidenceApiUrl, UIElement } from '@enums';
import { useInternalDocumentPermission } from '../document/document-permission';
import { DocumentToolbar } from '../document/document-toolbar';
import { useDocuments } from '../document/documents';
import { ActionBar } from './internal-document-actionbar';
import { useColumns } from './internal-document-columns';
import { Fields } from './internal-document-fields';
import { useValidationSchema } from './internal-document-schema';

export function InternalDocuments() {
  const intl = useIntl();
  const validationSchema = useValidationSchema();
  const columns = useColumns();

  const { hasPermission } = useInternalDocumentPermission();

  const { evidenceBag } = useDocuments({
    evidenceProps: {
      version: 13,
      apiProps: {
        url: EvidenceApiUrl.DOCUMENTS,
      },
      tableProps: {
        defaultPreFilters: [
          {
            field: 'type.id',
            operation: ApiFilterOperation.EQ,
            value: DocumentType.INTERNAL,
          },
        ],
        tableName: intl.formatMessage({
          id: 'ES__DOCUMENTS_INTERNAL__TABLE__TITLE',
          defaultMessage: 'Interní dokumenty',
        }),
        columns: columns,
      },
      detailProps: {
        FieldsComponent: Fields,
        validationSchema,
        toolbarProps: {
          title: intl.formatMessage({
            id: 'ES__DOCUMENTS_INTERNAL__DETAIL__TITLE',
            defaultMessage: 'Interní dokument',
          }),
          showBtn: (button) => !['REMOVE', 'NEW'].includes(button),
          disableBtn: (button) =>
            button === 'EDIT' &&
            !hasPermission(UIElement.InternalDocument.EDIT_DOCUMENT_BUTTON),
          After: DocumentToolbar,
          ActionBar,
        },
      },
    },
  });

  return <Evidence {...evidenceBag} />;
}
