import React from 'react';
import { useIntl } from 'react-intl';
import { Evidence } from '@eas/common-web';
import { useCourtFiles } from '../court-files';
import { useColumns } from './court-file-all-columns';

export function CourtFilesAll() {
  const intl = useIntl();
  const columns = useColumns();

  const { evidenceBag } = useCourtFiles({
    evidenceProps: {
      version: 5,
      tableProps: {
        columns,
        tableName: intl.formatMessage({
          id: 'ES__COURT_FILES__ALL__TABLE__TITLE',
          defaultMessage: 'Soudní spisy',
        }),
      },
      detailProps: {
        toolbarProps: {
          showBtn: (btn) => !['NEW'].includes(btn),
        },
      },
    },
  });

  return <Evidence {...evidenceBag} />;
}
