import { abortableFetch, useEventCallback } from '@eas/common-web';
import { EsFileRegulation } from '@models';
import { EvidenceApiUrl, RegulationEntityType } from '@enums';

export function useEditRegulationDialog() {
  const callApi = useEventCallback(
    (esFileId: string, regulations: EsFileRegulation[]) => {
      return abortableFetch(
        `${EvidenceApiUrl.ES_FILES}/${esFileId}/regulation`,
        {
          method: 'PUT',
          headers: new Headers({
            'Content-Type': 'application/json',
          }),
          body: JSON.stringify(
            regulations.map((regulation) => {
              const { sectionOrArticlePart, ...rest } = regulation;

              return regulation.type === RegulationEntityType.ARTICLE
                ? { ...rest, article: sectionOrArticlePart }
                : { ...rest, sectionSign: sectionOrArticlePart };
            })
          ),
        }
      );
    }
  );

  return {
    callApi,
  };
}
