import React from 'react';
import { useIntl } from 'react-intl';
import { ApiFilterOperation, Evidence } from '@eas/common-web';
import { EsFileState } from '@enums';
import { useSprFiles } from '../spr-files';
import { useColumns } from './spr-file-new-columns';

export function SprFilesNew() {
  const intl = useIntl();
  const columns = useColumns();

  const { evidenceBag } = useSprFiles({
    evidenceProps: {
      version: 5,
      tableProps: {
        columns,
        defaultPreFilters: [
          {
            field: 'state.id',
            operation: ApiFilterOperation.EQ,
            value: EsFileState.NEW,
          },
        ],
        tableName: intl.formatMessage({
          id: 'ES__SPR_FILES_NEW__TABLE__TITLE',
          defaultMessage: 'Spisy SPR - Nové spisy',
        }),
      },
    },
  });

  return <Evidence {...evidenceBag} />;
}
