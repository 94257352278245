enum Element {
  /**
   * Spisová značka
   */
  FILE_NUMBER = 'FILE_NUMBER',

  /**
   * Soudce zpravodaj
   */
  JUDGE = 'JUDGE',

  /**
   * Žádost o anonymizaci
   */
  ANONYMIZATION_REQUEST = 'ANONYMIZATION_REQUEST',

  /**
   * Anonymizovat
   */
  ANONYMIZE = 'ANONYMIZE',

  /**
   * Stav
   */
  STATE = 'STATE',

  /**
   * Číslo rozhodnutí
   */
  NUMBER = 'NUMBER',

  /**
   * Identifikátor evropské judikatury
   */
  ECLI = 'ECLI',

  /**
   * Forma rozhodnutí
   */
  FORM = 'FORM',

  /**
   * Způsoby vyřízení
   */
  SETTLEMENT_METHODS = 'SETTLEMENT_METHODS',

  /**
   * Umožnit zveřejnění
   */
  ALLOW_PUBLISHING = 'ALLOW_PUBLISHING',

  /**
   * Převzít indexy
   */
  TAKE_OVER_INDEXES = 'TAKE_OVER_INDEXES',

  /**
   * Datum rozhodnutí
   */
  DECISION_DATE = 'DECISION_DATE',

  /**
   * Datum vyhlášení
   */
  PUBLICATION_DATE = 'PUBLICATION_DATE',

  /**
   * Datum zpřístupnění
   */
  MADE_ACCESSIBLE_DATE = 'MADE_ACCESSIBLE_DATE',

  /**
   * Publikovat ve sbírce
   */
  PUBLISH_IN_COLLECTION = 'PUBLISH_IN_COLLECTION',

  /**
   * Paralelní citace (Sb.)
   */
  PARALLEL_CITATION_SB = 'PARALLEL_CITATION_SB',

  /**
   * Paralelní citace (SbNU.)
   */
  PARALLEL_CITATION_SBNU = 'PARALLEL_CITATION_SBNU',

  /**
   * Populární název
   */
  POPULAR_NAME = 'POPULAR_NAME',

  /**
   * Význam
   */
  IMPORTANCE = 'IMPORTANCE',

  /**
   * Odlišné stanovisko
   */
  DIFFERENT_OPINION = 'DIFFERENT_OPINION',

  /**
   * Poznámka
   */
  NOTE = 'NOTE',

  /**
   * Jazyk rozhodnutí
   */
  LANGUAGE = 'LANGUAGE',

  /**
   * Vyloučeno z NALUSu
   */
  EXCLUDED_FROM_NALUS = 'EXCLUDED_FROM_NALUS',

  /**
   * Poznámky
   */
  NOTES = 'NOTES',

  /**
   * Plné znění
   */
  TEXT = 'TEXT',

  /**
   * Anonymizováno
   */
  ANONYMIZED_TEXT = 'ANONYMIZED_TEXT',

  /**
   * Rozhodnutí - plné znění
   */
  FULL_TEXT = 'FULL_TEXT',

  /**
   * Rozhodnutí - zveřejněné
   */
  PUBLIC_TEXT = 'PUBLIC_TEXT',

  /**
   * Rozhodnutí po korektuře
   */
  CORRECTED_TEXT = 'CORRECTED_TEXT',

  /**
   * Jiná sdělení
   */
  OTHER_ANNOUNCEMENTS = 'OTHER_ANNOUNCEMENTS',

  /**
   * Historie
   */
  HISTORY = 'HISTORY',

  /**
   * AKCE: Předat k indexaci
   */
  INDEXING = 'INDEXING',

  /**
   * AKCE: Předat k anonymizaci
   */
  TO_ANONYMIZE = 'TO_ANONYMIZE',

  /**
   * AKCE: Předat k zpřístupnění
   */
  TO_MAKE_ACCESSIBLE = 'TO_MAKE_ACCESSIBLE',

  /**
   * AKCE: Přegenerovat přílohy
   */
  REGENERATE_ATTACHMENTS = 'REGENERATE_ATTACHMENTS',

  /**
   * AKCE: Zpřístupnit rozhodnutí
   */
  ACCESSIBLE = 'ACCESSIBLE',

  /**
   * AKCE: Zpět - rozpracováno
   */
  IN_PROGRESS = 'IN_PROGRESS',

  /**
   * AKCE: Upravit rozhodnutí
   */
  EDIT_DECISION_BUTTON = 'EDIT_DECISION_BUTTON',
}

export default Element;
