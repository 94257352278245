import clsx from 'clsx';
import React, { useMemo } from 'react';
import {
  ApiFilterOperation,
  FilterComponentProps,
  ListSource,
  Select,
  useEventCallback,
} from '@eas/common-web';
import { DomainObject } from '@models';
import { useStyles } from '../filter-styles';

export function FilterSelectCell<TYPE extends DomainObject>(
  dataHook: () => ListSource<TYPE>
) {
  return useMemo(
    () =>
      function Cell({ disabled, onChangeState, state }: FilterComponentProps) {
        const classes = useStyles();
        const source = dataHook();

        const handleChange = useEventCallback(
          (value: TYPE | string | TYPE[] | string[] | null) => {
            onChangeState({
              ...state,
              operation: ApiFilterOperation.EQ,
              value: (value as TYPE)?.id,
              object: value,
            });
          }
        );

        return (
          <div
            className={clsx(classes.textWithoutSelectWrapper, {
              [classes.disabled]: disabled,
            })}
          >
            <Select
              source={source}
              value={state.object}
              onChange={handleChange}
              disabled={disabled}
            />
          </div>
        );
      },
    [dataHook]
  );
}
