import React from 'react';
import { useIntl } from 'react-intl';
import { ApiFilterOperation, Evidence } from '@eas/common-web';
import { EsFileState } from '@enums';
import { useColumns } from '../closed/spr-file-closed-columns';
import { useSprFiles } from '../spr-files';

export function SprFilesShredded() {
  const intl = useIntl();

  const columns = useColumns();

  const { evidenceBag } = useSprFiles({
    evidenceProps: {
      version: 2,
      tableProps: {
        columns,
        defaultPreFilters: [
          {
            field: 'state.id',
            operation: ApiFilterOperation.EQ,
            value: EsFileState.SHREDDED,
          },
        ],
        tableName: intl.formatMessage({
          id: 'ES__SPR_FILES_SHREDDED__TABLE__TITLE',
          defaultMessage: 'Spisy SPR - Skartované',
        }),
      },
      detailProps: {
        toolbarProps: {
          showBtn: (btn) => !['NEW'].includes(btn),
          disableBtn: (btn) => !['REFRESH', 'SHARE'].includes(btn),
        },
      },
    },
  });

  return <Evidence {...evidenceBag} />;
}
