export enum EntityName {
  // main entities
  ASSIGNING_RULE = 'AssigningRule',
  ASSIGNED_FILE_NUMBER = 'AssignedFileNumber',
  CHALLENGED_ACT = 'ChallengedAct',
  DRAFT = 'Draft',
  DECISION = 'Decision',
  DISPATCH = 'Dispatch',
  DOCUMENT = 'Document',
  ES_FILE = 'EsFile',
  JUDGE = 'Judge',
  NASPIS_LOG_RECORD = 'NaspisLogRecord',
  SUBJECT = 'Subject',
  TASK = 'Task',
  USER = 'USer',
  USER_SUBSTITUTION = 'UserSubstitution',
  VIEWING_REQUEST = 'ViewingRequest',

  // dictionaries
  ACT_TYPE = 'ActType',
  BRIEF_SUMMARY = 'BriefSummary',
  BARCODE_PRINTER = 'BarcodePrinter',
  CONCERNED_AUTHORITY_TYPE = 'ConcernedAuthorityType',
  CONTACT_TYPE = 'ContactType',
  COUNTRY = 'Country',
  DISPATCH_TYPE = 'DispatchType',
  ENVELOPE_CONTENT = 'EnvelopeContent',
  HOLIDAY = 'Holiday',
  LEGAL_FORM = 'LegalForm',
  NOTE = 'Note',
  PROCEEDINGS_SUBJECT = 'ProceedingsSubject',
  OPPONENT = 'Opponent',
  PHYSICAL_STORAGE = 'PhysicalStorage',
  PROPOSER_DISTINCITON = 'ProposerDistinction',
  PROPOSER_TYPE = 'ProposerType',
  PROCESSING_TYPE = 'ProcessingType',
  REGULATION_ORDER = 'RegulationOrder',
  SENATE = 'Senate',
  SETTLEMENT_METHOD = 'SettlementMethod',
  SUBJECT_INDEX = 'SubjectIndex',
  SUBMISSION_CONTENT = 'SubmissionContent',
  TASK_NAME = 'TaskName',
  ZIP_CODE = 'ZipCode',
}
