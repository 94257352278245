import {useContext} from 'react';
import {useIntl} from 'react-intl';
import {
  ApiFilterOperation,
  eqFilterParams,
  TableCells,
  TableColumn,
  TableFilterCells,
  useStaticListSource,
} from '@eas/common-web';
import {useUserRoles} from '@modules/role/role-api';
import {StaticDataContext} from '@components/evidence/static-data-provider/static-data-provider';
import {
  autocompleteColumnMapper,
  autocompleteLabelMapper,
  multipleAutocompleteColumnMapper,
  subjectContactColumnMapper,
} from '@components/form/misc/label-mappers';
import {Decision} from '@models';
import {Role} from '@enums';
import {useSettlementMethods} from '../dict-settlement-method/dict-settlement-method-api';
import {useJudges} from '../judge/judge-api';
import {useContacts} from '../subject/subject-api';

export function useColumns(): TableColumn<Decision>[] {
  const intl = useIntl();
  const { anonymizationRequests, decisionForms } =
    useContext(StaticDataContext);

  const useAnalysts = () =>
    useUserRoles(eqFilterParams({ field: 'role.id', value: Role.ANALYST }));
  const useAnonymizationRequests = () =>
    useStaticListSource(anonymizationRequests);
  const useDecisionForms = () => useStaticListSource(decisionForms);

  return [
    {
      datakey: 'number',
      sortkey: 'numberSort',
      name: intl.formatMessage({
        id: 'ES__DECISIONS__TABLE__COLUMN__ES_FILE',
        defaultMessage: 'Číslo rozhodnutí',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      sortkey: 'esFile.analystUserRole.name',
      datakey: 'esFile.analystUserRole.label',
      filterkey: 'esFile.analystUserRole.id',
      name: intl.formatMessage({
        id: 'ES__DECISIONS__TABLE__COLUMN__ANALYTICS',
        defaultMessage: 'Analytik - Indexace',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      FilterComponent: TableFilterCells.useFilterAutocompleteCellFactory(
        useAnalysts,
        autocompleteLabelMapper
      ),
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'form',
      sortkey: 'form.name',
      filterkey: 'form.id',
      name: intl.formatMessage({
        id: 'ES__DECISIONS__TABLE__COLUMN__FORM',
        defaultMessage: 'Forma rozhodnutí',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      valueMapper: TableCells.useSelectCellFactory(useDecisionForms),
      FilterComponent:
        TableFilterCells.useFilterSelectCellFactory(useDecisionForms),
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'decisionDate',
      name: intl.formatMessage({
        id: 'ES__DECISIONS__TABLE__COLUMN__DECISION_DATE',
        defaultMessage: 'Datum rozhodnutí',
      }),
      width: 200,
      CellComponent: TableCells.DateCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'esFile.anonymizationRequest',
      sortkey: 'esFile.anonymizationRequest.name',
      filterkey: 'esFile.anonymizationRequest.id',
      name: intl.formatMessage({
        id: 'ES__DECISIONS__TABLE__COLUMN__ANONYMIZATION_REQUEST',
        defaultMessage: 'Žádost o anonymizaci',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      valueMapper: TableCells.useSelectCellFactory(useAnonymizationRequests),
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(
        useAnonymizationRequests
      ),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'settlementMethods',
      sortkey: 'settlementMethods.concatenated',
      filterkey: 'settlementMethods.id',
      name: intl.formatMessage({
        id: 'ES__DECISIONS__TABLE__COLUMN__SETTLEMENT_METHODS',
        defaultMessage: 'Typ výroku',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      valueMapper: multipleAutocompleteColumnMapper,
      FilterComponent: TableFilterCells.useFilterAutocompleteCellFactory(
        useSettlementMethods,
        autocompleteLabelMapper
      ),
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'esFile.judge',
      filterkey: 'esFile.judge.id',
      sortkey: 'esFile.judge.name',
      name: intl.formatMessage({
        id: 'ES__DECISIONS__TABLE__COLUMN__JUDGE',
        defaultMessage: 'Soudce zpravodaj',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      valueMapper: autocompleteColumnMapper,
      FilterComponent: TableFilterCells.useFilterAutocompleteCellFactory(
        useJudges,
        autocompleteLabelMapper
      ),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'esFile.proposers',
      filterkey: 'esFile.proposers.contact.id',
      sortkey: 'esFile.proposers.contact.name',
      name: intl.formatMessage({
        id: 'ES__DECISIONS__TABLE__COLUMN__PROPOSERS',
        defaultMessage: 'Navrhovatelé',
      }),
      width: 300,
      CellComponent: TableCells.TextCell,
      valueMapper: subjectContactColumnMapper,
      FilterComponent: TableFilterCells.useFilterAutocompleteCellFactory(
        useContacts,
        autocompleteLabelMapper
      ),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    // {
    //   datakey: 'text.introduction',
    //   name: intl.formatMessage({
    //     id: 'ES__DECISIONS__TABLE__COLUMN__INTRODUCTION',
    //     defaultMessage: 'Návětí - plné znění',
    //   }),
    //   width: 200,
    //   CellComponent: TableCells.TextCell,
    //   filterable: true,
    //   visible: false,
    //   enableHighlighting: true,
    //   filterGroup: 3,
    // },
    // {
    //   datakey: 'text.statement',
    //   name: intl.formatMessage({
    //     id: 'ES__DECISIONS__TABLE__COLUMN__STATEMENT',
    //     defaultMessage: 'Výrok - plné znění',
    //   }),
    //   width: 200,
    //   CellComponent: TableCells.TextCell,
    //   filterable: true,
    //   visible: false,
    //   enableHighlighting: true,
    //   filterGroup: 3,
    // },
    // {
    //   datakey: 'text.narration',
    //   name: intl.formatMessage({
    //     id: 'ES__DECISIONS__TABLE__COLUMN__NARRATION',
    //     defaultMessage: 'Narace - plné znění',
    //   }),
    //   width: 200,
    //   CellComponent: TableCells.TextCell,
    //   filterable: true,
    //   visible: false,
    //   enableHighlighting: true,
    //   filterGroup: 3,
    // },
    // {
    //   datakey: 'text.argumentation',
    //   name: intl.formatMessage({
    //     id: 'ES__DECISIONS__TABLE__COLUMN__ARGUMENTATION',
    //     defaultMessage: 'Argumentace - plné znění',
    //   }),
    //   width: 200,
    //   CellComponent: TableCells.TextCell,
    //   filterable: true,
    //   visible: false,
    //   enableHighlighting: true,
    //   filterGroup: 3,
    // },
    // {
    //   datakey: 'text.legalSentence',
    //   name: intl.formatMessage({
    //     id: 'ES__DECISIONS__TABLE__COLUMN__LEGAL_SENTENCE',
    //     defaultMessage: 'Právní věta - plné znění',
    //   }),
    //   width: 200,
    //   CellComponent: TableCells.TextCell,
    //   filterable: true,
    //   visible: false,
    //   enableHighlighting: true,
    //   filterGroup: 3,
    // },
    // {
    //   datakey: 'text.abstract',
    //   name: intl.formatMessage({
    //     id: 'ES__DECISIONS__TABLE__COLUMN__ABSTRACT',
    //     defaultMessage: 'Abstrakt - plné znění',
    //   }),
    //   width: 200,
    //   CellComponent: TableCells.TextCell,
    //   filterable: true,
    //   visible: false,
    //   enableHighlighting: true,
    //   filterGroup: 3,
    // },
    // {
    //   datakey: 'text.differentOpinion',
    //   name: intl.formatMessage({
    //     id: 'ES__DECISIONS__TABLE__COLUMN__DIFFERENT_OPINION',
    //     defaultMessage: 'Odlišné stanovisko - plné znění',
    //   }),
    //   width: 200,
    //   CellComponent: TableCells.TextCell,
    //   filterable: true,
    //   visible: false,
    //   enableHighlighting: true,
    //   filterGroup: 3,
    // },
    // {
    //   datakey: 'anonymizedText.introduction',
    //   name: intl.formatMessage({
    //     id: 'ES__DECISIONS__TABLE__COLUMN__INTRODUCTION',
    //     defaultMessage: 'Návětí - anonymizováno',
    //   }),
    //   width: 200,
    //   CellComponent: TableCells.TextCell,
    //   filterable: true,
    //   visible: false,
    //   enableHighlighting: true,
    //   filterGroup: 4,
    // },
    // {
    //   datakey: 'anonymizedText.statement',
    //   name: intl.formatMessage({
    //     id: 'ES__DECISIONS__TABLE__COLUMN__STATEMENT',
    //     defaultMessage: 'Výrok - anonymizováno',
    //   }),
    //   width: 200,
    //   CellComponent: TableCells.TextCell,
    //   filterable: true,
    //   visible: false,
    //   enableHighlighting: true,
    //   filterGroup: 4,
    // },
    // {
    //   datakey: 'anonymizedText.narration',
    //   name: intl.formatMessage({
    //     id: 'ES__DECISIONS__TABLE__COLUMN__NARRATION',
    //     defaultMessage: 'Narace - anonymizováno',
    //   }),
    //   width: 200,
    //   CellComponent: TableCells.TextCell,
    //   filterable: true,
    //   visible: false,
    //   enableHighlighting: true,
    //   filterGroup: 4,
    // },
    // {
    //   datakey: 'anonymizedText.argumentation',
    //   name: intl.formatMessage({
    //     id: 'ES__DECISIONS__TABLE__COLUMN__ARGUMENTATION',
    //     defaultMessage: 'Argumentace - anonymizováno',
    //   }),
    //   width: 200,
    //   CellComponent: TableCells.TextCell,
    //   filterable: true,
    //   visible: false,
    //   enableHighlighting: true,
    //   filterGroup: 4,
    // },
    // {
    //   datakey: 'anonymizedText.legalSentence',
    //   name: intl.formatMessage({
    //     id: 'ES__DECISIONS__TABLE__COLUMN__LEGAL_SENTENCE',
    //     defaultMessage: 'Právní věta - anonymizováno',
    //   }),
    //   width: 200,
    //   CellComponent: TableCells.TextCell,
    //   filterable: true,
    //   visible: false,
    //   enableHighlighting: true,
    //   filterGroup: 4,
    // },
    // {
    //   datakey: 'anonymizedText.abstract',
    //   name: intl.formatMessage({
    //     id: 'ES__DECISIONS__TABLE__COLUMN__ABSTRACT',
    //     defaultMessage: 'Abstrakt - anonymizováno',
    //   }),
    //   width: 200,
    //   CellComponent: TableCells.TextCell,
    //   filterable: true,
    //   visible: false,
    //   enableHighlighting: true,
    //   filterGroup: 4,
    // },
    // {
    //   datakey: 'anonymizedText.differentOpinion',
    //   name: intl.formatMessage({
    //     id: 'ES__DECISIONS__TABLE__COLUMN__DIFFERENT_OPINION',
    //     defaultMessage: 'Odlišné stanovisko - anonym.',
    //   }),
    //   width: 200,
    //   CellComponent: TableCells.TextCell,
    //   filterable: true,
    //   visible: false,
    //   enableHighlighting: true,
    //   filterGroup: 4,
    // },
  ];
}
