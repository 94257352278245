/**
 * Type of modification
 */
export enum SimpleLogEventType {
  MIGRATION = 'MIGRATION',
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
  ACTIVATE = 'ACTIVATE',
  DEACTIVATE = 'DEACTIVATE',
  LEGACY = 'LEGACY',
}

/**
 * Enum of possible Change objects
 */
export enum HistoryFieldType {
  STRING = 'STRING',
  LABELED = 'LABELED',
  LOCAL_DATE = 'LOCAL_DATE',
  LOCAL_DATE_TIME = 'LOCAL_DATE_TIME',
  COLLECTION = 'COLLECTION',
  NONE = 'NONE',
  NESTED = 'NESTED',
  BOOLEAN = 'BOOLEAN',
}

/**
 * Typ entity
 */
export enum HistoryEntityType {
  /**
   * Způsob vyřízení
   */
  SETTLEMENT_METHOD = 'SETTLEMENT_METHOD',

  /**
   * Země
   */
  COUNTRY = 'COUNTRY',

  /**
   * Věcný rejstřík
   */
  SUBJECT_INDEX = 'SUBJECT_INDEX',

  /**
   * Typ řízení
   */
  PROCESSING_TYPE = 'PROCESSING_TYPE',

  /**
   * Typ odeslání
   */
  DISPATCH_TYPE = 'DISPATCH_TYPE',

  /**
   * Typ navrhovatele
   */
  PROPOSER_TYPE = 'PROPOSER_TYPE',

  /**
   * Typ dotčeného orgánu
   */
  CONCERNED_AUTHORITY_TYPE = 'CONCERNED_AUTHORITY_TYPE',

  /**
   * Tiskárna čárových kódů
   */
  BARCODE_PRINTER = 'BARCODE_PRINTER',

  /**
   * Svátek
   */
  HOLIDAY = 'HOLIDAY',

  /**
   * Právní forma
   */
  LEGAL_FORM = 'LEGAL_FORM',

  /**
   * Subjekt
   */
  SUBJECT = 'SUBJECT',

  /**
   * Stručný obsah
   */
  BRIEF_SUMMARY = 'BRIEF_SUMMARY',

  /**
   * Soudce
   */
  JUDGE = 'JUDGE',

  /**
   * Senát
   */
  SENATE = 'SENATE',

  /**
   * Rozlišení navrhovatele
   */
  PROPOSER_DISTINCTION = 'PROPOSER_DISTINCTION',

  /**
   * PSČ
   */
  ZIP_CODE = 'ZIP_CODE',

  /**
   * Předmět řízení
   */
  PROCEEDINGS_SUBJECT = 'PROCEEDINGS_SUBJECT',

  /**
   * Poznámky
   */
  NOTE = 'NOTE',

  /**
   * Odpůrce
   */
  OPPONENT = 'OPPONENT',

  /**
   * Obsah podání
   */
  SUBMISSION_CONTENT = 'SUBMISSION_CONTENT',

  /**
   * Obsah obálky
   */
  ENVELOPE_CONTENT = 'ENVELOPE_CONTENT',

  /**
   * Index právních předpisů
   */
  REGULATION_ORDER = 'REGULATION_ORDER',

  /**
   * Druh aktu
   */
  ACT_TYPE = 'ACT_TYPE',

  /**
   * Spis
   */
  ES_FILE = 'ES_FILE',

  /**
   * Dokument
   */
  DOCUMENT = 'DOCUMENT',

  /**
   * Příloha dokumentu
   */
  DOCUMENT_ATTACHMENT = 'DOCUMENT_ATTACHMENT',

  /**
   * Vypravení
   */
  DISPATCH = 'DISPATCH',

  /**
   * Notifikační šablona
   */
  NOTIFICATION_TEMPLATE = 'NOTIFICATION_TEMPLATE',

  /**
   * Pravidlo pro přidělování spisových značek
   */
  ASSIGNING_RULE = 'ASSIGNING_RULE',

  /**
   * Rozhodnutí
   */
  DECISION = 'DECISION',

  /**
   * Fyzické úložiště
   */
  PHYSICAL_STORAGE = 'PHYSICAL_STORAGE',
}
